import classNames from 'classnames';
import React, { PropsWithChildren, HTMLAttributes, useState, MouseEventHandler, RefObject, useRef } from 'react';
import { getKeyUpHandler } from '../../utils/helpers';
import { useMaterialEffect } from '../../utils/hooks/useMaterialEffectHook';
import { Text } from './text';

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  accent?: boolean;
  adaptive?: boolean;
  className?: string;
  disabled?: boolean | string;
  error?: boolean;
  extra?: boolean;
  fab?: boolean;
  fit?: boolean;
  highlight?: boolean;
  icon?: string;
  iconMicro?: boolean;
  large?: boolean;
  xlarge?: boolean;
  material?: boolean;
  mini?: boolean;
  micro?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | (() => any);
  open?: boolean;
  outline?: boolean;
  preview?: boolean;
  primary?: boolean;
  rounded?: boolean;
  show?: boolean;
  space?: boolean;
  special?: boolean;
  text?: string;
  textFirst?: boolean;
  toggleClassName?: string;
  wide?: boolean;
  position?: string;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      accent = false,
      adaptive = false,
      className = '',
      disabled = false,
      error = false,
      extra = false,
      fab = false,
      fit = false,
      highlight = false,
      icon = '',
      iconMicro = false,
      large = false,
      mini = false,
      micro = false,
      onClick,
      open = false,
      outline = false,
      preview = false,
      primary = false,
      rounded = false,
      show = false,
      space = false,
      special = false,
      text = '',
      textFirst = true,
      wide = false,
      children,
      material = true,
      toggleClassName = '',
      position,
      ...rest
    },
    ref
  ) => {
    const refObj = useRef(null);
    const [iconCode, fill] = icon.split('.');
    const [addToggleClassNames, setAddToggleClassNames] = useState(false);
    let iconProps = null;
    if (fill === 'fill') {
      iconProps = { fill: '' };
    }
    const { svg, onMouseDown, onMouseLeave, onMouseUp } = useMaterialEffect({
      ref: (ref ?? refObj) as RefObject<HTMLDivElement>,
      isMaterial: material,
    });

    return (
      <div
        ref={ref ?? refObj}
        className={classNames(
          'button',
          {
            accent,
            adaptive,
            error,
            extra,
            fab,
            fit,
            highlight,
            large,
            mini,
            micro,
            open,
            outline,
            preview,
            primary,
            rounded,
            show,
            special,
            space,
            wide,
            ...(toggleClassName ? { [toggleClassName]: addToggleClassNames } : null),
          },
          className
        )}
        onClick={(e) => {
          toggleClassName && setAddToggleClassNames((prev) => !prev);
          onClick?.(e);
        }}
        onKeyUp={getKeyUpHandler()}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        {...rest}
        {...(position ? { 'data-position': position } : null)}
        {...(disabled ? { disabled: true } : null)}
        {...(material ? { effect: 'material' } : null)}
      >
        {text && textFirst && <Text>{text}</Text>}
        {iconCode && (
          <icon {...iconProps} {...(iconMicro ? { micro: '' } : null)}>
            {iconCode}
          </icon>
        )}
        {children}
        {text && !textFirst && <Text>{text}</Text>}
        {svg}
      </div>
    );
  }
);

export default Button;
