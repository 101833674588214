import { Text } from '../../components/shared/text';
import Clients from '../clients';

const UserClients: React.FC = () => {
    return (
        <section>
            <wrapper>
                <group data-space-vertical="20">
                    <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
                        clients.mesClients.libelle
                    </Text>
                </group>
                <Clients />
            </wrapper>
        </section>
    );
};

export default UserClients;
