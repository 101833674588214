import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/button';
import NavigationList from '../../components/shared/navigation/navigationList';
import Scroll from '../../components/shared/scroll';
import Separator from '../../components/shared/separator';
import { SvgHamburger } from '../../components/svg';
import { getAppUrl } from '../../project/helpers';

const Landing: React.FC = () => {
  return (
    <view data-vertical="" data-adaptive="">
      <view>
        <group data-space="30" data-border="" data-background="main-background">
          <group>
            <nav data-launcher-item="" data-type="grid">
              <ul>
                <li data-accent="" data-radius="10">
                  <div className="counter">5</div>
                  <Link to="">
                    <icon>shopping_cart</icon>
                    <text>Shopping Cart</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>local_cafe</icon>
                    <text>Warranty</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>home</icon>
                    <text> History</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>local_pizza</icon>
                    <text>Warranty</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>Inventory_2</icon>
                    <text>Order History</text>
                  </Link>
                </li>
                <li data-radius="10" className="selected">
                  <Separator vertical />
                  <Link to="">
                    <icon>cloud</icon> <text>Order History</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>directions_boat</icon> <text>Directon</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>takeout_dining</icon> <text>Sample</text>
                  </Link>
                </li>
                <li>
                  <Separator vertical />
                  <Link to="">
                    <icon>shopping_bag</icon> <text>Account</text>
                  </Link>
                </li>
              </ul>
            </nav>
          </group>
        </group>
        <group data-space="30" data-border="" data-background="main-background">
          <group>
            <NavigationList
              launcherItem
              type="grid"
              separator
              data={[
                {
                  text: 'Shopping Cart',
                  icon: 'shopping_cart',
                  link: getAppUrl('ShoppingCart'),
                  count: 5,
                },
                {
                  text: 'Warranty',
                  icon: 'local_cafe',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Sample',
                  icon: 'takeout_dining',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Order History',
                  icon: 'Inventory_2',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Shopping Cart',
                  icon: 'shopping_bag',
                  link: getAppUrl('Template'),
                },
              ]}
            />
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group>
            <nav launcher="">
              <ul>
                <li>
                  <icon>home</icon>
                  <text>Home</text>
                </li>
                <li>
                  <Link to="">
                    <icon>shopping_bag</icon>
                    <text>Warranty</text>
                  </Link>
                </li>
                <li className="selected">
                  <Link to="">
                    <icon>drafts</icon>
                    <text> History</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <icon>chair</icon>
                    <text>Warranty</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <icon>notifications</icon>
                    <text>Directon</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <icon>leaderboard</icon> <text>Order History</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <icon>work</icon> <text>Directon</text>
                  </Link>
                </li>
              </ul>
            </nav>
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group>
            <NavigationList
              launcher
              data={[
                {
                  text: 'Shopping Cart',
                  icon: 'shopping_cart',
                  link: getAppUrl('ShoppingCart'),
                },
                {
                  text: 'Warranty',
                  icon: 'local_cafe',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Sample',
                  icon: 'takeout_dining',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Order History',
                  icon: 'Inventory_2',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Shopping Cart',
                  icon: 'shopping_bag',
                  link: getAppUrl('Template'),
                },
              ]}
            />
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <nav className="nav_strip round">
            <ul data-gap="5">
              <li>
                <text>Home</text>
              </li>
              <li className="selected">
                <Link to="">
                  <text>Warranty</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text> History</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Warranty</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Directon</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Order History</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Directon</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Sample</text>
                </Link>
              </li>
              <li>
                <Link to="">
                  <text>Account</text>
                </Link>
              </li>
            </ul>
          </nav>
        </group>
        <group data-space="30" data-border="" data-background="main-background">
          <NavigationList
            navStrip
            gap="5"
            round
            data={[
              {
                text: 'Shopping Cart',
                link: getAppUrl('ShoppingCart'),
              },
              {
                text: 'Warranty',
                link: getAppUrl('Template'),
              },
              {
                text: 'Sample',
                link: getAppUrl('Template'),
              },
              {
                text: 'Order History',
                link: getAppUrl('Template'),
              },
              {
                text: 'Shopping Cart',
                link: getAppUrl('Template'),
              },
            ]}
          />
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group data-border="" data-width="auto">
            <nav className="nav_strip highlight">
              <ul>
                <li>
                  <icon>home</icon>
                  <text>Home</text>
                </li>
                <Separator vertical />
                <li className="selected">
                  <Link to="">
                    <icon>drafts</icon>
                    <text> History</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Warranty</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Directon</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Order History</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Directon</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Sample</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Account</text>
                  </Link>
                </li>
                <li className="sticky_item">
                  <Button className="extra" toggleClassName="open" material>
                    <icon>
                      <SvgHamburger />
                    </icon>
                  </Button>
                </li>
              </ul>
            </nav>
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group data-border="" data-width="auto">
            <NavigationList
              navStrip
              data={[
                {
                  text: 'Shopping Cart',
                  link: getAppUrl('ShoppingCart'),
                },
                {
                  text: 'Warranty',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Sample',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Order History',
                  link: getAppUrl('Template'),
                },
                {
                  text: 'Shopping Cart',
                  link: getAppUrl('Template'),
                },
                {
                  render: () => {
                    return (
                      <li className="sticky_item">
                        <Button className="extra" toggleClassName="open" material>
                          <icon>
                            <SvgHamburger />
                          </icon>
                        </Button>
                      </li>
                    );
                  },
                },
              ]}
            />
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group data-border="" data-width="auto">
            <nav className="nav_strip">
              <Scroll>
                <ul>
                  <li>
                    <icon>home</icon>
                    <text>Home</text>
                  </li>
                  <Separator vertical />
                  <li>
                    <Link to="">
                      <icon>drafts</icon>
                      <text> History</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Warranty</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Directon</text>
                    </Link>
                  </li>
                  <li className="selected">
                    <Link to="">
                      <text>Order History</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Directon</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Sample</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Account</text>
                    </Link>
                  </li>
                </ul>
              </Scroll>
            </nav>
          </group>
        </group>

        <group data-space="30" data-border="" data-background="main-background">
          <group data-background="main-background" data-dark="">
            <wrapper data-align="center">
              <group data-align="center" data-wrap="no">
                <group data-position="right" width="initial">
                  <nav launcher="" data-normal="">
                    <ul data-gap="5">
                      <li>
                        <Link to="">
                          <text>Warranty History</text>
                        </Link>
                      </li>
                      <li>
                        <Separator vertical />
                      </li>
                      <li>
                        <Link to="">
                          <icon>shopping_bag</icon>
                          <text>5</text>
                          <text data-space="10">Cart</text>
                        </Link>
                      </li>
                      <li>
                        <Separator vertical />
                      </li>
                      <li>
                        <Link to="">
                          <icon data-fill="">person</icon>
                          <text data-space-horizontal="10">Welcome Username</text>
                        </Link>
                      </li>
                      <li className="selected">
                        <Button className="mini menu primary extra" toggleClassName="open" material>
                          <icon>
                            <SvgHamburger />
                          </icon>
                        </Button>
                      </li>
                    </ul>
                  </nav>
                </group>
              </group>
            </wrapper>
          </group>
        </group>
      </view>

      <view data-space="20">
        <group data-space="30" data-border="" data-background="main">
          <group>
            <nav className="nav_strip classic">
              <Scroll>
                <ul>
                  <li>
                    <text>Home</text>
                  </li>
                  <li className="selected">
                    <Link to="">
                      <text>Warranty</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text> History</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Warranty</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Directon</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Order History</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Directon</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Sample</text>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <text>Account</text>
                    </Link>
                  </li>
                </ul>
              </Scroll>
            </nav>
          </group>
          <group data-space="30" data-background="main-background" data-gap="10" data-direction="column">
            <text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
              General Information
            </text>
            <text data-wrap="wrap" data-length="610">
              Aenean tempus at nisl et tempor. Morbi et tincidunt justo. Curabitur luctus condimentum justo, sit amet
              suscipit justo laoreet ut. Curabitur ac ornare dolor.
            </text>
          </group>
        </group>

        <Separator horizontal data-margin="30" />

        <group data-space="30" data-border="">
          <group>
            <nav className="nav_strip classic invert">
              <ul>
                <li>
                  <text>Home</text>
                </li>
                <li className="selected">
                  <Link to="">
                    <text>Warranty</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text> History</text>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <text>Warranty</text>
                  </Link>
                </li>
              </ul>
            </nav>
          </group>
          <group data-space="10" data-background="highlight" data-gap="10" data-direction="column">
            <div className="field" data-length="auto">
              <div className="form_fields">
                <div className="field_cont">
                  <input type="text" placeholder="Family, a product, a reference" />
                  <div className="button mini large">
                    <icon>search</icon>
                  </div>
                </div>
              </div>
            </div>
          </group>
        </group>
      </view>

      <view data-space="20"></view>
    </view>
  );
};
export default Landing;
