// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import { Product } from './productModels';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: baseQuery('produits'),
  endpoints: (builder) => {
    return {
      getProduct: builder.query<Product, any>({
        query: ({ univers_uuid, lang, pays_uuid, id, schema_uuid }) => {
          return {
            url: `${id}/detail`,
            method: 'get',
            params: {
              univers_uuid,
              lang,
              pays_uuid,
              schema_uuid,
            },
          };
        },
      }),
    };
  },
});

export const { useGetProductQuery, useLazyGetProductQuery } = productApi;
