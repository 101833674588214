import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation, matchPath } from 'react-router';
import { useGetDocumentsQuery } from '../../store/documents/documentApi';
import { useAppSelector } from '../../store/hooks';
import { Product } from '../../store/product/productModels';
import {
  useGetFamilyDefectsQuery,
  useGetProductDefectsQuery,
  useLazyGetDiagnosticsByFamilyQuery,
  useLazyGetDiagnosticsByProductQuery,
} from '../../store/sav/savApi';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { APP_URL } from '../../project/defines';
import { downloadByLink } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import Select from '../shared/inputs/select';
import Loader from '../shared/loader';
import Popup from '../shared/popup';
import { Text } from '../shared/text';
import { getAppUrl } from '../../project/helpers';
import { SupportDataLayerTypes, onSupportDataLayer } from '../../project/analytics';
import Separator from '../shared/separator';

export enum SupportWidgetType {
  Family = 'family',
  Product = 'product',
}

type TagGroup = { tag: string; text: string; options: any[] };

let TAGS = [
  'PFS_DOC_PRODUIT',
  'PFS_DOC_SAV',
  'PFS_PV_CERTIF',
  'PFS_VIDEO',
  'PFS_FORMATION',
  'PFS_PHOTO',
  'PFS_ACCESSOIRES',
  'PFS_FICHE_DEFAUT',
  'PFS_AUTRE',
];

const SupportWidget: React.FC<{ type: SupportWidgetType; accessoires?: Product[] }> = ({ type, accessoires }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [video, setVideo] = useState<{ show: boolean; videoId?: string; title?: string; videoType?: string }>({
    show: false,
  });

  const isProduct = matchPath({ path: APP_URL.Base + '/' + APP_URL.Product, end: false }, location.pathname ?? '');

  const [diagnosticsPopupState, setDiagnosticsPopupState] = useState<{
    show: boolean;
    comments?: string[];
    uuid?: string;
    label?: string;
  }>({
    show: false,
  });

  const {
    data: documents,
    isLoading: isDocuentsLoading,
    isFetching: isDocuentsFetching,
  } = useGetDocumentsQuery(
    {
      univers_uuid: univers?.uuid ?? '',
      lang,
      pays_uuid,
      ...(isProduct ? { produit: id } : { famille: id }),
    },
    { skip: !id }
  );

  const {
    data: familyDefects,
    isLoading: isFamilyDefectsLoading,
    isFetching: isFamilyDefectsFetching,
  } = useGetFamilyDefectsQuery({ lang, pays_uuid, famille: id }, { skip: type !== SupportWidgetType.Family });
  const isDocsLoading = isDocuentsLoading || isDocuentsFetching;

  const {
    data: productDefects,
    isLoading: isProductDefectsLoading,
    isFetching: isProductDefectsFetching,
  } = useGetProductDefectsQuery(
    { lang, pays_uuid, famille: null, produit: id },
    { skip: type !== SupportWidgetType.Product }
  );

  const defectsResponse = type === SupportWidgetType.Family ? familyDefects : productDefects;
  const isDefectsLoading =
    type === SupportWidgetType.Family
      ? isFamilyDefectsFetching || isFamilyDefectsLoading
      : isProductDefectsFetching || isProductDefectsLoading;

  const diagByFamilyResponse = useLazyGetDiagnosticsByFamilyQuery();
  const diagByProductResponse = useLazyGetDiagnosticsByProductQuery();
  const [triggerDiagnostics, { data: diagnosticsResult, isFetching, isLoading }] =
    type === SupportWidgetType.Family ? diagByFamilyResponse : diagByProductResponse;

  const documentsData = useMemo(() => {
    if (!documents || !Object.keys(documents).length) {
      return [];
    }

    const tagGroups: TagGroup[] = TAGS.map((tag) => {
      return { tag, text: t(`global.docGANatureTags.${tag}`), options: [] };
    });

    Object.keys(documents).forEach((key) => {
      let doc = documents[key as any],
        documentTagsList = doc.tags.split(',').filter((tag) => TAGS.indexOf(tag) > -1),
        tagNature = documentTagsList.length === 0 ? 'PFS_AUTRE' : documentTagsList[0],
        groupOption = tagGroups.find((gr) => gr.tag === tagNature);

      groupOption?.options.push({
        value: doc.uuid,
        text: doc.title + (doc.lang && (doc.lang !== lang && doc.lang !== 'xx') ? ' (' + doc.lang.toUpperCase() + ')' : ''),
        data: doc,
      });
    });

    let dropdownData: any = [];
    tagGroups.forEach((item) => {
      if (item.options.length) {
        dropdownData.push({ text: item.text, isGroup: true });
        dropdownData.push(...item.options);
      }
    });

    return dropdownData;
  }, [documents, t]);

  const defectsData = useMemo(() => {
    if (!defectsResponse?.defauts || !defectsResponse.defauts.length) {
      return [];
    }

    return defectsResponse.defauts.map((item) => {
      return { text: item.libelle, value: item.uuid };
    });
  }, [defectsResponse]);

  const onDocumentChangeHandler = (val: any) => {
    let doc = documentsData?.find((i: any) => i?.data?.uuid === val)?.data;
    if (doc?.type === 'video') {
      setVideo({ show: true, videoId: doc.video_id, title: doc.title, videoType: doc.video_type });
    } else {
      downloadByLink(doc.force_download, doc.title);
    }

    onSupportDataLayer(SupportDataLayerTypes.Documents, doc.title || '');
  };

  const onAccessoriesChangeHandler = (val: any) => {
    const data = accessoires?.find((item) => item.uuid === val);

    onSupportDataLayer(SupportDataLayerTypes.Prestations, data?.libelle ?? '');

    navigate(
      getAppUrl(APP_URL.Product, {
        routeParams: {
          id: data?.uuid ?? '',
        },
      })
    );
  };

  const onDefectsChangeHandler = (val: any) => {
    let def = defectsResponse?.defauts?.find((i) => i?.uuid === val);

    onSupportDataLayer(SupportDataLayerTypes.Diagnostic, def?.libelle ?? '');

    setDiagnosticsPopupState({
      show: true,
      comments: defectsResponse?.commentaires,
      uuid: def?.uuid,
      label: def?.libelle,
    });
  };

  useEffect(() => {
    if (diagnosticsPopupState.show) {
      const diagReq = type === SupportWidgetType.Family ? { famille: id } : { famille: null, produit: id };
      triggerDiagnostics(
        { defaut: diagnosticsPopupState.uuid, lang, pays_uuid, univers_uuid: univers?.uuid, ...diagReq },
        true
      );
    }
  }, [diagnosticsPopupState, id, lang, pays_uuid, triggerDiagnostics, type, univers?.uuid]);

  return (
    <>
      <group data-contain="" data-space="10" data-radius="5" data-border="" data-gap="10" data-background="highlight">
        {(isDefectsLoading || isDocsLoading) && <Loader />}
        <Select
 data-weight="700"
 data-weight-inherit="true"
          dataLength="auto"
          options={documentsData}
          groupOptionRender={({ text }) => (
            <text data-color="main" data-uppercase="" data-weight="700">
              {text}
            </text>
          )}
          disabled={!documentsData.length}
          onSelect={onDocumentChangeHandler}
          placeholder={t(!documentsData.length ? 'blocs.documentation.noDocument' : 'blocs.documentation.placeholder')}
        />
        <Select

data-weight="700"
data-weight-inherit="true"
          dataLength="auto"
          options={
            accessoires?.map((i) => {
              return { text: i.libelle, value: i.uuid };
            }) ?? []
          }
          disabled={!accessoires?.length}
          onSelect={onAccessoriesChangeHandler}
          placeholder={t('blocs.prestations.placeholder')}
        />
        <Select
data-weight="700"
data-weight-inherit="true"
          dataLength="auto"
          options={defectsData}
          disabled={!defectsData.length}
          onSelect={onDefectsChangeHandler}
          placeholder={t('blocs.aideDiagnostic.placeholder')}
        />
      </group>

      {video.show && (
        <Popup
          windowContProps={{ 'data-length': 700 }}
          title={video.title}
          onClose={() => {
            setVideo({ show: false });
          }}
        >
          <group data-contain="" data-ratio="16:9">
            <iframe
              title={video.videoId}
              src={
                video.videoType === 'Vimeo'
                  ? `https://player.vimeo.com/video/${video.videoId}?dnt=true`
                  : `https://www.youtube.com/embed/${video.videoId}`
              }
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </group>
        </Popup>
      )}
      {diagnosticsPopupState.show && (
        <Popup
          title={t('sav.titre')}
          windowContProps={{ 'data-length': 700 }}
          onClose={() => {
            setDiagnosticsPopupState({ show: false });
          }}
        >
          <view data-scroll="">
            <group data-direction="column" data-space="20" data-gap="20">
              {!!diagnosticsPopupState.comments?.length && (
                <group data-direction="column" data-gap="10">
                  <Text data-weight="700">sav.commentaires</Text>
                  {diagnosticsPopupState.comments.map((comment, index) => {
                    return (
                      <Fragment key={comment + index}>
                        {index !== 0 && <Separator horizontal />}
                        <text data-wrap="wrap">{comment}</text>
                      </Fragment>
                    );
                  })}
                </group>
              )}

              {isLoading || isFetching ? (
                <Loader />
              ) : (
                <>
                  <group data-direction="column">
                    <Text data-wrap="wrap" translateArgs={{ defaut: diagnosticsPopupState.label ?? '' }}>
                      sav.diagnostic
                    </Text>
                  </group>
                  <div className="table_view">
                    <table>
                      <thead>
                        <tr>
                          <th>{t('sav.table.symptome')}</th>
                          <th>{t('sav.table.cause')}</th>
                          <th>{t('sav.table.remede')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {diagnosticsResult?.map((diag) => {
                          return (
                            <tr key={diag.uuid}>
                              <td>
                                <wrap>
                                  <text data-wrap="wrap">{diag.symptome}</text>
                                </wrap>
                              </td>
                              <td>
                                <wrap>
                                  <text data-wrap="wrap">{diag.cause}</text>
                                </wrap>
                              </td>
                              <td>
                                <wrap>
                                  <text data-wrap="wrap">{diag.remede}</text>
                                </wrap>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </group>
          </view>
        </Popup>
      )}
    </>
  );
};

export default SupportWidget;
