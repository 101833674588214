import { Table as AntTable, TableProps as AntTableProps } from 'antd';
import { SorterResult, TablePaginationConfig, ColumnType as AntColumnType } from 'antd/lib/table/interface';
import { Sort, tableSort } from '../utils';

export interface ColumnType extends AntColumnType<Record<string, any>> {
  type?: string;
  getValue?: Function;
  searchable?: boolean;
}
export interface TableProps extends Omit<AntTableProps<Record<string, any>>, 'pagination'> {
  dataSource: any[];
  keyField?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  pagination?: false | TablePaginationConfig;
  setPagination?: any;
  setSorter?: any;
  total?: number;
  columns?: ColumnType[];
  columnCountConfig?: boolean;
  showAllColumns?: boolean;
  rowClassName?: any;
  sort?: Sort;
  pagingProps?: TablePaginationConfig;
  defaultSortField?: string;
  view?: string;
  newlyAddedKey?: string;
}

const Table: React.FC<TableProps> = ({
  dataSource = [],
  keyField,
  isLoading,
  isFetching,
  sort,
  pagination = false,
  setSorter,
  columns: columnConfig,
  rowClassName,
  pagingProps,
  defaultSortField,
  newlyAddedKey,
  view,
  ...rest
}) => {
  let columns = columnConfig;
  let items = [...dataSource];

  if (Object.keys(sort || {}).length > 1 || defaultSortField) {
    items = items.sort(tableSort(!Object.keys(sort ?? {}).length ? { field: defaultSortField } : sort ?? {}));
  }

  if (newlyAddedKey) {
    const item = items.splice(items.map((item) => item.uuid).indexOf(newlyAddedKey), 1); //Add new prepend item
    items = [...item, ...items];
  }

  if (pagingProps) {
    let { current, pageSize } = pagingProps;
    current = Math.min(current!, Math.ceil(items.length / pageSize!));
    items = items.slice((current - 1) * pageSize!, current * pageSize!);
  }

  if (keyField) {
    items = items.map((i) => ({ ...i, key: i[keyField] }));
  }
  return (
    <AntTable
      data-view={view}
      columns={columns}
      dataSource={items}
      loading={isFetching || isLoading} //nosonar
      {...(rowClassName ? { rowClassName } : null)}
      pagination={{ hideOnSinglePage: true, pageSize: items.length }}
      onChange={(tablePagination, filter, sorter) => {
        const sort = sorter as any;
        setSorter?.(
          (sort?.column
            ? {
                ...sorter,
                getValue: sort?.column?.getValue,
                type: sort?.column?.type,
                field: sort?.column?.sortField || sort.field,
              }
            : {}) as SorterResult<any>
        );
      }}
      showSorterTooltip={false}
      scroll={{ x: '100%' }}
      {...rest}
    />
  );
};

export default Table;
