import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '../../../components/shared/inputs/input';
import Select from '../../../components/shared/inputs/select';
import { Text } from '../../../components/shared/text';
import { DEFAULT_COUNTRY, WarrantyDeliveryFormFields } from '../../../project/defines';
import { useGetSocieteAddressesQuery } from '../../../store/auth/authApi';
import { AddressModel } from '../../../store/auth/authModels';
import { useAppSelector } from '../../../store/hooks';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import { RegexpPatterns } from '../../../utils/defines';
import useTranslation from '../../../utils/hooks/useTranslation';
import useYupValidationResolver from '../../../utils/hooks/useYupValidationResolver';

const validationSchema = yup.object({
  [WarrantyDeliveryFormFields.reference_commande]: yup.string().required('input.validations.required'),
  [WarrantyDeliveryFormFields.livraison_nom]: yup.string().required('input.validations.required'),
  [WarrantyDeliveryFormFields.livraison_adresse1]: yup.string().required('input.validations.required'),
  [WarrantyDeliveryFormFields.livraison_code_postal]: yup
    .string()
    .when('livraison_pays', {
      is: (livraison_pays: string) => {
        return livraison_pays === 'FR' || livraison_pays === 'ES' || livraison_pays === 'IT';
      },
      then: (schema: any) => schema.matches(/^(\d{5})$/, 'input.validations.code_postal'),
    })
    .when('livraison_pays', {
      is: (livraison_pays: string) => {
        return livraison_pays === 'AD';
      },
      then: (schema: any) => schema.matches(/^(AD\d{3})$/, 'input.validations.code_postal'),
    })
    .when('livraison_pays', {
      is: (livraison_pays: string) => {
        return livraison_pays === 'BE';
      },
      then: (schema: any) => schema.matches(/^(\d{4})$/, 'input.validations.code_postal'),
      otherwise: (schema) => schema.required('input.validations.required'),
    }),
  [WarrantyDeliveryFormFields.livraison_ville]: yup.string().required('input.validations.required'),
  [WarrantyDeliveryFormFields.email]: yup
    .string()
    .required('input.validations.required')
    .matches(RegexpPatterns.email, 'input.validations.email'),
  [WarrantyDeliveryFormFields.livraison_pays]: yup.string().required('input.validations.required'),
});

const DeliveryForm: React.FC<{
  request: WarrantyRequest;
  setGetFormValues: Dispatch<SetStateAction<() => FieldValues>>;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
}> = ({ request, setGetFormValues, setIsFormValid }) => {
  const { t } = useTranslation();
  const countries = useAppSelector((state) => state.siteData.countries);
  const societe_uuid = useAppSelector((state) => state.auth.societe_uuid);
  const { data: addresses } = useGetSocieteAddressesQuery({ societe_uuid }, { skip: !societe_uuid });
  const [lastSelectValue, setLastSelectValue] = useState('');

  const resolver = useYupValidationResolver(validationSchema);
  let defaultValues = useMemo(() => {
    const result: FieldValues = {};
    Object.values(WarrantyDeliveryFormFields).forEach((field: string) => {
      const f = field as keyof WarrantyRequest;
      result[f] = request[f] === null ? '' : request[f];
      if (!result[f] && f === WarrantyDeliveryFormFields.livraison_pays) {
        result[f] = DEFAULT_COUNTRY;
      }
    });
    return result;
  }, [request]);

  const {
    control,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<FieldValues>({
    resolver,
    defaultValues,
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      trigger(name);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    setGetFormValues(() => () => getValues());
  }, [getValues, setGetFormValues]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  const onSelectAddress = (v: string) => {
    const address = addresses?.find((a) => a.uuid === v);
    if (!address) {
      return;
    }

    setValue(WarrantyDeliveryFormFields.livraison_nom, address?.nom || v);
    setLastSelectValue(address?.nom || v);

    const addressFields = ['adresse', 'adresse_complement', 'code_postal', 'ville', 'email'];
    const autofillFields = [
      WarrantyDeliveryFormFields.livraison_adresse1,
      WarrantyDeliveryFormFields.livraison_adresse2,
      WarrantyDeliveryFormFields.livraison_code_postal,
      WarrantyDeliveryFormFields.livraison_ville,
      WarrantyDeliveryFormFields.email,
    ];

    for (let i = 0; i < addressFields.length; i++) {
      setValue(autofillFields[i], address[addressFields[i] as keyof AddressModel] || '');
    }
  };

  return (
    <view data-border="none" data-scroll="" data-space="5">
      <group data-space-vertical="20">
        <Text dataWeight="700" accent dataTextSize="medium" data-wrap="wrap">
          garantie.demande.etapes.3.form.coordonneesLivraison
        </Text>
      </group>
      <group data-direction="column" data-gap="10" data-radius="10" data-space="20" data-background="highlight">
        <Input
          label="panier.commandeModal.reference"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('panier.commandeModal.reference')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.reference_commande}
          control={control}
        />
        <Select
          autocomplete
          allowClear
          showArrow
          label="garantie.demande.etapes.3.form.coordonnees.nom"
          dataLength="autofit"
          size="large"
          name={WarrantyDeliveryFormFields.livraison_nom}
          placeholder={t('panier.commandeModal.selectCreateLabel')}
          onClear={() => {
            setLastSelectValue('');
          }}
          onFocus={() => {
            const lastValue = getValues(WarrantyDeliveryFormFields.livraison_nom);
            if (lastValue) {
              setLastSelectValue(lastValue);
            }
          }}
          onBlur={(event) => {
            const withSameName = addresses?.find((a) => a.nom === event.target.value);
            const withSameUUID = addresses?.find((a) => a.uuid === event.target.value);

            if (!withSameUUID && !withSameName) {
              setValue(WarrantyDeliveryFormFields.livraison_nom, lastSelectValue || '');
            }
            setLastSelectValue('');
          }}
          translateLabel
          control={control}
          options={addresses?.map((a) => ({ value: a.uuid, text: a.nom })) ?? []}
          isGroup
          onSelect={onSelectAddress as any}
        />
        <Input
          label="garantie.demande.etapes.3.form.coordonnees.adresse"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.adresse')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.livraison_adresse1}
          control={control}
          data-appearance="none"
        />
        <Input
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.adresse')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.livraison_adresse2}
          control={control}
          data-appearance="none"
        />
        <Input
          label="garantie.demande.etapes.3.form.coordonnees.codePostal"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.codePostal')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.livraison_code_postal}
          control={control}
          data-appearance="none"
        />
        <Input
          label="garantie.demande.etapes.3.form.coordonnees.ville"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.ville')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.livraison_ville}
          control={control}
          data-appearance="none"
        />
        <Select
          showArrow
          allowClear
          label="garantie.demande.etapes.3.form.coordonnees.pays"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.pays')}
          onSelect={(v) => {
            trigger();
          }}
          options={countries?.map((c) => ({ value: c.code, text: c.nom })) ?? []}
          isGroup
          name={WarrantyDeliveryFormFields.livraison_pays}
          control={control}
        />
        <Input
          label="garantie.demande.etapes.3.form.coordonnees.mail"
          dataLength="autofit"
          size="large"
          aria-invalid="false"
          placeholder={t('garantie.demande.etapes.3.form.coordonnees.mail')}
          translateLabel
          isGroup
          name={WarrantyDeliveryFormFields.email}
          control={control}
          data-appearance="none"
        />
      </group>
    </view>
  );
};

export default DeliveryForm;
