import Button from '../../components/shared/button';
import Separator from '../../components/shared/separator';
import useTranslation from '../../utils/hooks/useTranslation';

const DevisSuccessPopupContent: React.FC<{ pdfUrl?: string; onClose: () => void }> = ({ pdfUrl = '', onClose }) => {
  const { t } = useTranslation();

  const label = t('panier.resultModal.results.success.label');
  const message = t('panier.resultDevisModal.results.success.message');
  return (
    <group data-direction="row" data-gap="20" data-space="20">
      <group>
        <text data-weight="700">{label}</text>: {message}
      </group>
      <Separator horizontal />
      <group data-width="auto" data-gap="10">
        <Button
          text={t('panier.resultDevisModal.closeLink.label')}
          primary
          onClick={() => {
            window.open(pdfUrl, '_blank');
            onClose();
          }}
        />
        <Button text={t('panier.resultDevisModal.closeButton.title')} outline onClick={onClose} />
      </group>
    </group>
  );
};

export default DevisSuccessPopupContent;
