import { useDispatch } from 'react-redux';
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import { Text } from '../../components/shared/text';
import { addNotification } from '../../store/componentsSlice';
import { useAppSelector } from '../../store/hooks';
import { useEmptyCartMutation } from '../../store/shoppingCart/shoppingCartApi';
import { setCartData } from '../../store/shoppingCart/shoppingCartSlice';
import useTranslation from '../../utils/hooks/useTranslation';

type CartHeaderProps = { title: string; count: number; reseauUuid: string; enAttente: boolean };

const CartHeader: React.FC<CartHeaderProps> = ({ title, count, reseauUuid, enAttente }) => {
  const [emptyCart, { isLoading }] = useEmptyCartMutation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);

  return (
    <>
      {isLoading && <Loader />}
      <group
        direction="row"
        data-space="10"
        data-background="main-lighter"
        data-gap="5"
        data-wrap="no"
        data-align="center"
      >
        <group data-width="auto" data-wrap="wrap" data-direction="column">
          <Text data-wrap="wrap">panier.reseau.title</Text>
          <group data-wrap="wrap" data-gap="5">
            <text data-weight="700" data-wrap="wrap">
              {title}
            </text>
            <dot></dot>
            <text data-weight="700">{count}</text>
          </group>
        </group>
        <group data-width="auto" data-position="right">
          <Button
            icon="delete"
            primary
            text="panier.reseau.deleteAll"
            onClick={() => {
              emptyCart({
                reseau_uuid: reseauUuid,
                en_attente: enAttente,
                lang,
                univers_uuid: univers?.uuid,
              }).then((response: any) => {
                if (response?.error) {
                  dispatch(
                    addNotification({
                      type: 'error',
                      message: t('global.notifications.paniers.titleSingle'),
                      description: response?.error.error || t(response.error?.data?.error),
                      duration: 5,
                    })
                  );
                } else {
                  dispatch(setCartData(response.data));
                  dispatch(
                    addNotification({
                      type: 'success',
                      message: t('global.notifications.paniers.titleSingle'),
                      description: t('global.notifications.paniers.empty'),
                      duration: 5,
                    })
                  );
                }
              });
            }}
          />
        </group>
      </group>
    </>
  );
};
export default CartHeader;
