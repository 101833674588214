import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../../components/shared/button';
import { useConfirmationPopup } from '../../components/shared/popup/confirmationPopup';
import Separator from '../../components/shared/separator';
import { getKeyUpHandler } from '../../utils/helpers';

export const SimpleContent: React.FC = () => {
  const { open } = useConfirmationPopup();
  return (
    <>
      <view data-space="50" data-scroll="">
        <text data-light="" data-wrap="wrap" data-length="390">
          Quisque commodo ut urna et dignissim. In vitae dui fermentum odio luctus efficitur in non mauris. Nulla
          tristique ac nisi nec auctor.
        </text>
        <space data-height="30"></space>
        <label className="field" data-label="left" data-multi-element="">
          <div className="form_fields">
            <div className="field_cont">
              <icon>search</icon>
              <Separator vertical />

              <input type="text" placeholder="Sample Placeholder" value="" />
              <div className="button">
                <text>Search</text>
              </div>
              <i></i>
            </div>
          </div>
        </label>
        <space data-height="10"></space>
        <Button
          text="open Confirmation popup"
          onClick={() =>
            open({
              title: 'This Cannot Be Undone',
              message: 'Are you sure you want to delete this item?',
              positiveCallback: () => {},
              positiveButtonText: 'Yes, Delete',
              negativeButtonText: 'Cancel',
              negativeCallBack: () => {},
            })
          }
        />
      </view>
      <group data-space="30" data-gap="10" data-type="grid">
        <div data-effect="material" className="button primary large">
          <text>OK</text>
        </div>
        <div data-effect="material" className="button outline large">
          <text>Cancel</text>
        </div>
      </group>
    </>
  );
};

export const TabsContent: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  return (
    <>
      <div>
        <div className="nav_strip classic">
          <ul>
            <li
              className={classNames({ selected: selectedTabIndex === 0 })}
              onClick={() => {
                setSelectedTabIndex(0);
              }}
              onKeyUp={getKeyUpHandler()}
            >
              <text>Buttons Icons</text>
            </li>
            <li
              className={classNames({ selected: selectedTabIndex === 1 })}
              onClick={() => {
                setSelectedTabIndex(1);
              }}
              onKeyUp={getKeyUpHandler()}
            >
              <text>Buttons Icons</text>
            </li>
          </ul>
        </div>
      </div>
      <view data-space="50" data-scroll="">
        <text data-light="" data-wrap="wrap">
          Quisque commodo ut urna et dignissim. In vitae dui fermentum odio luctus efficitur in non mauris. Nulla
          tristique ac nisi nec auctor.
        </text>
        <icon></icon>
        <label className="field" data-label="left" data-multi-element="">
          <div className="form_fields">
            <div className="field_cont">
              <icon>search</icon>
              <Separator vertical />

              <input type="text" placeholder="Sample Placeholder" value="" />
              <div className="button">
                <text>Search</text>
              </div>
              <i></i>
            </div>
          </div>
        </label>
      </view>
      <toolbar>
        <div className="button primary large">
          <text>Sample Action</text>
        </div>
        <text data-space="10" data-ellipsis="" data-wrap="nowrap">
          This Toolbar is Part of content
        </text>
      </toolbar>
    </>
  );
};
