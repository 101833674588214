// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

import {
  AuthModel,
  MeAuthRequestModel,
  UserGenelalInformationRequestModel,
  UserAddressRequestModel,
  UserPasswordRequestModel,
  UserSocieteAddressRequestModel,
  CollaborateurModel,
  AccessModel,
  ClientModel,
  AddressModel,
  CGVResponseModel,
} from './authModels';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery(''),
  endpoints: (builder) => {
    return {
      getLoggedUser: builder.query<AuthModel, MeAuthRequestModel>({
        query: ({ univers_uuid, lang }) => {
          return {
            url: 'me',
            method: 'get',
            params: {
              univers_uuid,
              lang,
            },
          };
        },
      }),
      isConnected: builder.query<boolean, void>({
        query: () => {
          return {
            url: 'is_connected',
            method: 'get',
            params: {},
          };
        },
      }),
      updateLoggedUser: builder.mutation<AuthModel, UserGenelalInformationRequestModel | UserAddressRequestModel>({
        query: ({ users_uuid, ...params }) => {
          return {
            url: `users\\${users_uuid}`,
            method: 'put',
            params,
          };
        },
      }),
      updateSociete: builder.mutation<AuthModel, UserSocieteAddressRequestModel>({
        query: ({ societe_uuid, ...body }) => {
          return {
            url: `societes\\${societe_uuid}`,
            method: 'put',
            body,
          };
        },
      }),
      updateCollaborateur: builder.mutation<CollaborateurModel, CollaborateurModel>({
        query: ({ uuid, ...body }) => {
          return {
            url: `collaborateurs\\${uuid}`,
            method: 'put',
            body,
          };
        },
      }),
      getCollaborateurs: builder.query<CollaborateurModel[], any>({
        query: ({ societe_uuid }) => {
          return {
            url: `societes\\${societe_uuid}\\collaborateurs`,
            method: 'get',
          };
        },
      }),
      deleteCollaborateur: builder.mutation<CollaborateurModel[], string>({
        query: (collaborateur_uuid) => {
          return {
            url: `collaborateurs\\${collaborateur_uuid}`,
            method: 'delete',
          };
        },
      }),
      addClient: builder.mutation<any, ClientModel>({
        query: ({ ...body }) => {
          return {
            url: `clients`,
            method: 'post',
            body,
          };
        },
      }),
      updateClient: builder.mutation<ClientModel, ClientModel>({
        query: ({ uuid, ...body }) => {
          return {
            url: `clients\\${uuid}`,
            method: 'put',
            body,
          };
        },
      }),
      getClients: builder.query<ClientModel[], any>({
        query: ({ societe_uuid }) => {
          return {
            url: `societes\\${societe_uuid}\\clients`,
            method: 'get',
          };
        },
      }),
      getSocieteAddresses: builder.query<AddressModel[], any>({
        query: ({ societe_uuid }) => {
          return {
            url: `societes\\${societe_uuid}\\adresses`,
            method: 'get',
          };
        },
      }),
      getCGVContent: builder.query<CGVResponseModel, any>({
        query: ({ reseau_uuid }) => {
          return {
            url: `cgv\\${reseau_uuid}`,
            method: 'get',
          };
        },
      }),
      deleteClient: builder.mutation<ClientModel[], string>({
        query: (uuid) => {
          return {
            url: `clients\\${uuid}`,
            method: 'delete',
          };
        },
      }),
      getAcces: builder.query<AccessModel[], any>({
        query: () => {
          return {
            url: `acces`,
            method: 'get',
          };
        },
      }),
      addCollaborateur: builder.mutation<any, CollaborateurModel>({
        query: ({ ...body }) => {
          return {
            url: `collaborateurs`,
            method: 'post',
            body,
          };
        },
      }),
      updateLoggedUserPassword: builder.mutation<AuthModel, UserPasswordRequestModel>({
        query: ({ users_uuid, ...body }) => {
          return {
            url: `users\\${users_uuid}\\password`,
            method: 'put',
            body,
          };
        },
      }),
    };
  },
});

export const {
  useGetLoggedUserQuery,
  useUpdateLoggedUserMutation,
  useUpdateLoggedUserPasswordMutation,
  useUpdateSocieteMutation,
  useGetCollaborateursQuery,
  useDeleteCollaborateurMutation,
  useGetAccesQuery,
  useUpdateCollaborateurMutation,
  useAddCollaborateurMutation,
  useAddClientMutation,
  useUpdateClientMutation,
  useGetClientsQuery,
  useDeleteClientMutation,
  useGetSocieteAddressesQuery,
  useGetCGVContentQuery,
  useLazyIsConnectedQuery,
} = authApi;
