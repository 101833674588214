import classNames from 'classnames';
import { ReactElement, HTMLAttributes, PropsWithChildren } from 'react';

import useTranslation from '../../../utils/hooks/useTranslation';
import { Text } from '../text';

export interface FormFieldProps {
  label?: string;
  className?: string;
  dataLength?: string;
  translateLabel?: boolean;
  validationMessageOnTop?: boolean;
  isAnt?: boolean;
  button?: ReactElement;
  icon?: string;
  isGroup?: boolean;
  error?: any;
}
export const FormField: React.FC<PropsWithChildren<FormFieldProps & HTMLAttributes<HTMLElement>>> = ({
  icon,
  label,
  className,
  translateLabel = true,
  button,
  validationMessageOnTop = false,
  children,
  dataLength,
  isAnt,
  isGroup,
  error,
  ...rest
}) => {
  const { t } = useTranslation();
  const labelText = label && translateLabel ? t(label) : label;

  return isGroup ? (
    <group data-gap="10">
      <label className={classNames('field', className, { third_part: isAnt })} data-length={dataLength} {...rest}>
        <div className="form_fields">
          {validationMessageOnTop && !!error && (
            <group data-name="error" data-color="error" data-length="autofit">
              <Text data-wrap="wrap">{error.message}</Text>
            </group>
          )}
          {!!labelText && (
            <div className="data_label">
              <Text data-space="5">{labelText}</Text>
            </div>
          )}
          <div className="field_cont">
            {icon && <icon>{icon}</icon>}
            {children}
            {button}
            <i />
          </div>
          {!validationMessageOnTop && !!error && (
            <group data-name="error" data-color="error" data-length="autofit" data-space="5">
              <Text data-wrap="wrap">{error.message}</Text>
            </group>
          )}
        </div>
      </label>
    </group>
  ) : (
    <div className={classNames('field', className, { third_part: isAnt })} data-length={dataLength} {...rest}>
      <div className="form_fields ">
        {labelText && (
          <div className="data_label">
            <text data-ellipsis="">{labelText}</text>
          </div>
        )}

        <div className="field_cont">
          {icon && <icon>{icon}</icon>}
          {children}
          {button}
          {!isAnt && <i />}
        </div>
      </div>
    </div>
  );
};
