import React from 'react';
import {setMode} from '../../store/componentsSlice';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import Radio, {RadioType} from './inputs/radio';
import OptionBar from './optionBar';

export type Mode = 'light' | 'dark' | 'auto';

const modes = [
  {value: 'light', icon: 'light_mode'},
  {value: 'dark', icon: 'dark_mode'},
  {value: 'auto', label: 'Device Theme',labelProps:{'data-fit':''}},
];

function DarkModeToggle() {
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.components.mode);

  function handleModeChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setMode(event.target.value as Mode));
  }

  return (
    <OptionBar compact>
      {modes.map((m) => (
        <Radio
          value={mode}
          key={m.value}
          icon={m.icon}
          radioType={RadioType.Button}
          radioValue={m.value}
          label={m.label}
          onChange={handleModeChange}
          labelProps={m.labelProps}
        />
      ))}
    </OptionBar>
  );
}

export default DarkModeToggle;
