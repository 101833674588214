import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/shared/button';
import Checkbox from '../../components/shared/inputs/checkbox';
import Radio, { RadioType } from '../../components/shared/inputs/radio';
import OptionBar from '../../components/shared/optionBar';
import Separator from '../../components/shared/separator';

const CheckboxAndSwitchers: React.FC = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: { 7: true, 6: true, 8: 'Option3' } as any,
  });
  return (
    <view data-vertical="true" data-adaptive="">
      <view>
        <demo_wrap>
          <Checkbox name="1" label="Simple Checkbox 1" classic control={control} />
          <Separator vertical />
          <Checkbox name="2" label="Simple Checkbox 2" classic disabled />
        </demo_wrap>
        <demo_wrap>
          <Checkbox name="3" label="Simple Checkbox 3" control={control} />
          <Separator vertical />
          <Checkbox name="4" />
          <Separator vertical />
          <Checkbox name="5" label="Disabled Checkbox 5" disabled control={control} />
        </demo_wrap>
        <demo_wrap>
          <Checkbox name="6" minimal simple />

          <Checkbox name="6" minimal label="Simple Checkbox 6" control={control} />
          <Checkbox name="7" minimal label="Disabled Checkbox 7" disabled control={control} />
          <Separator vertical />
        </demo_wrap>
        <demo_wrap>
          <label data-simple="" checkbox="">
            <name>
              <b>Section Title</b>
            </name>
            <Separator vertical /> <input type="checkbox" />
            <tip></tip>
          </label>
        </demo_wrap>
        <demo_wrap>
          <Radio name="8" radioValue="Option1" label="Radio Button 8 Option1" control={control} />
          <Radio name="8" radioValue="Option2" label="Radio Button 8 Option2" control={control} />
          <Radio name="8" radioValue="Option3" label="Radio Button 8 Option3 disabled" control={control} disabled />
          <Radio
            name="8"
            radioValue="Option4"
            allowUncheck
            label="Radio Button 8 Option4 allowed uncheck"
            control={control}
          />
        </demo_wrap>
        <toolbar data-space="20">
          <group data-type="grid" data-gap="10">
            <Button primary large text="Submit" onClick={handleSubmit((data: any) => console.log(data))} />
            <Button outline large text="Reset" onClick={() => reset()} />
          </group>
        </toolbar>
      </view>
      <view>
        <demo_wrap>
          <OptionBar autosize>
            <Radio
              control={control}
              radioType={RadioType.Button}
              name="RadioDemo1"
              radioValue="Dashboard"
              label="Dashboard"
            />
            <Radio
              control={control}
              radioType={RadioType.Button}
              name="RadioDemo1"
              radioValue="Listview"
              icon="table_rows"
              label="List View"
            />
          </OptionBar>
        </demo_wrap>
        <demo_wrap data-gap="20">
          <OptionBar compact dynamic>
            <group>
              <Radio
                control={control}
                radioType={RadioType.Button}
                allowUncheck
                name="RadioDemo2"
                radioValue="Thumbnail View"
                icon="grid_view"
                tooltip="Thumbnail View"
              />
              <Radio
                control={control}
                radioType={RadioType.Button}
                name="RadioDemo2"
                radioValue="Column View"
                icon="view_stream"
                tooltip="Column View"
              />
              <Radio
                control={control}
                radioType={RadioType.Button}
                name="RadioDemo2"
                radioValue="Table View"
                icon="table_rows"
                tooltip="Table View"
              />
            </group>
          </OptionBar>
          <Separator vertical />
          <OptionBar compact dynamic>
            <group>
              <Radio
                control={control}
                radioType={RadioType.Button}
                allowUncheck
                name="RadioDemo6"
                radioValue="Thumbnail View"
                label="Leaf"
                icon="nest_eco_leaf"
                tooltip="Leaf"
              />
              <Radio
                control={control}
                radioType={RadioType.Button}
                name="RadioDemo6"
                radioValue="Column View"
                label="Sample Label"
                tooltip="Column View"
              />
              <Radio
                control={control}
                radioType={RadioType.Button}
                name="RadioDemo6"
                radioValue="Table View"
                icon="home"
                tooltip="Home"
              />
            </group>
          </OptionBar>
        </demo_wrap>
        <demo_wrap>
          <OptionBar compact dynamic>
            <Radio
              control={control}
              radioType={RadioType.Button}
              name="RadioDemo3"
              radioValue="Grid View"
              icon="grid_view"
              label="Grid View"
            />
            <Radio
              control={control}
              radioType={RadioType.Button}
              name="RadioDemo3"
              radioValue="List View"
              icon="table_rows"
              label="List View"
            />
            <Radio
              control={control}
              radioType={RadioType.Button}
              name="RadioDemo3"
              radioValue="Dashboard"
              icon="view_stream"
              label="Dashboard"
            />
          </OptionBar>
          <OptionBar compact>
            <group>
              <label data-effect="material">
                <input type="radio" name="demo_2" value="" />
                <wrap>
                  <icon>table_rows</icon>
                </wrap>
              </label>
              <label data-effect="material">
                <wrap data-gap="none">
                  <icon>expand_more</icon>
                </wrap>
              </label>
            </group>
          </OptionBar>

          <text data-space="10"> Animated Switcher </text>
          <Separator vertical />

          <OptionBar fixed animate>
            <Radio control={control} radioType={RadioType.Button} name="RadioDemo4" radioValue="true" label="Yes" />
            <Radio control={control} radioType={RadioType.Button} name="RadioDemo4" radioValue="false" label="No" />
          </OptionBar>
        </demo_wrap>
      </view>
    </view>
  );
};
export default CheckboxAndSwitchers;
