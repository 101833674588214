import classNames from 'classnames';
import { Fragment } from 'react';
import NavigationItem, { IconProps, NavigationItemProps } from './navigationItem';

export interface NavigationListProps {
  data: NavigationItemProps[];
  type?: string;
  launcherItem?: boolean;
  launcher?: boolean;
  navStrip?: boolean;
  classic?: boolean;
  invert?: boolean;
  round?: boolean;
  mobile?: boolean;
  semiround?: boolean;
  separator?: boolean;
  background?: string;
  className?: string;
  gap?: string;
  adaptive?: string;
  justify?: string;
  space?: number;
  navProps?: any;
  iconProps?: IconProps;
  liProps?: any;
  textProps?: any;
  onClick?: (item?: NavigationItemProps) => void;
  selected?: (item?: NavigationItemProps, items?: NavigationItemProps[], index?: number) => boolean;
}

const NavigationList: React.FC<NavigationListProps> = ({
  className,
  launcher,
  launcherItem,
  type,
  separator,
  navStrip,
  classic,
  invert,
  round,
  mobile,
  semiround,
  background,
  data,
  gap,
  adaptive,
  justify,
  space,
  navProps = {},
  iconProps,
  liProps,
  textProps,
  onClick,
  selected,
}) => {
  launcher && (navProps['launcher'] = '');
  launcherItem && (navProps['data-launcher-item'] = '');
  adaptive && (navProps['data-adaptive'] = adaptive);
  justify && (navProps['data-justify'] = justify);
  type && (navProps['data-type'] = type);
  space && (navProps['data-space'] = space);
  background && (navProps['data-background'] = background);

  return (
    <nav
      {...navProps}
      className={classNames(className, {
        nav_strip: navStrip,
        round,
        mobile,
        semiround,
        classic,
        invert,
      })}
    >
      <ul {...(gap ? { 'data-gap': gap } : null)}>
        {!!data?.length &&
          data.map((item, index) => {
            const key = `${item.text}_${item.link}_${index}`;
            return (
              <Fragment key={key}>
                {item.render ? (
                  item.render()
                ) : (
                  <NavigationItem
                    iconProps={{ ...iconProps, ...item.iconProps }}
                    liProps={{ ...liProps, ...item.liProps }}
                    textProps={{ ...textProps, ...item.textProps }}
                    text={item.text}
                    innerContent={item.innerContent}
                    url={item.url}
                    index={index}
                    link={item.link}
                    icon={item.icon}
                    count={item.count}
                    accent={item.accent}
                    tooltip={item.tooltip}
                    svg={item.svg}
                    separator={separator}
                    selected={selected?.(item, data, index)}
                    onClick={() => {
                      item.onClick ? item.onClick(item) : onClick?.(item);
                    }}
                  />
                )}
              </Fragment>
            );
          })}
      </ul>
    </nav>
  );
};

export default NavigationList;
