import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/button';
import Separator from '../../components/shared/separator';
import { SvgPlus } from '../../components/svg';

const ProductBrowser: React.FC = () => {
  return (
    <view data-vertical="" data-adaptive="">
      <view>
        <group data-space="10">
          <div className="button large primary">
            <text>Product Search</text>
            <icon>expand_more</icon>
          </div>
        </group>

        <group>
          <div className="nav_tree_cont">
            <div className="nav_tree_menu">
              <div className="nav_tree_menu_wrap">
                <div className="list_menu">
                  <ul>
                    <li data-effect="material" className="selected">
                      <label>
                        <Link to="">
                          <text>Heating Emitters</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Climatisation</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Heat Pump</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Stoves</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Hot Water</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Ventilation</text>
                        </Link>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="nav_tree_menu">
              <div className="nav_tree_menu_wrap">
                <div className="list_menu">
                  <ul>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Electric Heating</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material" className="selected">
                      <label>
                        <Link to="">
                          <text>Hot Water (Towel Dryer)</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Fan Coil Radiators</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                    <li data-effect="material">
                      <label>
                        <Link to="">
                          <text>Heating And Cooling Ceiling</text>
                        </Link>
                        <Button mini large toggleClassName="open" material>
                          <icon data-opacity="light">
                            <SvgPlus />
                          </icon>
                        </Button>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </group>
      </view>
      <view>
        <view data-space="20">
          <group data-space="10" data-background="main-background" data-border="" data-length="280" data-radius="10">
            <div className="list_menu">
              <ul>
                <li>
                  <icon></icon>
                  <text data-weight="700">My Account</text>
                </li>
                <li className="separator"></li>
                <li>
                  <icon></icon>
                  <text data-position="left">Feedback</text>

                  <icon data-opacity="light">navigate_next</icon>
                </li>
                <li>
                  <icon>nest_eco_leaf</icon>
                  <text>Support</text>
                </li>
                <li className="separator"></li>
                <li>
                  <label classic="" className="">
                    <input type="checkbox" placeholder=" " name="1" />
                    <box>
                      <check></check>
                    </box>
                    <text className="">Simple Checkbox</text>
                  </label>
                </li>
                <li className="separator"></li>
                <li>
                  <label classic="" className="">
                    <input type="checkbox" placeholder=" " name="1" />
                    <box>
                      <check></check>
                    </box>
                    <text className="">
                      {' '}
                      Fusce massa orci, imperdiet nec magna non, elementum aliquet augue fringilla a mi vel ...
                    </text>
                  </label>
                </li>
                <li className="separator"></li>
                <li>
                  <icon></icon>
                  <text>Settings</text>
                </li>
                <li className="separator"></li>

                <div className="option_bar compact">
                  <label>
                    <input type="radio" placeholder=" " name="sample" value="light" />
                    <wrap>
                      <icon>light_mode</icon>
                    </wrap>
                  </label>
                  <label>
                    <input type="radio" placeholder=" " name="sample" value="dark" />
                    <wrap>
                      <icon>dark_mode</icon>
                    </wrap>
                  </label>
                  <label data-fit="">
                    <input type="radio" placeholder=" " name="sample" value="auto" />
                    <wrap>
                      <text>Device Theme</text>
                    </wrap>
                  </label>
                </div>

                <li className="separator"></li>
                <li>
                  <icon></icon>
                  <group data-gap="10" data-align="center">
                    <text>Version</text>
                    <Separator vertical />
                    <text light="">2.0.0</text>
                  </group>
                </li>
                <li>
                  <icon></icon>
                  <text data-weight="700">Sign Out</text>
                </li>
              </ul>
            </div>
          </group>
        </view>
      </view>
    </view>
  );
};
export default ProductBrowser;
