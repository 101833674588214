import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { DEFAULT_COUNTRY, LOCAL_STORAGE_KEYS } from '../../project/defines';
import { getIsBetweenDates, getUuidByCodePays } from '../../utils/helpers';
import { Country } from '../country/countryModels';
import { Family } from '../family/familyModels';
import { UniversInfo } from '../univers/universModels';
import { SiteData } from './siteDataModels';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../utils/helpers/localStorage';

let initialState: SiteData = {
  currentCountryUuid: getLocalStorageItem(LOCAL_STORAGE_KEYS.CurrentCountry) ?? '',
};

export const siteDataSlice = createSlice({
  name: 'siteData',
  initialState: initialState,
  reducers: {
    setUnivers(state, action: PayloadAction<UniversInfo>) {
      state.universInfo = action.payload;
    },
    setCountries(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
    },
    setActiveCountries(state, action: PayloadAction<Country[]>) {
      state.activeCountries = action.payload;
    },
    // setCurrentCountryUuid(state, action: PayloadAction<string>) {
    //   state.currentCountryUuid = action.payload;
    //   setLocalStorageItem(LOCAL_STORAGE_KEYS.CurrentCountry, action.payload);
    // },
    setCurrentCountry(state, action: PayloadAction<Country | undefined>) {
      state.currentCountry = action.payload;
      state.currentCountryUuid = state.currentCountry?.uuid;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.Country, JSON.stringify(state.currentCountry));
    },
    // clearCurrentCountryUuid(state) {
    //   state.currentCountryUuid = '';
    //   removeLocalStorageItem(LOCAL_STORAGE_KEYS.CurrentCountry);
    // },
    clearCurrentCountry(state) {
      state.currentCountryUuid = '';
      state.currentCountry = undefined;
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.CurrentCountry);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.Country);
    },
    setFamilies(state, action: PayloadAction<Family[]>) {
      state.families = action.payload;
    },
  },
});

export const {
  setUnivers,
  setActiveCountries,
  setFamilies,
  //setCurrentCountryUuid,
  setCurrentCountry,
  //clearCurrentCountryUuid,
  setCountries,
} = siteDataSlice.actions;
export default siteDataSlice.reducer;

export const siteData = (state: RootState) => state.siteData;
export const currentCountryUuid = (state: RootState) => {
  return (
    state.siteData?.currentCountryUuid ?? getUuidByCodePays(state.siteData?.activeCountries ?? [], DEFAULT_COUNTRY)
  );
};

export const families = (state: RootState) => state.siteData.families;
export const isDisabledLogin = (state: RootState) =>
  state.siteData.universInfo?.maintenance &&
  state.siteData.universInfo?.maintenance?.disabled_login &&
  getIsBetweenDates(state.siteData.universInfo.maintenance);
