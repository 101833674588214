// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import { WarrantyRequest } from '../warrantyRequest/warrantyRequestModels';

export const warrantyHistoryApi = createApi({
  reducerPath: 'warrantyHistoryApi',
  baseQuery: baseQuery('garanties'),
  tagTypes: ['WarrantyHistory'],
  endpoints: (builder) => {
    return {
      getWarrantyHistory: builder.query<WarrantyRequest[], {
        univers_uuid: string, societe_uuid: string, lang: string, archive: boolean;
      }>({
        query: ({ univers_uuid, societe_uuid, lang, archive }) => {
          return {
            url: `demandes\\societe\\${societe_uuid}`,
            method: 'get',
            params: {
              univers_uuid,
              lang,
              archive
            }
          };
        },
        providesTags: ['WarrantyHistory']
      }),
      deleteWarranty: builder.mutation<WarrantyRequest[], string>({
        query: (demande_uuid) => {
          return {
            url: `demandes\\${demande_uuid}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['WarrantyHistory']
      }),
    };
  },
});

export const { useGetWarrantyHistoryQuery, useDeleteWarrantyMutation } = warrantyHistoryApi;
