import React from 'react';

type LoaderProps = { isSticky?: boolean };
const Loader: React.FC<LoaderProps> = React.forwardRef<HTMLDivElement, { isSticky?: boolean }>(({ isSticky }, ref) => {
  return (
    <div ref={ref} className="preloader">
      <div className="background" data-background="main-background"></div>
      <div className="loader" {...(isSticky ? { 'data-sticky': '' } : null)}>
        <div></div>
      </div>
    </div>
  );
});

export default Loader;
