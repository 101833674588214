import { useCallback, useMemo } from 'react';
import { DEFAULT_LANGUAGE } from '../../project/defines';
import { useAppSelector } from '../../store/hooks';

const useTranslation = () => {
  const languages = useAppSelector((state) => state.language.languages);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const translations = useAppSelector((state) => state.language.translations);

  //const [language, setLanguage] = useState<string>(lang || DEFAULT_LANGUAGE);
  //const [parentLanguages, setParentLanguages] = useState<string[]>([]);
  const language = lang || DEFAULT_LANGUAGE;
  const parentLanguages = useMemo(() => {
    const parents: string[] = [];
    if (!languages) return;

    const sorted = [...languages].sort((a, b) => {
      return Number(a.poid) - Number(b.poid);
    });
    const currentLanguagePoid = languages.find((lng) => {
      return lng.lang === language;
    })?.poid;

    for (let lng of sorted) {
      if (Number(lng.poid) > Number(currentLanguagePoid)) {
        parents.push(lng.lang!);
      }
    }
    return parents;
  }, [language, languages]);

  const getTranslatedField: (translation: string) => string = useCallback(
    (translation: string) => {
      const tFields = JSON.parse(translation);
      if (tFields[language]) {
        return tFields[language];
      } else if (parentLanguages?.length) {
        for (let parent of parentLanguages) {
          if (tFields[parent]) {
            return tFields[parent];
          }
        }
      }
    },
    [parentLanguages, language]
  );

  const t = useCallback(
    (key: string = '', args?: Record<string, string | number>) => {
      let result = key;
      if (translations && key) {
        const current = translations[key];
        if (current) {
          result = getTranslatedField(current);
        }
      }
      if (args) {
        Object.keys(args).forEach((k) => (result = result.replaceAll(`%${k}%`, args[k] as string)));
      }
      return result;
    },
    [getTranslatedField, translations]
  );

  return { t };
};

export default useTranslation;
