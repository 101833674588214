export interface SeparatorProps {
  horizontal?: string | boolean;
  vertical?: string | boolean;
}

const Separator: React.FC<SeparatorProps> = ({ vertical, horizontal, ...rest }) => {
  const separatorProps: SeparatorProps = {};
  if (vertical) { separatorProps.vertical = vertical === true ? "" : vertical; }
  if (horizontal) { separatorProps.horizontal = horizontal === true ? "" : horizontal; }
  return <separator {...separatorProps}{...rest} />;
};

export default Separator;
