import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { APP_URL, DEFAULT_DATE_FORMAT, WarrantyRequestValideStatus } from '../../project/defines';
import { getAppUrl, getWarrantyPrintUrl } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import { useGetProductQuery } from '../../store/product/productApi';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import {
  useGetWarrantyArticlesQuery,
  useGetWarrantyDefectsQuery,
} from '../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest } from '../../store/warrantyRequest/warrantyRequestModels';
import { formatDate } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';

const WarrantyHistoryDetail: React.FC<PropsWithChildren<{ warrantyHistory?: WarrantyRequest; setOpenDetail: any }>> = ({
  warrantyHistory,
  setOpenDetail,
}) => {
  const { t } = useTranslation();
  const { siteData } = useAppSelector((state) => state);
  const univers = siteData?.universInfo?.univers;
  const pays_uuid = useAppSelector(currentCountryUuid);
  const lang = useAppSelector((state) => state.language.currentLanguage);

  const { data: warrantyDefects, isFetching } = useGetWarrantyDefectsQuery(warrantyHistory?.defaut_uuid ?? '', {
    skip: !warrantyHistory?.defaut_uuid,
  });
  const { data: articles, isFetching: isFetchingArticles } = useGetWarrantyArticlesQuery(
    {
      uuid: warrantyHistory?.uuid ?? '',
      univers_uuid: univers?.uuid ?? '',
      pays_uuid: pays_uuid ?? '',
      lang: lang ?? '',
    },
    { skip: !warrantyHistory?.uuid || !univers?.uuid || !pays_uuid || !lang }
  );

  const { data: product, isFetching: isProductFetching } = useGetProductQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id: warrantyHistory?.produit_uuid ?? '', schema_uuid: '' },
    { skip: !warrantyHistory?.produit_uuid || !pays_uuid || !univers?.uuid || !lang }
  );

  return (
    <>
      {(isFetching || isFetchingArticles || isProductFetching) && <Loader />}

      <group data-space="20" data-scroll="">
        <group data-gap="5" data-direction="column">
          <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
            garantie.listingDemandes.modale.body.identiteDemandeur
          </Text>
          <Text data-weight="700">{`${warrantyHistory?.user?.nom ?? ''} ${warrantyHistory?.user?.prenom ?? ''}`}</Text>
        </group>

        <space data-height="40"></space>

        <group>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="">garantie.listingDemandes.modale.body.tel</Text>
              {warrantyHistory?.user?.telephone && (
                <a data-color="main" data-weight="700" href={`tel:${warrantyHistory?.user?.telephone}`}>
                  {warrantyHistory?.user?.telephone}
                </a>
              )}
            </group>
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.mail
              </Text>
              {warrantyHistory?.user?.telephone && (
                <a data-color="main" data-weight="700" href={`mailto:${warrantyHistory?.email}`}>
                  {warrantyHistory?.email}
                </a>
              )}
            </group>
          </group>
          <space data-adaptive="mobile" data-height="20"></space>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="">garantie.listingDemandes.modale.body.fax</Text>
              <Text data-wrap="wrap">{warrantyHistory?.user?.fax}</Text>
            </group>

            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.correspondant
              </Text>
              <Text data-wrap="wrap">
                {warrantyHistory?.user?.nom} {warrantyHistory?.user?.prenom}
              </Text>
            </group>
          </group>
        </group>

        <space data-height="30"></space>
        <Separator horizontal />
        <space data-height="30"></space>

        <group data-gap="5" data-direction="column">
          <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
            garantie.listingDemandes.modale.body.produits
          </Text>

          {product && (
              <Text data-weight="700" data-text-size="" data-wrap="wrap">
                {product.libelle}
              </Text>
          )}
        </group>
        <space data-height="40"></space>
        <group>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="wrap" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.typeGarantie
              </Text>
              <Text>{warrantyHistory?.garantie_type?.libelle}</Text>
            </group>

            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.commentaire
              </Text>
              <Text data-wrap="wrap">{warrantyHistory?.commentaire}</Text>
            </group>
          </group>
          <space data-adaptive="mobile" data-height="20"></space>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.numSerie
              </Text>
              <Text data-wrap="wrap">{warrantyHistory?.no_serie}</Text>
            </group>

            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              {/*<text>{product ? warrantyHistory?.commentaire : '\u00A0'}</text>*/}
              {warrantyHistory?.fichiers &&
                warrantyHistory?.fichiers.length > 0 &&
                warrantyHistory?.fichiers[0].image && (
                  <Text data-light="" data-wrap="wrap">
                    garantie.listingDemandes.modale.body.image
                  </Text>
                )}
              {warrantyHistory?.fichiers &&
                warrantyHistory?.fichiers.length > 0 &&
                warrantyHistory?.fichiers[0].image && (
                  <a href={warrantyHistory?.fichiers[0].image} target="_blank" rel="noreferrer">
                    <Button micro effect="material" icon="description" />
                  </a>
                )}
            </group>
          </group>
          <group data-adaptive="800"></group>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.dateMiseEnService
              </Text>
              <text data-wrap="wrap">
                {warrantyHistory?.date_mise_en_service ? formatDate(warrantyHistory.date_mise_en_service, DEFAULT_DATE_FORMAT) : ''}
              </text>
            </group>
          </group>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.defautPresume
              </Text>
              <Text data-wrap="wrap">{warrantyDefects?.libelle}</Text>
            </group>
          </group>
          <group data-adaptive="800">
            <group data-gap="10" data-space="5" data-wrap="no" data-length="autofit" data-adaptive="600">
              <Text data-light="" data-wrap="wrap">
                garantie.listingDemandes.modale.body.refArticles
              </Text>
              <text data-wrap="wrap">
                {articles
                  ? articles.map((article, index) => {
                      return article.reference + (index < articles.length - 1 ? ' / ' : '');
                    })
                  : ''}
              </text>
            </group>
          </group>
        </group>
      </group>
      {warrantyHistory?.commande && (
        <>
          <space data-height="10"></space>
          <Separator horizontal data-margin="none" />
          <group data-space="20">
            <group data-gap="30" data-wrap="no">
              <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                garantie.listingDemandes.modale.body.numCommande
              </Text>
            </group>
            <group data-gap="30" data-wrap="no">
              <Link
                to={getAppUrl(APP_URL.OrderDetails, { routeParams: { id: warrantyHistory?.commande.uuid } })}
                title={t('garantie.listingDemandes.modale.body.consultCommande')}
              >
                <Text data-weight="700" data-color="main" data-text-size="large" data-wrap="wrap">
                  {warrantyHistory?.commande?.reference}
                </Text>
              </Link>
            </group>
          </group>
        </>
      )}
      {warrantyHistory?.etape !== 'etape_end' ? (
          <group data-space="30" data-justify="center" data-position="bottom">
            <Link
              to={getAppUrl(APP_URL.WarrantyReplacement, { routeParams: { reference: warrantyHistory?.uuid! } })}
              title={t('garantie.listingDemandes.modale.buttons.repriseDemande.title')}
            >
              <Button large primary title={t('garantie.listingDemandes.modale.buttons.repriseDemande.title')}>
                <Text>garantie.listingDemandes.modale.buttons.repriseDemande.libelle</Text>
              </Button>
            </Link>
          </group>
      ) : warrantyHistory?.etape === 'etape_end' &&
        (warrantyHistory?.valide === WarrantyRequestValideStatus.Transmise ||
          warrantyHistory?.valide === WarrantyRequestValideStatus.Exporte ||
          warrantyHistory?.valide === WarrantyRequestValideStatus.Acceptee) && 
          <group data-space="30" data-justify="center" data-position="bottom">
            <Button
              large
              primary
              title={t('garantie.listingDemandes.modale.buttons.impression.title')}
              onClick={() => window.open(getWarrantyPrintUrl(warrantyHistory.uuid!, univers?.uuid!, lang))}
            >
              <Text>garantie.listingDemandes.modale.buttons.impression.title</Text>
            </Button>
          </group>
      }
    </>
  );
};

export default WarrantyHistoryDetail;
