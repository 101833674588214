import { Text } from '../../components/shared/text';

const CartListTitle: React.FC = () => {
  return (
    <group data-align="center" data-space="10" data-gap="10" data-wrap="no">
      <group data-direction="column" data-contain="" data-max-length="fit">
        <Text >commandes.commande.table.articles</Text>
      </group>
      <group data-align="center" data-wrap="no" data-length="120">
        <Text >commandes.commande.table.quantite</Text>
      </group>
      <group data-align="center" data-wrap="no" data-max-length="100">
        <Text data-position="right">commandes.commande.table.prix</Text>
      </group>
      <group data-width="auto" data-space="15"></group>


    </group>
  );
};

export default CartListTitle;
