export interface Sort {
  type?: string;
  order?: 'ASC' | 'DESC';
  field?: string;
  getValue?: Function;
}

export const tableSort = (sort: Sort) => (a: any, b: any) => {
  const { field, getValue, type } = sort;
  if (field) {
    const compare = getCompareFunc(type);
    const coef = (sort as any).order === 'DESC' ? -1 : 1;
    let valueA = a[field];
    let valueB = b[field];
    if (getValue) {
      valueA = getValue(valueA);
      valueB = getValue(valueB);
    }
    return coef * compare(valueA, valueB);
  }
  return 0;
};

const getCompareFunc = (type?: string) => {
  if (type === 'date') {
    return (a: any, b: any) => {
      return new Date(a).getTime() > new Date(b).getTime() ? 1 : -1;
    };
  }

  return (a: any, b: any) => {
    const finalA = typeof a === 'string' ? a?.toLowerCase() : a,
      finalB = typeof b === 'string' ? b?.toLowerCase() : b;

    return finalA > finalB ? 1 : -1;
  };
};

export const isValidJSON = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};
