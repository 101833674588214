import Button from '../../components/shared/button';
import Separator from '../../components/shared/separator';
import useTranslation from '../../utils/hooks/useTranslation';

const ShoppingCartSuccessPopupContent: React.FC<{ resultStatus: string; onClose: () => void }> = ({
  onClose,
  resultStatus,
}) => {
  const { t } = useTranslation();
  const label = t(`panier.resultModal.results.${resultStatus}.label`);
  const message = t(`panier.resultModal.results.${resultStatus}.message`);
  return (
    <group data-direction="row" data-gap="20" data-space="20">
      <group>
        <text data-weight="700">{label}</text>: {message}
      </group>
      <Separator horizontal />
      <group data-width="auto" data-gap="10">
        <Button text={t('panier.resultModal.closeButton.title')} outline onClick={onClose} />
      </group>
    </group>
  );
};

export default ShoppingCartSuccessPopupContent;
