import { useState } from 'react';
import Button from '../../components/shared/button';
import Popup from '../../components/shared/popup';
import { getDocGARight } from '../../project/helpers';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { copyToClipboard } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';

const TabVideos: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { videos } = data;
    const { t } = useTranslation();
    const [video, setVideo] = useState<{ show: boolean; videoId?: string; title?: string, videoType?: string }>({ show: false });

  return (
    <group data-space="5">
      {video.show && (
        <Popup
          windowContProps={{ "data-length": 700 }}
          title={video.title}
          onClose={() => {
            setVideo({ show: false });
          }}
        >
          <group data-contain="" data-ratio="16:9">
            <iframe
              title={video.videoId}
              src={video.videoType === 'Vimeo'
                  ? `https://player.vimeo.com/video/${video.videoId}?dnt=true`
                  : `https://www.youtube.com/embed/${video.videoId}`}
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </group>
        </Popup>
      )}
      <group data-space-vertical="15" direction="row" data-gap="10" data-type="grid" data-grid-size="500" data-align="start">
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t("satc.detail.tabs.videoTutos.first")}</th>
                <th data-sticky="right" style={{width:40}}>{t("satc.detail.tabs.videoTutos.second")}</th>
              </tr>
            </thead>
            <tbody>
              {videos.pfs.map((item: any) => {
                return (
                  <tr key={item.video_id}>
                    <td>
                      <wrap>
                        <text
                          data-wrap="wrap"
                          data-type="link"
                          data-clamp="2"
                          onClick={() => {
                            setVideo({
                              show: true,
                              videoId: item.video_id,
                                title: item.title,
                                videoType: item.video_type
                            });
                          }}
                        >
                          {item.title}
                        </text>
                      </wrap>
                    </td>
                    <td data-sticky="right">
                      <Button
                        extra
                        onClick={() => copyToClipboard(t, (item.video_type === 'YouTube' ? 'https://www.youtube.com/embed/' : 'https://vimeo.com/') + item.video_id)}
                      >
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t("satc.detail.tabs.videoTutos.first")}</th>
                <th><text>{t("satc.detail.tabs.pvCertifs.second")}</text></th>
                <th data-sticky="right" style={{width:40}}>{t("satc.detail.tabs.videoTutos.second")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(videos.docga).map((key: any) => {
                let item = videos.docga[key];
                let tags = getDocGARight(item.tags);

                return (
                  <tr key={key}>
                    <td>
                      <wrap>
                        <a
                          data-type="link"
                          href={item.download}
                          target="_blank"
                          rel="noreferrer"

                        >
                          {item.title}
                        </a>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{tags}</text>
                      </wrap>
                    </td>
                    <td data-sticky="right">
                      <Button
                        extra
                        onClick={() => copyToClipboard(t, item.download ?? ((item.video_type === 'YouTube' ? 'https://www.youtube.com/embed/' : 'https://vimeo.com/') + item.video_id))}
                      >
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </group>
    </group>
  );
};

export default TabVideos;
