import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import useTranslation from '../../utils/hooks/useTranslation';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../project/defines';
import Input from '../../components/shared/inputs/input';
import { Fragment, useMemo, useState } from 'react';
import { getAppUrl } from '../../project/helpers';
import { Text } from '../../components/shared/text';

type TableRecord = Record<string, any>;

const TabAccessories: React.FC<{ data: any }> = ({ data }) => {
  const { accessoires } = data;
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
  const { pagingProps, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const {
    pagination: { current, pageSize },
  } = pagingProps;

  const columns: ColumnType<TableRecord>[] = [
    {
      key: 'accessoire_reference',
      title: t('satc.detail.tabs.accessoires.first'),
      width: 100,
      render: (data) => {
        return (
          <Link
            key={data.accessoire_uuid}
            data-type="link"
            to={getAppUrl(APP_URL.Product, {
              routeParams: {
                id: data.accessoire_uuid || '',
              },
            })}
          >
            {data.accessoire_reference}
          </Link>
        );
      },
    },
    {
      key: 'accessoire_libelle',
      title: t('satc.detail.tabs.accessoires.second'),
      dataIndex: 'accessoire_libelle',
    },

    {
      key: 'produit_libelle',
      title: t('satc.detail.tabs.accessoires.fourth'),
      width: 300,
      render: (data) => {
        return data.produits.map((item: any, index: number) => {
          return (
            <Fragment key={item.produit_uuid}>
              {index !== 0 && <Text accent> {' / '} </Text>}
              <Link
                data-type="link"
                to={getAppUrl(APP_URL.Product, {
                  routeParams: {
                    id: item.produit_uuid || '',
                  },
                })}
              >
                {item.produit_libelle}
              </Link>
            </Fragment>
          );
        });
      },
    },
  ];

  const tableData = useMemo(() => {
    let arr: any[] = [];
    if (!accessoires.pfs.length) {
      return arr;
    }
    arr = accessoires.pfs.filter((item: any) => {
      return (
        item.accessoire_reference.includes(searchParam) ||
        item.accessoire_libelle.toLowerCase().includes(searchParam.toLowerCase()) ||
        !!item.produits.find((pr: any) => {
          return pr.produit_libelle.toLowerCase().includes(searchParam.toLowerCase());
        })
      );
    });

    return arr;
  }, [accessoires, current, pageSize, searchParam]);

  return (
    <group
      data-space="5"      
      data-gap="20"
      direction="row"
    >
      <group data-space="15" data-background="highlight" data-radius="10" data-border="">
        <Input
          dataLength="400"
          type="search"
          icon="search"
          placeholder={t("recherche.switchButton")}
          onChange={(e) => {
            if (searchTimeout) {
              clearTimeout(searchTimeout);
              setSearchTimeout(undefined);
            }
            let timout = setTimeout(() => {
              setSearchParam(e.target.value);
              setSearchTimeout(undefined);
            }, 500);
            setSearchTimeout(timout);
          }}
        />
      </group>
      <group data-direction="column">
        <Table
          ellipsis={true}
          bordered={true}
          columns={columns}
          tableLayout={"auto"}
          showHeader={true}
          dataSource={[...tableData].slice(
            (current! - 1) * pageSize!,
            current! * pageSize!
          )}
          pagination={false}
          setSorter={setSort}
          size={"small" as SizeType}
        />
        <PaginationToolbar {...pagingProps} total={tableData.length} />
      </group>
    </group>
  );
};

export default TabAccessories;
