import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Input, { InputType } from '../../components/shared/inputs/input';
import Loader from '../../components/shared/loader';
import TabStrip from '../../components/shared/tabstrip';
import Tab from '../../components/shared/tabstrip/tab';
import { Text } from '../../components/shared/text';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useUpdateSocieteMutation } from '../../store/auth/authApi';
import { setUserData } from '../../store/auth/authSlice';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTranslation from '../../utils/hooks/useTranslation';
import Collaborateur from '../collaborateur';
import * as yup from 'yup';
import useYupValidationResolver from '../../utils/hooks/useYupValidationResolver';
import Separator from '../../components/shared/separator';
import { getKeyUpHandler } from '../../utils/helpers';
import { RegexpPatterns } from '../../utils/defines';
const validationSchema = yup.object({
  livraison_telephone: yup
    .string()
    .nullable()
    .matches(RegexpPatterns.phone)
    .transform((value) => (value ? value : null)),
});

const Societe: React.FC = () => {
  const { auth, siteData } = useAppSelector((state) => state);
  const [trigger, { isLoading }] = useUpdateSocieteMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, watch, control, formState } = useForm<FieldValues>({
    defaultValues: auth?.societe ?? {},
    resolver,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <section>
      <space data-height="20"></space>
      <wrapper>
        <group data-gap="20" data-align="start">
          <group data-max-length="400" data-length="forcefit" data-gap="20">
            <group data-border="" data-radius="10" data-space="20" data-background="highlight">
              {isLoading && <Loader />}
              <group data-direction="column" data-gap="5">
                <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                  societe.informationsGenerales.libelle
                </Text>
              </group>
              <space data-height="10"></space>
              <Separator horizontal />
              <space data-height="10"></space>
              <group data-direction="column" data-gap="10">
                <Input
                  label="societe.informationsGenerales.raisonSociale.libelle"
                  dataLength="autofit"
                  size="medium"
                  aria-invalid="false"
                  placeholder={t('societe.informationsGenerales.raisonSociale.placeholder')}
                  translateLabel
                  disabled={auth.collaborateur}
                  isGroup
                  name="raison_sociale"
                  control={control}
                />

                <Input
                  label="societe.informationsGenerales.siret.libelle"
                  dataLength="autofit"
                  size="medium"
                  aria-invalid="false"
                  placeholder={t('societe.informationsGenerales.siret.placeholder')}
                  translateLabel
                  disabled
                  isGroup
                  name="siret"
                  control={control}
                />
              </group>
            </group>
            <group data-border="" data-radius="10" data-contain="" data-background="highlight">
              <group>
                <TabStrip
                  onChange={(index) => {
                    setSelectedIndex(index);
                  }}
                  skipSecondaryTabs={false}
                  selectedIndex={selectedIndex}
                >
                  <Tab title={t('societe.adresseFacturation.libelle')} primary>
                    <group data-space="20" data-direction="column" data-gap="10">
                      {!watch().livraison_idem_societe && (
                        <>
                          <Input
                            label="societe.adresseFacturation.pays.libelle"
                            size="medium"
                            aria-invalid="false"
                            translateLabel
                            isGroup
                            name="facturation_pays"
                            dataLength="auto"
                            options={
                              siteData?.countries?.map((item) => ({
                                text: item.nom,
                                value: item.code,
                              })) ?? []
                            }
                            value={watch().livraison_pays}
                            control={control}
                            placeholder={t('societe.adresseLivraison.pays.libelle')}
                            type={InputType.Select}
                            disabled={auth.collaborateur}
                          />
                          <Input
                            label="societe.adresseFacturation.adresse.libelle"
                            dataLength="autofit"
                            size="medium"
                            aria-invalid="false"
                            placeholder={t('societe.adresseFacturation.adresse.placeholder')}
                            translateLabel
                            isGroup
                            name="facturation_adresse"
                            control={control}
                            disabled={auth.collaborateur}
                          />

                          <Input
                            label="societe.adresseFacturation.adresseComplement.libelle"
                            dataLength="autofit"
                            size="medium"
                            aria-invalid="false"
                            placeholder={t('societe.adresseFacturation.adresseComplement.placeholder')}
                            translateLabel
                            isGroup
                            name="facturation_adresse_complement"
                            control={control}
                            disabled={auth.collaborateur}
                          />

                          <Input
                            label="societe.adresseFacturation.codePostal.libelle"
                            dataLength="autofit"
                            size="medium"
                            aria-invalid="false"
                            placeholder={t('societe.adresseFacturation.codePostal.placeholder')}
                            translateLabel
                            isGroup
                            name="facturation_code_postal"
                            control={control}
                            disabled={auth.collaborateur}
                          />

                          <Input
                            label="societe.adresseFacturation.ville.libelle"
                            dataLength="autofit"
                            size="medium"
                            aria-invalid="false"
                            placeholder={t('societe.adresseFacturation.ville.placeholder')}
                            translateLabel
                            isGroup
                            name="facturation_ville"
                            control={control}
                            disabled={auth.collaborateur}
                          />
                          <Input
                            label="societe.adresseFacturation.checkbox.libelle"
                            dataLength="autofit"
                            size="medium"
                            aria-invalid="false"
                            translateLabel
                            isGroup
                            name="livraison_idem_facturation"
                            control={control}
                            type={InputType.Checkbox}
                            classic
                            checked={watch().livraison_idem_facturation}
                          />
                        </>
                      )}
                    </group>
                  </Tab>
                  {!watch().livraison_idem_facturation ? (
                    <Tab title={t('societe.adresseLivraison.libelle')} primary>
                      <group data-direction="column" data-gap="10" data-space="20">
                        <Input
                          label="societe.adresseLivraison.pays.libelle"
                          size="medium"
                          aria-invalid="false"
                          translateLabel
                          isGroup
                          name="livraison_pays"
                          dataLength="auto"
                          options={
                            siteData?.countries?.map((item) => ({
                              text: item.nom,
                              value: item.code,
                            })) ?? []
                          }
                          value={watch().livraison_pays}
                          control={control}
                          placeholder={t('societe.adresseLivraison.pays.libelle')}
                          type={InputType.Select}
                          disabled={auth.collaborateur}
                        />
                        <Separator horizontal />
                        <Input
                          label="societe.adresseLivraison.adresse.libelle"
                          dataLength="autofit"
                          size="medium"
                          aria-invalid="false"
                          placeholder={t('societe.adresseLivraison.adresse.placeholder')}
                          translateLabel
                          isGroup
                          name="livraison_adresse"
                          control={control}
                          disabled={auth.collaborateur}
                        />

                        <Input
                          label="societe.adresseLivraison.adresseComplement.libelle"
                          dataLength="autofit"
                          size="medium"
                          aria-invalid="false"
                          placeholder={t('societe.adresseLivraison.adresseComplement.placeholder')}
                          translateLabel
                          isGroup
                          name="livraison_adresse_complement"
                          control={control}
                          disabled={auth.collaborateur}
                        />

                        <Input
                          label="societe.adresseLivraison.codePostal.libelle"
                          dataLength="autofit"
                          size="medium"
                          aria-invalid="false"
                          placeholder={t('societe.adresseLivraison.codePostal.placeholder')}
                          translateLabel
                          isGroup
                          name="livraison_code_postal"
                          control={control}
                          disabled={auth.collaborateur}
                        />

                        <Input
                          label="societe.adresseLivraison.ville.libelle"
                          dataLength="autofit"
                          size="medium"
                          aria-invalid="false"
                          placeholder={t('societe.adresseLivraison.ville.placeholder')}
                          translateLabel
                          isGroup
                          name="livraison_ville"
                          control={control}
                          disabled={auth.collaborateur}
                        />

                        <Input
                          label="societe.adresseLivraison.telephone.libelle"
                          dataLength="autofit"
                          size="medium"
                          aria-invalid="false"
                          placeholder={t('societe.adresseLivraison.telephone.placeholde')}
                          type="number"
                          translateLabel
                          isGroup
                          name="livraison_telephone"
                          control={control}
                          disabled={auth.collaborateur}
                          error={formState.errors.livraison_telephone}
                          data-appearance="none"
                        />
                      </group>
                    </Tab>
                  ) : (
                    <></>
                  )}
                </TabStrip>
              </group>

              <Separator horizontal data-margin="none" />
              <group data-type="grid" data-gap="10" data-space="20">
                <div
                  className="button large primary"
                  title={t('societe.boutons.validerModifications.texte')}
                  onClick={handleSubmit(async (data: any) => {
                    try {
                      const result = await trigger({
                        societe_uuid: data.uuid,
                        raison_sociale: data.raison_sociale,
                        facturation_adresse: data.facturation_adresse,
                        facturation_code_postal: data.facturation_code_postal,
                        facturation_ville: data.facturation_ville,
                        facturation_adresse_complement: data.facturation_adresse_complement,
                        livraison_adresse: data.livraison_adresse,
                        livraison_adresse_complement: data.livraison_adresse_complement,
                        livraison_code_postal: data.livraison_code_postal,
                        livraison_idem_facturation: data.livraison_idem_facturation,
                        livraison_telephone: data.livraison_telephone,
                        livraison_ville: data.livraison_ville,
                      });
                      dispatch(setUserData((result as any).data));
                      dispatch(
                        addNotification({
                          type: 'success',
                          message: t('global.notifications.societe.updateTitle'),
                          description: t('global.notifications.societe.updateMessage'),
                          duration: 5,
                        })
                      );
                    } catch (error: any) {
                      ApiErrorHandler(error, dispatch, t, 'global.notifications.societe.societeErrorTitle');
                    }
                  })}
                  onKeyUp={getKeyUpHandler()}
                >
                  <Text>societe.boutons.validerModifications.texte</Text>
                </div>
              </group>
            </group>
          </group>
          <group data-border="" data-radius="10" data-space="20" data-background="highlight" data-length="autofit">
            <group data-direction="column" data-gap="5">
              <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                societe.collaborateurs.libelle
              </Text>
              <text data-wrap="wrap" data-max-length="700" data-line="1.5">
                Nota : les administrateurs peuvent autoriser l’accès aux fonctions commande, demande de garantie et
                affichage des prix remisés pour tous les collaborateurs de la société. (Consulter Aide / Créer un accès
                collaborateur)
              </text>
            </group>
            <space data-height="10"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <Collaborateur />
          </group>
        </group>
      </wrapper>
      <space data-height="20"></space>
    </section>
  );
};

export default Societe;
