import { TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';
import { Sort } from './utils';

export enum ListViewType {
  Table = 'table', //eslint-disable-line  @typescript-eslint/no-shadow
  List = 'list',
  Tree = 'tree',
  Chart = 'chart',
  Block = 'block',
  Grid = 'grid',
}

export const ListViewTypeConfig: Record<string, { title?: string; icon?: string }> = {
  [ListViewType.Table]: { title: 'text.table', icon: 'Table_Rows' },
  [ListViewType.List]: { title: 'text.list', icon: 'table_rows' },
  [ListViewType.Block]: { title: 'text.block', icon: 'view_stream' },
  [ListViewType.Grid]: { title: 'text.grid', icon: 'grid_view' },
  [ListViewType.Chart]: { title: 'text.chart', icon: 'Leaderboard' },
};

export const useList = ({
  viewTypes = [ListViewType.Table, ListViewType.List],
  defaultViewType = ListViewType.Table,
  columnCountConfig = false,
}: {
  viewTypes?: ListViewType[];
  columnCountConfig?: boolean;
  defaultViewType?: ListViewType;
}) => {
  const defaultPageSize = 10;
  const [isMount, setIsMount] = useState(false);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({});
  const [sort, setSort] = useState<Sort>({});
  const [viewType, setViewType] = useState<ListViewType | ''>('');

  useEffect(() => {
    setIsMount(true);
    setViewType(defaultViewType || viewTypes[0]);
  }, []);

  useEffect(() => {
    setPagination({
      current: 1,
      pageSize: defaultPageSize,
    });
  }, [defaultPageSize]);

  return {
    isMount,
    pagination: { pageSize: pagination.pageSize, pageNumber: pagination.current },
    resetPagination: () => setPagination((prev) => ({ ...prev, current: 1 })),
    sort,
    setSort: (sorter: any) => {
      const descendOrder = ['descend', 'DESC'].includes(sorter?.order) ? 'DESC' : sorter?.order;
      setSort({
        ...sorter,
        order: ['ascend', 'ASC'].includes(sorter?.order) ? 'ASC' : descendOrder,
      });
    },
    viewType,
    showAllColumns,
    toolbarProps: {
      columnCountConfig,
      viewType,
      viewTypes,
      showAllColumns,
      setShowAllColumns,
      setViewType,
    },
    pagingProps: {
      pagination,
      setPagination,
    },
  };
};
