import Button from '../../components/shared/button';
import { getDocGARight } from '../../project/helpers';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { copyToClipboard } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';

const TabPVCertifs: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { pv_certifs } = data;
  const { t } = useTranslation();

  return (
    <group data-space="5">
      <group data-space-vertical="15" direction="row" data-gap="10" data-type="grid" data-grid-size="500" data-align="start">
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th><text>{t("satc.detail.tabs.pvCertifs.first")}</text></th>
                <th><text>{t("satc.detail.tabs.pvCertifs.second")}</text></th>
                <th data-sticky="right" style={{width:40}}><text>{t("satc.detail.tabs.videoTutos.second")}</text></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(pv_certifs).map((key: any) => {
                const item = pv_certifs[key];
                let tags = getDocGARight(item.tags);
                return (
                  <tr key={item.uuid}>
                    <td>
                      <wrap>
                        <a
                          data-type="link"
                          href={item.download}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <text data-clamp="2">{item.title}</text>
                        </a>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{tags.join(", ")}</text>
                      </wrap>
                    </td>
                    <td data-sticky="right">
                      <Button
                        extra
                        onClick={() => copyToClipboard(t, item.download)}
                      >
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </group>
    </group>
  );
};

export default TabPVCertifs;
