import { lazy, useMemo } from 'react';
import { getBrandFolder, getUniversFromUrl } from '../project/helpers';
import { useAppSelector } from '../store/hooks';

export const BrandComponent = {
  Layout: 'layout',
  Home: 'home',
};

export const useBrandComponent = (component: string) => {
  const domain = useAppSelector((s) => s.siteData.universInfo?.univers?.domain);
  const univers = getUniversFromUrl(domain ?? '');
  const Component = useMemo(
    () =>
      lazy(() => {
        return import(`./${getBrandFolder(univers)}/${component}`);
      }),
    [domain, component] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return Component;
};
