import { AppDispatch } from '.';
import { addNotification, logout } from '../store/componentsSlice';

export const ApiErrorHandler = (error: any, dispatch: AppDispatch, t: (k: string) => string, title?: string) => {
  const showNotification = (message: string, description: string) => {
    dispatch(
      addNotification({
        type: 'error',
        message: t(message),
        description: t(description),
        duration: 5,
      })
    );
  };
  const status = error?.status || 500;

  if (status === 401) {
    dispatch(logout());
  } else {
    let errorMsg = error?.data?.error || 'global.notifications.erreurInterne.title';

    if (['token_not_provided', 'token_expired'].indexOf(errorMsg) > -1) {
      dispatch(logout());
    }
    if (error?.data) {
      Object.keys(error.data).forEach((key) => {
        if (Array.isArray(error.data[key])) {
          error.data[key].forEach((err: any) => {
            showNotification(key, err);
          });
        }
        if (key === 'errors') {
          Object.keys(error.data[key]).forEach((k) => {
            error.data[key][k].forEach((err: any) => {
              showNotification(title ?? k, err);
            });
          });
        }
        if (key === 'error') {
          showNotification(title ?? '', errorMsg);
        }
      });
    }
  }
};
