import { useGetCGVContentQuery } from '../store/auth/authApi';
import Loader from './shared/loader';

const CGVPopupContent: React.FC<{ reseau: string }> = ({ reseau }) => {
  const { data: cgvContentData, isLoading, isFetching } = useGetCGVContentQuery({ reseau_uuid: reseau });

  return isLoading || isFetching ? (
    <div data-length="700" data-height="60">
      <Loader />
    </div>
  ) : (
    <view data-scroll="" data-max-length="800" data-space="30" dangerouslySetInnerHTML={{ __html: cgvContentData?.contenu! }} />
  );
};

export default CGVPopupContent;
