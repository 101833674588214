//const baseKey: string = ((window as any).config as any).REACT_APP_API_URL;

const baseKey: string | undefined = process.env.REACT_APP_API_URL;

export const getSessionStorageItem = (key: string) => {
  return sessionStorage.getItem(`${baseKey}-${key}`);
};

export const setSessionStorageItem = (key: string, value: any) => {
  return sessionStorage.setItem(`${baseKey}-${key}`, value);
};

export const removeSessionStorageItem = (key: string) => {
  return sessionStorage.removeItem(`${baseKey}-${key}`);
};
