import React, { useMemo } from 'react';
import { Location, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { BrandComponent, useBrandComponent } from '../brands/useBrandComponentHook';
import Layout from '../layout';
import { APP_URL, DEFAULT_LANGUAGE, DEFAULT_COUNTRY_LANGUAGE_SEPARATOR, DEFAULT_COUNTRY } from '../project/defines';
import { RootState } from '../store';
import { AuthModel } from '../store/auth/authModels';
import { useAppSelector } from '../store/hooks';
import { hasWarrantyAccess } from '../utils/helpers';
import UserClients from './account/clients';
import Profile from './account/profile';
import Societe from './account/societe';
import ArticleDetails from './article';
import Family from './family';
import OrderHistory from './orderHistory';
import OrderDetail from './orderHistory/orderDetails';
import Product from './product';
import QuoteHistory from './quoteHistory';
import { SearchResults } from './search/search';
import ShoppingCart from './shoppingCart';
import Template from './template';
import WarrantyHistory from './warrantyHistory';
import WarrantyRequest from './warrantyRequest';
import { Univers } from '../store/univers/universModels';

const AppRoutes: React.FC = () => {
  const language = useAppSelector((state) => state.language.currentLanguage) || DEFAULT_LANGUAGE;
  const country = useAppSelector((state) => state.siteData.currentCountry?.code) || DEFAULT_COUNTRY;
  const location = useLocation();
  const state = useAppSelector((state) => state);
  const authUser = useAppSelector((state) => state.auth);
  const languages = useAppSelector((state) => state.language.languages);
  const univers = useAppSelector((state) => state.siteData.universInfo?.univers);

  const Home = useBrandComponent(BrandComponent.Home);

  const startsWithLangCode = useMemo(() => {
    return (languages ?? [{ lang: 'fr' }])?.some((lang) => location.pathname.startsWith(`/${lang.lang}`) || location.pathname.startsWith(`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${lang.lang}`));
  }, [languages, country, location.pathname]);

  return (
    <Routes>
      <Route path={APP_URL.Base} element={<Layout hasBrandLayout={false} />}>
        <Route path={APP_URL.Template} element={<Template />} />
      </Route>
      <Route path={APP_URL.Base} element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path={APP_URL.Login} element={<LoginComponentRedirect />} />
        {getAppRoutes(location, authUser, language, country, state, univers)}
      </Route>
      {getAppRoutes(location, authUser, language, country, state, univers, false)}
      <Route path="/" element={<Navigate to={`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`} replace={true} />} />
      <Route
        path="*"
        element={
          <Navigate
            to={startsWithLangCode ? `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}` : `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}${location.pathname + location.search}`}
            replace={true}
          />
        }
      />
    </Routes>
  );
};
export default AppRoutes;

const LoginComponentRedirect = () => {
  const { lang } = useParams();
  return <Navigate to={`/${lang}`} replace={true} />;
};

const getAppRoutes = (
  location: Location,
  authUser: AuthModel,
  language: string,
  country: string,
  state: RootState,
  univers?: Univers,
  isInBaseRoute: boolean = true
) => {
  const isLoggedIn = !!authUser.uuid;

  if (!isLoggedIn && univers?.restricted) return null;

  const loggedInRoutes = [
    { path: APP_URL.ShoppingCart, component: <ShoppingCart /> },
    { path: APP_URL.ShoppingCartSuccess, component: <ShoppingCart /> },
    { path: APP_URL.WarrantyHistory, component: <WarrantyHistory /> },
    { path: APP_URL.OrderHistory, component: <OrderHistory /> },
    { path: APP_URL.OrderDetails, component: <OrderDetail /> },
    { path: APP_URL.Profile, component: <Profile /> },
    { path: APP_URL.Societe, component: <Societe /> },
    { path: APP_URL.Clients, component: <UserClients /> },
    { path: APP_URL.QuoteHistory, component: <QuoteHistory /> },
  ];

    hasWarrantyAccess(state) && 
    loggedInRoutes.push({ path: APP_URL.WarrantyReplacement, component: <WarrantyRequest /> });

  const mainRoutes = [
    { path: APP_URL.Search, component: <SearchResults /> },
    { path: APP_URL.Family, component: <Family /> },
    { path: APP_URL.FamilyDetail, component: <Family /> },
    { path: APP_URL.Product, component: <Product /> },
    { path: APP_URL.ProductSchema, component: <Product /> },
    { path: APP_URL.ProductReseau, component: <Product /> },
    { path: APP_URL.Article, component: <ArticleDetails /> },
    { path: APP_URL.ArticleReseau, component: <ArticleDetails /> },
  ];

  return (
    <>
      {isLoggedIn && (
        <>
          {loggedInRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                isInBaseRoute ? (
                  route.component
                ) : (
                  <Navigate to={`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}${location.pathname + location.search}`} replace={true} />
                )
              }
            />
          ))}
        </>
      )}
      {mainRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            isInBaseRoute ? (
              route.component
            ) : (
              <Navigate to={`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}${location.pathname + location.search}`} replace={true} />
            )
          }
        />
      ))}
    </>
  );
};
