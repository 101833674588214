const Switchable = () => {
  return (
    <view data-space="30" data-direction="row">
      <group horizontal="" data-length="240">
        <switchable horizontal="" className="hide">
          <switch data-effect="material">
            <icon>apps</icon>
            <wrap>
              <text>Section Name</text>
            </wrap>
          </switch>
          <view>
            <list>
              <wrap>
                <demospace tip="">
                  <icon large="" data-opacity="light">
                    info
                  </icon>
                  <space data-height="20"></space>
                  <text data-light="" data-wrap="wrap">
                    There is no any additional manipulations required for DOM to add or remove layout sections.
                  </text>
                  <space data-height="20"></space>
                  <div className="button primary sct_delete" data-effect="material">
                    <text>Remove Section</text>
                  </div>
                </demospace>
              </wrap>
            </list>
          </view>
        </switchable>
      </group>
    </view>
  );
};

export default Switchable;
