import { Article } from '../../store/article/articleModels';
import EmptyPicture from '../emptyPicture';
import Separator from '../shared/separator';
import ArticleCommandPanel from './articleCommandPanel';
import ArticleInfo from './articleInfo';

const ArticleWidget: React.FC<{ article: Article }> = ({ article }) => {
  return (
    <group data-direction="column" data-border="" data-radius="10" data-contain="">
      <group data-space="10" data-shrink="no" data-wrap="no" data-align="start">
        <picture data-ratio="1:1" data-radius="5" data-background="highlight" data-length="100">
          {article.image ? <img src={article.image} alt="" /> : <EmptyPicture />}
        </picture>
        <group data-direction="column" data-gap="5" data-space="10" data-text-size="12">
          <ArticleInfo article={article} isWidget />
        </group>
      </group>

      <group data-position="bottom"></group>
      <Separator horizontal data-margin="none" />
      <group data-align="center">
        <ArticleCommandPanel article={article} />
      </group>
    </group>
  );
};

export default ArticleWidget;
