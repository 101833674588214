import { configureStore } from '@reduxjs/toolkit';

import components from './componentsSlice';
import analytics from './analyticsSlice';
import shoppingCart from './shoppingCart/shoppingCartSlice';
import { familyApi } from './family/familyApi';
import siteData from './sideData/siteDataSlice';
import language from './language/languageSlice';
import auth from './auth/authSlice';
import { languageApi } from './language/laguageApi';
import { searchApi } from './search/searchApi';
import { universApi } from './univers/universApi';
import { countryApi } from './country/countryApi';
import { articleApi } from './article/articleApi';
import { documentApi } from './documents/documentApi';
import { savApi } from './sav/savApi';
import { productApi } from './product/productApi';
import { zoneApi } from './product/zonesApi';
import { breadcrumbApi } from './breadcrumb/breadcrumbApi';
import { authApi } from './auth/authApi';
import { shoppingCartApi } from './shoppingCart/shoppingCartApi';
import { satcApi } from './satc/satcApi';
import { productHistoryApi } from './productHistory/productHistoryApi';
import { devisApi } from './devis/devisApi';
import { ordersApi } from './orders/ordersApi';
import { commandApi } from './command/commandApi';
import { paymentApi } from './payment/paymentApi';
import { warrantyHistoryApi } from './warrantyHistory/warrantyHistoryApi';
import { warrantyRequestApi } from './warrantyRequest/warrantyRequestApi';

const store = configureStore({
  reducer: {
    components,
    analytics,
    shoppingCart,
    language,
    siteData,
    auth,
    [familyApi.reducerPath]: familyApi.reducer,
    [languageApi.reducerPath]: languageApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [universApi.reducerPath]: universApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [articleApi.reducerPath]: articleApi.reducer,
    [shoppingCartApi.reducerPath]: shoppingCartApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [productHistoryApi.reducerPath]: productHistoryApi.reducer,
    [zoneApi.reducerPath]: zoneApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [savApi.reducerPath]: savApi.reducer,
    [breadcrumbApi.reducerPath]: breadcrumbApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [satcApi.reducerPath]: satcApi.reducer,
    [devisApi.reducerPath]: devisApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [commandApi.reducerPath]: commandApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [warrantyHistoryApi.reducerPath]: warrantyHistoryApi.reducer,
    [warrantyRequestApi.reducerPath]: warrantyRequestApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(familyApi.middleware)
      .concat(languageApi.middleware)
      .concat(searchApi.middleware)
      .concat(universApi.middleware)
      .concat(countryApi.middleware)
      .concat(productApi.middleware)
      .concat(productHistoryApi.middleware)
      .concat(zoneApi.middleware)
      .concat(articleApi.middleware)
      .concat(shoppingCartApi.middleware)
      .concat(ordersApi.middleware)
      .concat(documentApi.middleware)
      .concat(savApi.middleware)
      .concat(breadcrumbApi.middleware)
      .concat(authApi.middleware)
      .concat(satcApi.middleware)
      .concat(devisApi.middleware)
      .concat(paymentApi.middleware)
      .concat(commandApi.middleware)
      .concat(warrantyHistoryApi.middleware)
      .concat(warrantyRequestApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
