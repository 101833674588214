import React, { CSSProperties, MouseEvent, PointerEvent, useState } from "react";

interface ImageMagnifierProps {
  src: string;
  width?: string;
  height?: string;
  magnifierHeight?: number;
  magnifierWidth?: number;
  zoomLevel?: number;
  floating?: boolean;
  cover?: boolean;
}

const ImageMagnifier: React.FC<ImageMagnifierProps> = ({
  src,
  width,
  height,
  magnifierHeight = 100,
  magnifierWidth = 100,
  zoomLevel = 1.5,
  floating = false,
  cover = false,

}) => {
  const [[x, y], setXY] = useState<[number, number]>([0, 0]);
  const [{ top, left, width: imgWidth, height: imgHeight }, setClientRect] = useState<DOMRect>({} as DOMRect);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const zoomLevelCursor = floating ? zoomLevel : 1;
  const magnifierStyle: CSSProperties = {
    position: "absolute",
    pointerEvents: "none",
    opacity: "1", // reduce opacity so you can verify position
   // border: "1px solid lightgray",
    backgroundImage: `url('${src}')`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "white"
  };

  const updatePosition = (e: PointerEvent | MouseEvent, currentTop?: number, currentLeft?: number) => {
    // update cursor position
    // calculate cursor position on the image
    let x = e.clientX - (currentLeft ?? left);
    let y = e.clientY - (currentTop ?? top);
    if (!cover && zoomLevelCursor <= 1) {
      x = Math.max(Math.min(x, imgWidth - Math.ceil(magnifierWidth / 2)), Math.ceil(magnifierWidth / 2));
      y = Math.max(Math.min(y, imgHeight - Math.ceil(magnifierHeight / 2)), Math.ceil(magnifierHeight / 2));
    }
    setXY([x, y]);
  };

  return (
    <div
      className="magnifier"
      style={{
        position: "relative",
        height: height,
        width: width,
      }}
    >
      <div
        style={{
          //   display: showMagnifier ? "" : "none",
          position: "absolute",
          borderRadius:"5px",
          // set size of magnifier
          height: "100%",
          width: "100%",
          // move element center to cursor pos
          opacity: showMagnifier && !floating ? 0.6 : 0, // reduce opacity so you can verify position
          ///  border: "1px solid lightgray",
          backgroundColor: "gray",
          touchAction: "none",
          cursor: floating || cover ? "none" : "crosshair",
        }}
        onPointerEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const rect = elem.getBoundingClientRect();
          setClientRect(rect);
          updatePosition(e, rect.top, rect.left);
          setShowMagnifier(true);
        }}
        onPointerMove={(e) => {
          if (showMagnifier) {
            updatePosition(e);
          }
        }}
        onMouseMove={(e) => {
          if (showMagnifier) {
            updatePosition(e);
          }
        }}
        onPointerLeave={() => {
          setShowMagnifier(false);
        }}
      ></div>
      <img src={src} style={{ height: height, width: width }} alt="" />
      {showMagnifier && (
        <>
          {!cover && (
            <div
              style={{
                ...magnifierStyle,
                height: `${magnifierHeight}px`,
                width: `${magnifierWidth}px`,
                // move element center to cursor pos
                top: `${y - 3 - magnifierHeight / 2}px`,
                left: `${x - 1 - magnifierWidth / 2}px`,
                //calculate zoomed image size
                backgroundSize: `${imgWidth * zoomLevelCursor}px ${
                  imgHeight * zoomLevelCursor
                }px`,
                //calculate position of zoomed image.
                backgroundPositionX: `${
                  -x * zoomLevelCursor + magnifierWidth / 2
                }px`,
                backgroundPositionY: `${
                  -y * zoomLevelCursor + magnifierHeight / 2
                }px`,
              }}
            ></div>
          )}
          {!floating && (
            <div
              style={{
                ...magnifierStyle,
                zIndex: 99,
                top: `${0}px`,
                ...(cover
                  ? {
                      left: `${0}px`,
                      width: `${imgWidth}px`,
                      height: `${imgHeight}px`,
                      backgroundPositionX: `${Math.max(
                        Math.min(-x * zoomLevel + imgWidth / 2, 0),
                        -imgWidth * (zoomLevel - 1)
                      )}px`,
                      backgroundPositionY: `${Math.max(
                        Math.min(-y * zoomLevel + imgHeight / 2, 0),
                        -imgHeight * (zoomLevel - 1)
                      )}px`,
                    }
                  : {
                      left: `${imgWidth}px`,
                      width: `${magnifierWidth * zoomLevel}px`,
                      height: `${Math.min(
                        magnifierHeight * zoomLevel,
                        imgHeight
                      )}px`,
                      backgroundPositionX: `${
                        (-x + magnifierWidth / 2) * zoomLevel
                      }px`,
                      backgroundPositionY: `${
                        (-y + magnifierHeight / 2) * zoomLevel
                      }px`,
                    }),
                //calculate zoomed image size
                backgroundSize: `${imgWidth * zoomLevel}px ${
                  imgHeight * zoomLevel
                }px`,
                //calculate position of zoomed image.
              }}
            ></div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageMagnifier;
