import { useNavigate } from 'react-router-dom';
import Button from './shared/button';

const BackButton: React.FC<{ size?: string; }> = ({ size }) => {
  const navigate = useNavigate();
  return <Button
    data-length="forcefit"
    accent
    icon="arrow_back"
    text="article.retour"
    textFirst={false}
    onClick={() => navigate(-1)}
  ></Button>;
};

export default BackButton;