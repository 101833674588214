import { ReactNode } from 'react';
import { SvgPlus } from '../components/svg';
import { UNIVERS } from '../project/defines';
import { getUniversFromUrl } from '../project/helpers';
import { useAppSelector } from '../store/hooks';

export const BrandIcons = {
  ExpandIcon: 'expandIcon',
};
const BrandIcon: React.FC<{ children: string }> = ({ children }) => {
  const domain = useAppSelector((s) => s.siteData.universInfo?.univers?.domain);
  const univers = getUniversFromUrl(domain ?? '');
  let icon: ReactNode = '';
  if (children === BrandIcons.ExpandIcon) {
    icon = univers === UNIVERS.AtlanticFR ? 'navigate_next' : <SvgPlus />;
  } else {
    icon = children;
  }

  return <>{icon}</>;
};

export default BrandIcon;
