import Separator from '../../components/shared/separator';
import CartFooter from './cartFooter';
import CartHeader from './cartHeader';
import CartItem from './cartItem';
import CartListTitle from './cartListTitle';

const ReseauCart: React.FC<{ isPending?: boolean; cartByReseau: any; reseauUuid: string }> = ({
  isPending = false,
  cartByReseau,
  reseauUuid,
}) => {
  return (
    <group data-border="" data-radius="10" data-contain="">
      <CartHeader
        enAttente={cartByReseau.lignes_panier[0].en_attente}
        reseauUuid={reseauUuid}
        title={cartByReseau.label}
        count={Object.keys(cartByReseau.lignes_panier).length}
      />
      <div className="list_view_container cart" data-view="grid">
        <group className="list_view_scroller">
          <div className="list_view">
            <group className="title" data-adaptive="desktop">
              <CartListTitle />
            </group>
            {cartByReseau.lignes_panier.map((item: any) => (
              <CartItem isPending={isPending} key={item.uuid} cartData={item} />
            ))}
            <Separator horizontal data-margin="none" />
            <CartFooter isPending={isPending} cartData={cartByReseau} />
          </div>
        </group>
      </div>
    </group>
  );
};

export default ReseauCart;
