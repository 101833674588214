import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../../../../components/shared/inputs/datePicker';
import Input, { InputType } from '../../../../components/shared/inputs/input';
import Select from '../../../../components/shared/inputs/select';
import Loader from '../../../../components/shared/loader';
import Popup from '../../../../components/shared/popup';
import { Text } from '../../../../components/shared/text';
import Sav from '../../../../components/warrantyRequest/sav';
import { DATE_FORMAT_ARRAY, WarrantyStage2FormFields } from '../../../../project/defines';
import { useAppSelector } from '../../../../store/hooks';
import { savApi, useGetDiagnosticsByProductQuery } from '../../../../store/sav/savApi';
import { currentCountryUuid } from '../../../../store/sideData/siteDataSlice';
import { WarrantyRequest } from '../../../../store/warrantyRequest/warrantyRequestModels';
import { getKeyUpHandler } from '../../../../utils/helpers';
import useTranslation from '../../../../utils/hooks/useTranslation';

const FieldsWarrantyProduct: React.FC<{ request: WarrantyRequest }> = ({ request }) => {
  const { t } = useTranslation();
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const {
    data: productDefects,
    isLoading: isDefectLoading,
    isFetching: isDefectFetching,
  } = savApi.endpoints.getProductDefects.useQueryState(
    { lang, pays_uuid, famille: null, produit: request?.produit_uuid },
    { skip: !request?.produit_uuid }
  );
  const defautOptions = useMemo(() => {
    const result = [{ value: '', text: t('garantie.demande.etapes.2.form.optionCommande') }];
    productDefects?.defauts?.forEach((d) => result.push({ text: d.libelle, value: d.uuid }));
    return result;
  }, [productDefects, t]);

  const { control, watch, setValue } = useFormContext();
  const deffect_uuid = watch(WarrantyStage2FormFields.defaut_uuid);

  useEffect(() => {
    const currentDeffect = productDefects?.defauts?.find((d) => d.uuid === deffect_uuid);
    if (currentDeffect && !currentDeffect.produit_remplacable) {
      setValue(WarrantyStage2FormFields.renvoie_produit, false);
      setValue(WarrantyStage2FormFields.renvoie_piece, true);
    }
  }, [deffect_uuid, productDefects, setValue]);

  const {
    data: diagnostics,
    isLoading: isDiagnosticsLoading,
    isFetching: isDiagnosticsFetching,
  } = useGetDiagnosticsByProductQuery(
    { lang, pays_uuid, famille: null, produit: request?.produit_uuid, defaut: deffect_uuid },
    { skip: !request?.produit_uuid || !deffect_uuid }
  );

  return isDefectLoading || isDefectFetching ? (
    <Loader />
  ) : (
    <>
      {openPopup && diagnostics && (
        <Popup
          title={`${t('sav.titre')}`}
          onClose={() => {
            setOpenPopup(false);
          }}
        >
          <Sav
            diagnostics={diagnostics}
            defects={productDefects?.defauts?.find((d) => d.uuid === deffect_uuid)}
            comments={productDefects?.commentaires}
          />
        </Popup>
      )}
      <DatePicker
        label="garantie.demande.etapes.2.form.miseEnService"
        dataLength="autofit"
        size="large"
        allowClear
        format={DATE_FORMAT_ARRAY}
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.miseEnService')}
        translateLabel
        isGroup
        name={WarrantyStage2FormFields.date_mise_en_service}
        control={control}
      />
      <Select
        label="garantie.demande.etapes.2.form.defaut"
        dataLength="autofit"
        size="large"
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.defaut')}
        translateLabel
        options={defautOptions}
        isGroup
        name={WarrantyStage2FormFields.defaut_uuid}
        control={control}
      />
      {deffect_uuid && (diagnostics?.length as number) > 0 && !isDiagnosticsFetching && !isDiagnosticsLoading && (
          <a /*eslint-disable-line*/
          className="button medium"
          link=""
          onClick={() => {
            setOpenPopup(true);
            }}
            onKeyUp={getKeyUpHandler()}
        >
          <Text>garantie.demande.etapes.2.diagnostic.link</Text>
        </a> 
      )}
      <Input
        label="garantie.demande.etapes.2.form.numSerie"
        dataLength="autofit"
        size="large"
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.numSerie')}
        translateLabel
        isGroup
        name={WarrantyStage2FormFields.no_serie}
        control={control}
      />
      <Input
        label="garantie.demande.etapes.2.form.commentaire"
        dataLength="autofit"
        size="large"
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.commentaire')}
        translateLabel
        isGroup
        name={WarrantyStage2FormFields.commentaire}
        control={control}
        type={InputType.TextArea}
        data-appearance="none"
      />
    </>
  );
};

export default FieldsWarrantyProduct;
