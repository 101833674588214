import { ReactElement } from 'react';
import Radio, { RadioType } from '../inputs/radio';
import OptionBar from '../optionBar';
import { ListViewType, ListViewTypeConfig } from './useListHook';

export const TopToolbar: React.FC<{
  toolbarContent?: ReactElement;
  showAllColumns?: boolean;
  setShowAllColumns?: any;
  viewType: ListViewType | '';
  viewTypes?: ListViewType[];
  setViewType: any;
}> = ({ toolbarContent, viewType, viewTypes, setViewType }) => (
  <toolbar>
    {toolbarContent}
    {!viewTypes || viewTypes.length <= 1 ? (
      <></>
    ) : (
      <OptionBar autosize dynamic>
        {viewTypes?.map((t) => (
          <Radio
            value={viewType}
            key={t}
            radioType={RadioType.Button}
            onClick={() => {
              setViewType(t);
            }}
            radioValue={t}
            icon={ListViewTypeConfig[t].icon}
            label={ListViewTypeConfig[t].title}
          />
        ))}
      </OptionBar>
    )}
  </toolbar>
);
