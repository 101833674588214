import { Upload } from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../../components/shared/button';
import Checkbox from '../../../../components/shared/inputs/checkbox';
import Loader from '../../../../components/shared/loader';
import NavigationList from '../../../../components/shared/navigation/navigationList';
import Separator from '../../../../components/shared/separator';
import { Text } from '../../../../components/shared/text';
import {
  WarrantyItemTypes,
  WarrantyStage2FormFields,
  WarrantyStage2FormFieldsByType,
  WarrantyType,
} from '../../../../project/defines';
import { ApiErrorHandler } from '../../../../store/apiErrorHandler';
import { addNotification } from '../../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useGetProductOrdersQuery } from '../../../../store/orders/ordersApi';
import { useGetProductDefectsQuery } from '../../../../store/sav/savApi';
import { currentCountryUuid } from '../../../../store/sideData/siteDataSlice';
import { useCreateOrUpdateWarrantyRequestMutation } from '../../../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest } from '../../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../../utils/hooks/useTranslation';
import useYupValidationResolver from '../../../../utils/hooks/useYupValidationResolver';
import FieldsWarrantyArticle from './fieldsWarrantyArticle';
import FieldsWarrantyProduct from './fieldsWarrantyProduct';

const validationSchemaProduct = yup.object({
  date_mise_en_service: yup.string().required('input.validations.required'),
  defaut_uuid: yup.string().required('input.validations.required'),
  no_serie: yup.string().required('input.validations.required'),
});

const validationSchemaArticle = yup.object({
  dossier: yup.string().required('input.validations.required'),
  commande: yup.string().when('dossier', {
    is: (v?: string) => !v,
    then: (schema) => schema.required('input.validations.required'),
    otherwise: (schema) => schema.nullable(),
  }),
});

const WarrantyForm: React.FC<{
  request: WarrantyRequest;
  setGetFormValues: Dispatch<SetStateAction<() => FieldValues>>;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  setRenvoiePiece: Dispatch<SetStateAction<boolean>>;
  setRenvoieProduit: Dispatch<SetStateAction<boolean>>;
}> = ({ request, setGetFormValues, setIsFormValid, setRenvoiePiece, setRenvoieProduit }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedWarrantyItemType, setSelectedWarrantyItemType] = useState(
    request?.type_garantie ?? WarrantyItemTypes.Product
  );
  const resolver = useYupValidationResolver(
    selectedWarrantyItemType === WarrantyItemTypes.Product ? validationSchemaProduct : validationSchemaArticle
  );

  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const lang = useAppSelector((state) => state.language.currentLanguage);

  useGetProductOrdersQuery(request.produit_uuid!, { skip: !request?.produit_uuid });
  const { data: deffects } = useGetProductDefectsQuery(
    { lang, pays_uuid, famille: null, produit: request?.produit_uuid },
    { skip: !request?.produit_uuid }
  );

  const [createOrUpdateDemande, { isLoading: isFileUploading }] = useCreateOrUpdateWarrantyRequestMutation();

  const onFileUpload = async (file: File) => {
    try {
      await createOrUpdateDemande({
        uuid: request.uuid,
        univers_uuid: univers?.uuid ?? '',
        pays_uuid: pays_uuid,
        file: file,
      }).unwrap();
      dispatch(
        addNotification({
          type: 'success',
          message: t(`global.notifications.demande.updateTitle`),
          description: t(`global.notifications.demande.updateMessage`),
          duration: 5,
        })
      );
    } catch (error: any) {
      ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
    }
  };

  let defaultValues = useMemo(() => {
    const result: FieldValues = {};
    Object.values(WarrantyStage2FormFields).forEach(
      (f) => (result[f] = request[f as keyof WarrantyRequest] === null ? '' : request[f as keyof WarrantyRequest])
    );
    result[WarrantyStage2FormFields.renvoie_produit] =
      request.type_renvoie === WarrantyStage2FormFields.renvoie_produit;
    result[WarrantyStage2FormFields.renvoie_piece] =
      request.type_renvoie === WarrantyStage2FormFields.renvoie_piece || !request.type_renvoie;
    return result;
  }, [request]);

  const formMethods = useForm<FieldValues>({
    resolver,
    defaultValues,
    reValidateMode: 'onBlur',
  });

  const {
    control,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { isValid },
  } = formMethods;

  const renvoie_produit = watch(WarrantyStage2FormFields.renvoie_produit);
  const renvoie_piece = watch(WarrantyStage2FormFields.renvoie_piece);
  useEffect(() => {
    renvoie_produit && setValue(WarrantyStage2FormFields.renvoie_piece, false);
    setRenvoieProduit(renvoie_produit);
  }, [renvoie_produit, setValue, setRenvoieProduit]);

  useEffect(() => {
    renvoie_piece && setValue(WarrantyStage2FormFields.renvoie_produit, false);
    setRenvoiePiece(renvoie_piece);
  }, [renvoie_piece, setValue, setRenvoiePiece]);

  useEffect(() => {
    setGetFormValues(() => () => {
      const formValues = getValues();
      const values: FieldValues = {};
      Object.keys(formValues)
        .filter((k) => WarrantyStage2FormFieldsByType[selectedWarrantyItemType].includes(k))
        .forEach((k) => (values[k] = formValues[k]));
      values.type_garantie = selectedWarrantyItemType;
      values.type_renvoie = renvoie_produit
        ? WarrantyStage2FormFields.renvoie_produit
        : WarrantyStage2FormFields.renvoie_piece;
      return values;
    });
  }, [getValues, setGetFormValues, selectedWarrantyItemType, renvoie_produit]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  useEffect(() => {
    trigger();
  }, [selectedWarrantyItemType, trigger]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      trigger(name);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const defect_uuid = watch('defaut_uuid');

  const currentDeffect = useMemo(() => deffects?.defauts?.find((d) => d.uuid === defect_uuid), [defect_uuid, deffects]);

  return (
    <view data-border="none" data-scroll="" data-space="5">
      <group data-space-vertical="20">
        <Text dataWeight="700" accent dataTextSize="medium" dataWrap="wrap">
          garantie.demande.etapes.2.selectiontype
        </Text>
      </group>
      <group>
        <NavigationList
          className="classic invert autofit"
          navStrip
          data={Object.values(WarrantyItemTypes).map((type) => ({
            key: type,
            innerContent: (
              <Text data-wrap="wrap">
                {t(`garantie.demande.etapes.2.typeGarantie.${type}`)}
                {type === WarrantyItemTypes.Article && (
                  <>{t(`garantie.demande.etapes.2.typeGarantie.garantie_piece_2`)}</>
                )}
              </Text>
            ),
            onClick: () => setSelectedWarrantyItemType(type),
          }))}
          //liProps={{ 'data-length': '200' }}
          selected={(t: any) => t.key === selectedWarrantyItemType}
        />
      </group>
      <FormProvider {...(formMethods as any)}>
        <group
          data-direction="column"
          data-border=""
          data-radius-top="0"
          data-radius="10"
          data-space="20"
          data-background="highlight"
        >
          <group data-direction="column" data-gap="10">
            {selectedWarrantyItemType === WarrantyItemTypes.Product ? (
              <FieldsWarrantyProduct request={request} />
            ) : (
              <FieldsWarrantyArticle request={request} />
            )}
          </group>
          <space data-height="10"></space>
          <Separator horizontal />
          <space data-height="10"></space>
          <group data-gap="10">
            {isFileUploading ? (
              <Loader />
            ) : (
              <Upload beforeUpload={onFileUpload} showUploadList={false}>
                <Button
                  data-auto-height=""
                  large
                  wide
                  primary
                  title={t('garantie.demande.etapes.2.form.filebtn')}
                  text="garantie.demande.etapes.2.form.filebtn"
                  icon="upload"
                  textFirst={false}
                />
              </Upload>
            )}
          </group>
          <space data-height="10"></space>
          <Separator horizontal />
          <space data-height="10"></space>
          {request.typedemande === WarrantyType.Retour && (
            <group data-gap="10">
              <Checkbox
                classic
                label="garantie.demande.etapes.2.form.renvoie_piece"
                dataLength="autofit"
                size="large"
                aria-invalid="false"
                translateLabel
                isGroup
                name={WarrantyStage2FormFields.renvoie_piece}
                control={control}
              />
              {currentDeffect?.produit_remplacable && (
                <Checkbox
                  classic
                  label="garantie.demande.etapes.2.form.renvoie_produit"
                  dataLength="autofit"
                  size="large"
                  aria-invalid="false"
                  translateLabel
                  isGroup
                  //disabled
                  name={WarrantyStage2FormFields.renvoie_produit}
                  control={control}
                />
              )}
            </group>
          )}
        </group>
      </FormProvider>
    </view>
  );
};

export default WarrantyForm;
