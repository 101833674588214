// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const commandApi = createApi({
  reducerPath: 'commandApi',
  baseQuery: baseQuery('commandes'),
  endpoints: (builder) => {
    return {
      storeCommand: builder.mutation<any, any>({
        query: ({ data }) => {
          return {
            url: '',
            method: 'post',
            body: data,
          };
        },
      }),
    };
  },
});

export const { useStoreCommandMutation } = commandApi;
