// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import {
  AddArticleToCartModel,
  ArticleAddToShoppingCartModel,
  CartArticleSearchRequestModel,
  CartItemRequestModel,
  CartRequestModel,
} from './shoppingCartModels';

export const shoppingCartApi = createApi({
  reducerPath: 'shoppingCartApi',
  baseQuery: baseQuery('paniers'),
  tagTypes: ['CartList'],
  endpoints: (builder) => {
    return {
      addToCart: builder.mutation<any, ArticleAddToShoppingCartModel>({
        query: (model) => {
          return {
            url: '',
            method: 'post',
            body: model,
          };
        },
        invalidatesTags: ['CartList'],
      }),
      updateCartItem: builder.mutation<any, ArticleAddToShoppingCartModel>({
        query: ({ cart_item_uuid, ...model }) => {
          return {
            url: `${cart_item_uuid}`,
            method: 'put',
            body: model,
          };
        },
        invalidatesTags: ['CartList'],
      }),
      deleteCartItem: builder.mutation<any, CartItemRequestModel>({
        query: ({ uuid, univers_uuid }) => {
          return {
            url: `${uuid}`,
            method: 'delete',
            params: { univers_uuid },
          };
        },
        invalidatesTags: ['CartList'],
      }),
      emptyCart: builder.mutation<any, CartRequestModel>({
        query: ({ reseau_uuid, en_attente, lang, univers_uuid }) => {
          return {
            url: `reseaux/${reseau_uuid}`,
            method: 'delete',
            params: { en_attente, lang, univers_uuid },
          };
        },
        invalidatesTags: ['CartList'],
      }),

      addArticleToCart: builder.mutation<any, AddArticleToCartModel>({
        query: (model) => {
          return {
            url: 'articles/add',
            method: 'post',
            body: model,
          };
        },
        invalidatesTags: ['CartList'],
      }),
      getCartItems: builder.query<any, CartRequestModel>({
        query: ({ lang, univers_uuid }) => {
          return {
            url: '',
            method: 'get',
            params: {
              lang,
              univers_uuid,
            },
          };
        },
        providesTags: ['CartList'],
      }),
      searchArticle: builder.query<any, CartArticleSearchRequestModel>({
        query: ({ lang, univers, keywords, pays_uuid }) => {
          return {
            url: 'search',
            method: 'get',
            params: {
              keywords,
              lang,
              pays_uuid,
              univers,
              reseau_commercial_uuid: null,
              bootstrapCol: 'lg',
            },
          };
        },
      }),
    };
  },
});

export const {
  useAddToCartMutation,
  useGetCartItemsQuery,
  useLazySearchArticleQuery,
  useDeleteCartItemMutation,
  useEmptyCartMutation,
  useUpdateCartItemMutation,
  useAddArticleToCartMutation,
} = shoppingCartApi;
