import { Article, Fichier } from '../article/articleModels';
import { Family } from '../family/familyModels';

export type Brand = {
  code?: string;
  created_at?: Date;
  defaut_univers_uuid?: string;
  fichiers?: [];
  image?: string;
  image_guid?: string;
  libelle?: string;
  logo?: string;
  nom?: string;
  updated_at?: Date;
  uuid?: string;
};

export type ProductWarrantyType = {
  slug: string;
};

export type Nomenclature = {
  commentaire: string;
  complement?: string;
  created_at: string;
  libelle: string;
  reference: string;
  schemas: Schema[];
  updated_at: string;
  uuid: string;
};

export type Schema = {
  created_at: string;
  fichiers: Fichier;
  image: string;
  image_guid?: string;
  libelle: string;
  line_number?: string;
  nom: string;
  nomenclature_uuid: string;
  updated_at: string;
  uuid: string;
};

export type Zone = {
  articles: Article[];
  created_at: string;
  is_commandable: boolean;
  libellestring: string;
  libelle_gescom?: string;
  margin_left: number;
  margin_top: number;
  reseau_uuid: string;
  schema_uuid: string;
  stock: number;
  taille: string;
  updated_at?: string;
  uuid: string;
  libelle: string;
  selected: boolean;
};

export type Product = {
  accessoires: Product[];
  uuid: string;
  reseau_uuid?: string;
  reference: string;
  code: string;
  commentaire?: string;
  image: string;
  is_masked: boolean;
  marque_uuid: string;
  univers_uuid: string;
  client: string;
  nomenclature_uuid: string;
  nomenclature: Nomenclature;
  quantite?: number;
  lettre?: string;
  created_at: string;
  updated_at: string;
  image_guid?: string;
  from_erp?: boolean;
  garantieForceSav?: boolean;
  refcom2?: string;
  levenshtein: number;
  isEnCompte: boolean;
  typesGarantie: {
    'client-en-compte': ProductWarrantyType;
    'station-sav': ProductWarrantyType;
  };
  reseau: SalesNetwork;
  marque: Brand;
  familles: Family[];
  fichiers: [];
  libelle: string;
};

export type SalesNetwork = {
  uuid: string;
  nom: string;
  code: string;
  created_at: Date;
  updated_at: Date;
  is_francais: boolean;
  sap_prix: boolean;
  sap_dist_chan: string;
  sap_sales_org: string;
  sap_code_client: string;
  devis_active: boolean;
  devis_template?: string;
  sap_code_client_part?: string;
  station_web?: boolean;
};

export type Serial = {
  reference?: string;
  date?: string;
  equivalent?: string;
};

export enum SearchFilterType {
  articles = 'articles',
  produits = 'produits',
  familles = 'familles',
}
export enum SearchTypeSerial {
  serie = 'serie',
}

export type SearchItem = {
  current_page: number;
  data: Product[] | Article[] | Family[];
  last_page: number;
  next_page_url?: string;
  prev_page_url?: string;
  per_page: number;
  total: number;
};

export type SearchData = Record<SearchFilterType, SearchItem>;

export type SerialSearchData = SearchData & Record<SearchTypeSerial, Serial>;

export type SearchFilter = {
  main_familles_uuids: string[];
  type?: SearchFilterType;
};

export type Paging = {
  limit?: number;
  page?: number;
};

export type SearchRequestModel = SearchFilter &
  Paging & {
    univers: string;
    pays_uuid: string;
    lang: string;
    keywords?: string;
    keywords_articles?: string;
    bootstrapCol?: string;
  };
