// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import { Article } from '../article/articleModels';
import baseQuery from '../baseQuery';
import { Defects } from '../sav/savModels';
import { WarrantyRequest, WarrantyRequestUpdateModel } from './warrantyRequestModels';

export const warrantyRequestApi = createApi({
  reducerPath: 'warrantyRequestApi',
  baseQuery: baseQuery('garanties'),
  endpoints: (builder) => {
    return {
      getWarrantyRequest: builder.query<
        WarrantyRequest,
        {
          demande_uuid: string;
          univers_uuid: string;
          domain: string;
          lang: string;
          pays_uuid: string;
        }
      >({
        query: ({ demande_uuid, univers_uuid, domain, lang, pays_uuid }) => {
          return {
            url: `/demandes/${demande_uuid}`,
            method: 'get',
            params: {
              univers_uuid,
              lang,
              pays_uuid,
              domain,
            },
          };
        },
      }),
      getWarrantyDefects: builder.query<Defects, string>({
        query: (defaut_uuid) => {
          return {
            url: `/defauts/${defaut_uuid}`,
            method: 'get',
          };
        },
      }),
      getWarrantyArticles: builder.query<
        Article[],
        { uuid: string; univers_uuid: string; lang: string; pays_uuid: string }
      >({
        query: ({ uuid, univers_uuid, pays_uuid, lang }) => {
          return {
            url: `/${uuid}/articles`,
            method: 'get',
            params: {
              univers_uuid,
              pays_uuid,
              lang,
            },
          };
        },
      }),
      createOrUpdateWarrantyRequest: builder.mutation<WarrantyRequest, WarrantyRequestUpdateModel>({
        query: ({ uuid, file, ...rest }) => {
          let body = rest;
          if (file) {
            const formData = new FormData();
            formData.append('file', file);
            Object.keys(rest).forEach((k) => formData.append(k, rest[k as keyof {}]));
            body = formData as any;
          }
          return {
            url: `/demandes${uuid ? `/${uuid}` : ''}`,
            method: 'post',
            body,
          };
        },
      }),
    };
  },
});

export const {
  useGetWarrantyRequestQuery,
  useCreateOrUpdateWarrantyRequestMutation,
  useGetWarrantyDefectsQuery,
  useGetWarrantyArticlesQuery,
} = warrantyRequestApi;
