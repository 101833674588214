import { useMemo } from 'react';
import Button from '../../components/shared/button';
import { WarrantyRequestSatcStatus, WarrantyRequestSteps, WarrantyType } from '../../project/defines';
import { WarrantyRequest } from '../../store/warrantyRequest/warrantyRequestModels';

const StepButtons: React.FC<{
  request: WarrantyRequest;
  onClickLeft?: () => any;
  disableLeft?: boolean;
  onClickRight?: () => any;
  disableRight?: boolean;
  onClickSatc?: () => any;
  disableSatc?: boolean;
}> = ({ request, onClickLeft, disableLeft, onClickRight, disableRight, onClickSatc, disableSatc }) => {
  let leftButton = useMemo(() => {
    let text;
    let icon;
    if (request?.etape === WarrantyRequestSteps.etape1) {
      text = 'annuler';
    } else {
      text = 'previous';
      icon = 'chevron_left';
    }
    return (
      <Button
        outline
        large
        icon={icon}
        textFirst={false}
        text={`garantie.demande.etapes.boutons.${text}`}
        disabled={disableLeft}
        onClick={() => {
          onClickLeft?.();
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        }}
      />
    );
  }, [request, onClickLeft, disableLeft]);

  let rightButton = useMemo(() => {
    const { garantie_type, satc_attente, typedemande, produit } = request;
    let text;
    let icon;
    if (request?.etape === WarrantyRequestSteps.etape3) {
      if (produit?.garantieForceSav && satc_attente !== WarrantyRequestSatcStatus.Refuse) {
        return null;
      }

      const isAvg = garantie_type?.isAvg && satc_attente === WarrantyRequestSatcStatus.Refuse;
      if (typedemande === WarrantyType.Commande && !isAvg) {
        text = 'commande';
        icon = 'shopping_basket';
      } else {
        text = 'valider';
        icon = 'chevron_right';
      }
    } else {
      text = 'next';
      icon = 'chevron_right';
    }
    return (
      <Button
        primary
        large
        icon={icon}
        text={`garantie.demande.etapes.boutons.${text}`}
        disabled={disableRight}
        onClick={() => {
          onClickRight?.();
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        }}
      />
    );
  }, [request, onClickRight, disableRight]);

  let satcButton = useMemo(() => {
    const { satc_attente } = request;
    if (request?.etape === WarrantyRequestSteps.etape3 && satc_attente !== 'refusee') {
      return (
        <Button
          outline
          large
          icon="forum"
          text="garantie.demande.etapes.boutons.satc"
          disabled={disableSatc}
          onClick={onClickSatc}
        />
      );
    }
  }, [request, onClickSatc, disableSatc]);

  return (
    <group data-justify="center" data-space="20">
      <group data-gap="20" data-adaptive="800" data-justify="center">
        {leftButton}
        {rightButton}
        {satcButton}
      </group>
    </group>
  );
};

export default StepButtons;
