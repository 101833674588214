import { Breadcrumb } from 'antd';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from 'react';
import { createPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../components/shared/loader';
import { Text } from '../../components/shared/text';
import { APP_URL } from '../../project/defines';
import { getAppUrl, isGroupSSO } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';
import { addNotification, setShowTarifMessage } from '../../store/componentsSlice';
import { Family } from '../../store/family/familyModels';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Product } from '../../store/product/productModels';
import {
  usePrefetch,
  useSearchAdvancedQuery,
  useSearchQuery,
  useSearchSerialQuery,
} from '../../store/search/searchApi';
import {
  Paging,
  SearchData,
  SearchFilter,
  SearchFilterType,
  SearchItem,
  SearchRequestModel,
} from '../../store/search/searchModels';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import useTranslation from '../../utils/hooks/useTranslation';
import { LeftFilter } from './leftFilter';
import SearchResultList from './searchResultList';
import { UniversInfo } from '../../store/univers/universModels';

export type SearchResultFilter = {
  isMount: boolean;
  searchTypes: SearchFilterType[];
  setSearchTypes: Dispatch<SetStateAction<SearchFilterType[]>>;
  appliedFilter: SearchFilter;
  paginationData: Paging;
  setPaginationData: Dispatch<SetStateAction<Paging>>;
  isTypeSelected: (type: SearchFilterType) => boolean;
  setType: (type: SearchFilterType) => void;
  toggleFamily: (family_uuid: string, newValue?: boolean) => void;
  clearFilter: () => void;
  univers_uuid: string;
  lang: string;
  pays_uuid: string;
};

export const searchTypesConfig = [
  {
    type: SearchFilterType.familles,
    filterLabel: 'recherche.famillesFilter',
    titleLabel: 'recherche.familles',
    suggestionTitleLabel: 'recherche.typesData.familles',
  },
  {
    type: SearchFilterType.produits,
    filterLabel: 'recherche.produitsFilter',
    titleLabel: 'recherche.produits',
    suggestionTitleLabel: 'recherche.typesData.produits',
  },
  {
    type: SearchFilterType.articles,
    filterLabel: 'recherche.articlesFilter',
    titleLabel: 'recherche.articles',
    suggestionTitleLabel: 'recherche.typesData.articles',
  },
];

const useResultsFilter: () => SearchResultFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, pathname, search } = location;
  const currentPath = createPath(location);

  const univers_uuid = useAppSelector((state) => state.siteData.universInfo!.univers!.uuid);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const families = useAppSelector((s) => s.siteData.families) ?? [];

  const [isMount, setIsMount] = useState(false);
  const [savedFilter, setSavedFilter] = useState<SearchFilter | null>(null);

  const [searchTypes, setSearchTypes] = useState<SearchFilterType[]>([]);
  const [filterData, setFilterData] = useState<SearchFilter>({
    main_familles_uuids: families?.map((f) => f.uuid),
  });
  const [appliedFilter, setAppliedFilter] = useState<SearchFilter>({
    ...filterData,
  });
  const [paginationData, setPaginationData] = useState<Paging>({});

  useEffect(() => {
    setIsMount(true);
    setSavedFilter(state?.appliedFilter);
  }, [pathname, search]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMount && !savedFilter) {
      navigate(currentPath, {
        state: { ...state, appliedFilter },
        replace: true,
      });
    }
  }, [appliedFilter]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (savedFilter && searchTypes.length) {
      if (savedFilter?.type) {
        setFilterData({ ...savedFilter });
      }
      setSavedFilter(null);
    }
  }, [searchTypes]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppliedFilter({ ...filterData });
    setPaginationData(filterData.type ? { page: 1, limit: 36 } : {});
    if (!filterData.type) {
      setSearchTypes([]);
    }
  }, [filterData, lang, pays_uuid]);

  const isTypeSelected = (type: SearchFilterType) => type === appliedFilter.type;
  const setType = (type: SearchFilterType) => setFilterData((prev: SearchFilter) => ({ ...prev, type }));
  const toggleFamily = (family_uuid: string, newValue?: boolean) =>
    setFilterData((prev: SearchFilter) => ({
      ...prev,
      main_familles_uuids: families
        .filter((f) =>
          f.uuid !== family_uuid
            ? prev.main_familles_uuids?.includes(f.uuid)
            : newValue ?? !prev.main_familles_uuids?.includes(family_uuid)
        )
        .map((f) => f.uuid),
    }));
  const clearFilter = () =>
    setFilterData({
      type: undefined,
      main_familles_uuids: families ? [...families.map((f) => f.uuid)] : [],
    } as SearchFilter);

  return {
    isMount,
    searchTypes,
    setSearchTypes,
    univers_uuid,
    lang,
    pays_uuid,
    appliedFilter,
    paginationData,
    setPaginationData,
    isTypeSelected,
    setType,
    toggleFamily,
    clearFilter,
  };
};

export const SearchResults: React.FC = () => {
  const { t } = useTranslation();
  const { keywords } = useParams();
  const [searchParams] = useSearchParams();
  const isSatc = useAppSelector((s) => s.components.satcMode);
  const { listeSSO } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const keywords_serie = searchParams.get('keywords_serie') ?? undefined;
  const keywords_articles = (!keywords_serie && searchParams.get('keywords_articles')) || undefined;
  const dispatch = useAppDispatch();

  const [appliedKeywords, setAppliedKeywords] = useState({
    keywords,
    keywords_serie,
    keywords_articles,
  });
  const [processedResult, setProcessedResult] = useState<SearchData>({} as SearchData);

  const filter = useResultsFilter();
  const {
    isMount,
    searchTypes,
    clearFilter,
    setSearchTypes,
    appliedFilter,
    paginationData,
    setPaginationData,
    setType,
    isTypeSelected,
    univers_uuid,
    lang,
    pays_uuid,
  } = filter;

  const prefetch = usePrefetch('search');

  const hideSerialSearch = useAppSelector((state) => state.siteData?.universInfo?.settings.hide_serial_number_search);
  const isSerialSearch = !hideSerialSearch && !!appliedKeywords.keywords_serie && !appliedFilter.type;
  const isSearchAdvanced =
    !appliedKeywords.keywords_serie && !!appliedKeywords.keywords_articles && !appliedFilter.type;
  const queryKeywords = isSerialSearch ? appliedKeywords.keywords_serie : appliedKeywords.keywords;

  const searchRequestParams = {
    lang,
    keywords: queryKeywords,
    keywords_articles: appliedKeywords.keywords_articles,
    univers: univers_uuid,
    pays_uuid,
    satc_mode: isSatc,
    ...paginationData,
    ...appliedFilter,
  };
  const skip = !queryKeywords || !univers_uuid || !pays_uuid || (!paginationData.page && !!appliedFilter.type);

  const resultSerial = useSearchSerialQuery(searchRequestParams as SearchRequestModel, {
    skip: !isSerialSearch || skip,
  });
  const advancedResult = useSearchAdvancedQuery(searchRequestParams as SearchRequestModel, {
    skip: !isSearchAdvanced || skip,
  });

  const result = useSearchQuery(searchRequestParams as SearchRequestModel, {
    skip: isSearchAdvanced || isSerialSearch || skip,
  });
  const {
    data: searchResult,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = isSerialSearch ? resultSerial : isSearchAdvanced ? advancedResult : result;

  useEffect(() => {
    if (isMount) {
      clearFilter();
      setAppliedKeywords({ keywords, keywords_serie, keywords_articles });
    }
  }, [keywords, keywords_serie, keywords_articles]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let _keywords = '',
      nb = 0;

    if (!processedResult || isLoading || isFetching) {
      return;
    }

    if (isSerialSearch) {
      if (isError) {
        nb = 0;
      } else {
        nb = processedResult.produits?.total || 0;
      }
    } else {
      Object.keys(processedResult).forEach((index: string) => {
        nb += (processedResult as Record<string, any>)[index].total || 0;
      });
    }

    let typeRecherche: string;
    let numSerieRecherche = undefined;
    let produitRecherche = undefined;
    let pieceRecherche = undefined;

    if (!isSearchAdvanced) {
      if (isSerialSearch) {
        _keywords = keywords ?? '';
        typeRecherche = 'Recherche par numéro de série';
        numSerieRecherche = keywords_serie;
      } else {
        _keywords = keywords ?? '';
        typeRecherche = 'Recherche globale';
      }
    } else {
      _keywords =
        t('rechercheAvancee.produitLabel') +
        ' ' +
        keywords +
        ' ' +
        t('rechercheAvancee.articleLabel') +
        ' ' +
        keywords_articles;
      typeRecherche = 'Recherche avancée';
      produitRecherche = keywords;
      pieceRecherche = keywords_articles;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    const final = {
      recherche: _keywords,
      nbResultats: nb,
      typeRecherche: typeRecherche,
      numSerieRecherche: numSerieRecherche,
      produitRecherche: produitRecherche,
      pieceRecherche: pieceRecherche,
      event: 'recherche-sav',
    };

    window.dataLayer.push(final);
  }, [processedResult, t, isError]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Number(paginationData.page) >= 1) {
      prefetch({
        ...(searchRequestParams as SearchRequestModel),
        page: paginationData.page! + 1,
      });
    }
  }, [paginationData.page]); //eslint-disable-line react-hooks/exhaustive-deps

  let total: number = Object.values(processedResult).reduce((prev, curr) => prev + curr?.total || 0, 0);

  useEffect(() => {
    if (appliedFilter.type) {
      searchResult &&
        setProcessedResult((prev) => ({
          ...prev,
          [appliedFilter.type!]:
            (paginationData.page || 1) > 1
              ? {
                  ...(searchResult as SearchItem),
                  data: [...prev[appliedFilter.type!].data, ...(searchResult as SearchItem)!.data] as
                    | Product[]
                    | Article[]
                    | Family[],
                }
              : (searchResult as SearchItem),
        }));
    } else {
      const data = (searchResult as SearchData) || {};
      setProcessedResult(data);
      setSearchTypes(
        data
          ? (Object.keys(data).filter((k) => data[k as SearchFilterType].data?.length > 0) as SearchFilterType[])
          : []
      );
    }
  }, [searchResult]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMount && isSuccess && isSerialSearch && !processedResult?.produits?.total) {
      dispatch(
        addNotification({
          type: 'warning',
          message: t('recherche.noResultats'),
          description: t('rechercheSerie.error_not_exists'),
          duration: 5,
        })
      );

      setProcessedResult({} as SearchData);
    }
  }, [isSuccess, isMount]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isGroupSSO(listeSSO)) {
      if (processedResult.articles?.data.length) {
        processedResult.articles.data.forEach((item: any, index) => {
          if (item.articleReseau.statut !== 4 && item.articleReseau.statut !== 5 && item.articleReseau.prix_ht) {
            dispatch(setShowTarifMessage(true));
          }
        });
      } else {
        dispatch(setShowTarifMessage(false));
      }
    }
  }, [processedResult, listeSSO, dispatch]);

  const breadcrumpItems = [
    { title: t('ariane.accueil'), href: getAppUrl(APP_URL.HomePage) },
    {
      title: t('ariane.recherche', {
        keyword: isSearchAdvanced
          ? `${t('rechercheAvancee.produitLabel')} ${keywords}, ${t(
              'rechercheAvancee.articleLabel'
            )} ${keywords_articles}`
          : keywords!,
        nb: total,
        pluriel: total > 1 ? 's' : '',
      }),
    },
  ];

  const overlay = isLoading || (isFetching && !(2 <= (paginationData.page as number)));
  const loadMoreData = useCallback(() => {
    !isFetching && paginationData.page && setPaginationData((prev) => ({ ...prev, page: (prev.page ?? 1) + 1 }));
  }, [isFetching, processedResult]);
  return (
    <section>
      <wrapper>
        <group data-space="15" data-align="center">
          <Text data-weight="700" data-ellipsis="">
            {(isSuccess || isSerialSearch) && <Breadcrumb items={breadcrumpItems} />}
          </Text>
        </group>
        {overlay && <Loader />}
        <group data-direction="row" data-gap="5" data-align="start" data-adaptive="800">
          <LeftFilter {...filter} />
          {!isLoading && (
            <group data-direction="column" data-fit="1">
              {searchTypesConfig
                .filter((stc) => searchTypes.includes(stc.type))
                .map((stc, i) => {
                  const { type: searchType, titleLabel } = stc;
                  const item = processedResult[searchType];
                  const isSelected = isTypeSelected(searchType);
                  return (
                    <Fragment key={searchType}>
                      {item && (!appliedFilter.type || isSelected) && (
                        <>
                          {i > 0 && !isSelected && <space data-space="10"></space>}
                          <SearchResultList
                            data={item}
                            titleLabel={titleLabel}
                            isSelected={isSelected}
                            type={searchType}
                            setType={setType}
                            lazyLoadProps={
                              !overlay && isSelected
                                ? {
                                    total: item.total,
                                    loadMoreData,
                                  }
                                : undefined
                            }
                          />
                        </>
                      )}
                    </Fragment>
                  );
                })}
            </group>
          )}
        </group>
      </wrapper>
    </section>
  );
};
