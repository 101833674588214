import { SetStateAction, useCallback, useState, Dispatch } from 'react';
import { WarrantyArticleLine } from '..';
import Button from '../../../../components/shared/button';
import { Text } from '../../../../components/shared/text';
import { Article } from '../../../../store/article/articleModels';
import { addNotification } from '../../../../store/componentsSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { WarrantyRequest } from '../../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../../utils/hooks/useTranslation';
import ArticleSearchAutocomplete from './articleSearchAutocomplete';
import ProductInfo from '../../../../components/productInfo';
import Popup from '../../../../components/shared/popup';

const WarrantyArticleSelection: React.FC<{
  request: WarrantyRequest;
  show: boolean;
  selectedArticles: WarrantyArticleLine[];
  setSelectedArticles: Dispatch<SetStateAction<WarrantyArticleLine[]>>;
}> = ({ request, show, selectedArticles, setSelectedArticles }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const isArticleSelected = useCallback(
    (article: Article) =>
      !!article &&
      selectedArticles.some((selectedArticle: WarrantyArticleLine) => selectedArticle.article.uuid === article.uuid),
    [selectedArticles]
  );

  const onSelectArticleHandler = useCallback(
    (article: Article) => {
      if (
        (!article.articleReseau && !article.is_commandable) ||
        (article.articleReseau && !article.articleReseau.isCommandable)
      ) {
        dispatch(
          addNotification({
            type: 'error',
            message: t('garantie.demande.etapes.2.errors.noCommandable.title', { reference: article.reference }),
            description: 'garantie.demande.etapes.2.errors.noCommandable.message',
            duration: 5,
          })
        );
      } else {
        if (!isArticleSelected(article)) {
          //article selection is disabled if already selected , quantity can be changed from the selection
          //let articleIndex = selectedArticles.findIndex(a => a.article.uuid === article.uuid);
          //if (articleIndex === -1) {
          setSelectedArticles([...selectedArticles, { article, qte: 1 }]);
          //} else {
          //const newItems = [...selectedArticles];
          //newItems.splice(articleIndex, 1, { ...selectedArticles[articleIndex], qte: selectedArticles[articleIndex].qte + 1 });
          //setSelectedArticles(newItems);
          //};
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: t('garantie.demande.etapes.2.autocomplete.addArticleErrorTitle'),
              description: 'garantie.demande.etapes.2.autocomplete.addArticleErrorMessage',
              duration: 5,
            })
          );
        }
      }
    },
    [selectedArticles]
  ); //eslint-disable-line

  return (
    <view data-border="none" data-scroll="" data-space="5" data-auto-height="" data-max-length="300">
      {show && (
        <>
          {openDetail && (
            <Popup
              title={request.produit?.libelle}
              onClose={() => {
                setOpenDetail(false);
              }}
              fixSize="fullscreen"
            >
              <group data-scroll="" data-space="20">
                <ProductInfo
                  id={request.produit?.uuid}
                  selectable={true}
                  setSelectedArticles={setSelectedArticles}
                  selectedArticles={selectedArticles}
                  setOpenDetail={setOpenDetail}
                />
              </group>
            </Popup>
          )}
          <group data-space-vertical="20">
            <Text dataWeight="700" accent dataTextSize="medium" dataWrap="wrap">
              garantie.demande.etapes.2.SelectArticles.label
            </Text>
          </group>
          <group>
            <Button
              data-auto-height=""
              large
              primary
              wide
              textFirst={false}
              text="garantie.demande.etapes.2.SelectArticles.button"
              onClick={() => {
                setOpenDetail(true);
              }}
              icon="search"
            />
          </group>
          <group data-space-vertical="20">
            <Text dataWeight="700" dataWrap="">
              garantie.demande.etapes.2.autocomplete.label
            </Text>
          </group>
          <group>
            <ArticleSearchAutocomplete
              request={request}
              onSelect={onSelectArticleHandler}
              isArticleSelected={isArticleSelected}
            />
          </group>
        </>
      )}
    </view>
  );
};

export default WarrantyArticleSelection;
