import { Article } from '../article/articleModels';
import { Family } from '../family/familyModels';
import { Product } from '../product/productModels';

export type Brand = {
  code?: string;
  created_at?: Date;
  defaut_univers_uuid?: string;
  fichiers?: [];
  image?: string;
  image_guid?: string;
  libelle?: string;
  logo?: string;
  nom?: string;
  updated_at?: Date;
  uuid?: string;
};

export type SalesNetwork = {
  uuid: string;
  nom: string;
  code: string;
  created_at: Date;
  updated_at: Date;
  is_francais: boolean;
  sap_prix: boolean;
  sap_dist_chan: string;
  sap_sales_org: string;
  sap_code_client: string;
  devis_active: boolean;
  devis_template?: string;
  sap_code_client_part?: string;
  station_web?: boolean;
};

export type Serial = {
  reference?: string;
  date?: string;
  equivalent?: string;
};

export enum SearchFilterType {
  articles = 'articles',
  produits = 'produits',
  familles = 'familles',
}
export enum SearchTypeSerial {
  serie = 'serie',
}

export type SearchItem = {
  current_page: number;
  data: Product[] | Article[] | Family[];
  last_page: number;
  next_page_url?: string;
  prev_page_url?: string;
  per_page: number;
  total: number;
};

export type SearchData = Record<SearchFilterType, SearchItem>;

export type SerialSearchData = SearchData & Record<SearchTypeSerial, Serial>;

export type SearchFilter = {
  main_familles_uuids?: string[];
  type?: SearchFilterType;
};

export type Paging = {
  limit?: number;
  page?: number;
};

export type SearchRequestModel = SearchFilter &
  Paging & {
    univers: string;
    pays_uuid: string;
    lang: string;
    keywords?: string;
    keywords_articles?: string;
    bootstrapCol?: string;
    satc_mode?: boolean;
  };

export type WarrantyArticleSearchRequestModel = SearchRequestModel & {
  product_uuid: string;
  reseau_commercial_uuid: string;
};
