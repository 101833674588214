//const baseKey: string = ((window as any).config as any).REACT_APP_API_URL;

const baseKey: string | undefined = process.env.REACT_APP_API_URL;

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(`${baseKey}-${key}`);
};

export const setLocalStorageItem = (key: string, value: any) => {
  return localStorage.setItem(`${baseKey}-${key}`, value);
};

export const removeLocalStorageItem = (key: string) => {
  return localStorage.removeItem(`${baseKey}-${key}`);
};
