import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '.';
import useTranslation from '../utils/hooks/useTranslation';
import { ApiErrorHandler } from './apiErrorHandler';
import { setCartCount } from './componentsSlice';
import { familyApi } from './family/familyApi';
import { useAddToCartMutation } from './shoppingCart/shoppingCartApi';
import { ArticleAddToShoppingCartModel } from './shoppingCart/shoppingCartModels';
import { currentCountryUuid } from './sideData/siteDataSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGetFamiliesQueryState = () => {
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const isSatc = useAppSelector((state) => state.components.satcMode);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid);

  const state = familyApi.endpoints.getFamilies.useQueryState({
    univers_uuid: univers?.uuid ?? '',
    lang,
    pays_uuid: pays_uuid ?? '',
    satc_mode: isSatc,
  });

  return state;
};

export const useUpdateCartData = (cartData: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cartData) {
      let cartCount = 0;
      if (cartData.paniers) {
        Object.keys(cartData.paniers).forEach((keyReseau) => {
          Object.keys(cartData.paniers[keyReseau].lignes_panier).forEach((keyLignePanier) => {
            cartCount += cartData.paniers[keyReseau].lignes_panier[keyLignePanier].quantite;
          });
        });
      }

      dispatch(setCartCount(cartCount));
    }
  }, [cartData, dispatch]);
};

export const useAddToCartHook = () => {
  const { t } = useTranslation();
  const [cartData, setCartData] = useState<any>(null);
  useUpdateCartData(cartData);
  const dispatch = useAppDispatch();
  const [addArticleToCart, queryState] = useAddToCartMutation();
  const addToCart = async (data: ArticleAddToShoppingCartModel) => {
    try {
      let result: any = await addArticleToCart(data).unwrap();
      setCartData(result);
      return result;
    } catch (err: any) {
      ApiErrorHandler(err, dispatch, t, 'global.notifications.paniers.titleSingle');
    }
  };

  return { addToCart, queryState };
};
