import { createSearchParams, generatePath, matchPath, PathMatch } from 'react-router-dom';
import { Article } from '../store/article/articleModels';
import { AccessModel, AuthModel } from '../store/auth/authModels';
import { ProductWarrantyType } from '../store/product/productModels';
import { SSOItem } from '../store/univers/universModels';
import { APP_URL, DEFAULT_COUNTRY, DEFAULT_COUNTRY_LANGUAGE_SEPARATOR, DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS, SLUG_ACCESS_KEYS, UNIVERS, WarrantyType } from './defines';
import { getLocalStorageItem } from '../utils/helpers/localStorage';

export const isLocalhost = Boolean(window.location.hostname.includes('localhost'));

export const getAppUrl = (
  url: string,
  options?: {
    routeParams?: Record<string, string>;
    searchParams?: Record<string, string>;
  }
) => {
  let languagesData,
    languagesJson = getLocalStorageItem(LOCAL_STORAGE_KEYS.Language);

  if (languagesJson) {
    languagesData = JSON.parse(languagesJson);
  }

  let countryData,
    countryJson = getLocalStorageItem(LOCAL_STORAGE_KEYS.Country);

  if (countryJson && countryJson !== 'undefined') {
    countryData = JSON.parse(countryJson);
  }

  const { routeParams, searchParams } = options ?? {};

  //debugger
  let result = !routeParams
    ? url
    : generatePath(
        url,
        routeParams
          ? Object.fromEntries(
              //Object.entries(routeParams).map((o) => [o[0], o[1]?.replace('%', encodeURIComponent('%'))])
              Object.entries(routeParams).map((o) => [o[0], encodeURIComponent(o[1])])
            )
          : {}
      );

  if (searchParams) {
    const sp = createSearchParams(searchParams).toString();
    result = `${result}?${sp}`;
  }
  const pattern = matchPath({ path: APP_URL.Base, end: false }, window.location.pathname) as PathMatch;

  if (languagesData) {
    if (pattern) {
      const langFromPattern = languagesData?.languages?.some((l: any) => {
        return l.lang === pattern.params.lang;
      });

      if (langFromPattern) {
        return `${pattern?.pathname || ''}/${result}`;
      } else {
        return `/${countryData?.code || DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${languagesData.currentLanguage || DEFAULT_LANGUAGE}/${result}`;
      }
    } else {
      return `/${countryData?.code || DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${languagesData.currentLanguage || DEFAULT_LANGUAGE}/${result}`;
    }
  } else {
    return `/${DEFAULT_COUNTRY}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${DEFAULT_LANGUAGE}/${result}`;
  }
};

export const getUniversFromUrl = (domain?: string) => {
  let host = window.location?.host;
  domain = UNIVERS.Other; //UNIVERS.ThermorFR;

  Object.values(UNIVERS).some((univers) => {
    if (getUrlByUniverse(univers)?.includes(host)) {
      domain = univers;
      return true;
    }
    return null;
  });
  return domain;
};

export const getApiUrlBase = (univers: string) => {
  let pattern = process.env.REACT_APP_API_URL;
  switch (univers) {
    case UNIVERS.Atlantic:
    case UNIVERS.Thermor:
    case UNIVERS.Acv:
    case UNIVERS.Ygnis:
      pattern = process.env.REACT_APP_API_URL_INT;
      break;
    case UNIVERS.ThermorFR:
      pattern = process.env.REACT_APP_API_URL_PRO;
      break;
    case UNIVERS.AtlanticFR:
    case UNIVERS.Pacific:
      pattern = process.env.REACT_APP_API_URL_PROS;
      break;
  }
  return pattern!.replace('brand', getUniversForUrl(univers));
};

export const getUrlByUniverse = (univers: string) => {
  let url: string | undefined = '';
  if (isLocalhost) {
    const parts = window.location?.host?.split('.');
    const index = parts.findIndex((p) => p.startsWith('localhost'));
    url = 'http://' + [univers, ...parts.slice(index)].join('.'); //nosonar
  } else {
    switch (univers) {
      case UNIVERS.Atlantic:
        url = process.env.REACT_APP_URL_Atlantic_INT;
        break;
      case UNIVERS.AtlanticFR:
        url = process.env.REACT_APP_URL_Atlantic_FR;
        break;
      case UNIVERS.Thermor:
        url = process.env.REACT_APP_URL_Thermor_INT;
        break;
      case UNIVERS.ThermorFR:
        url = process.env.REACT_APP_URL_Thermor_FR;
        break;
      case UNIVERS.Acv:
        url = process.env.REACT_APP_URL_ACV;
        break;
      case UNIVERS.AcvFR:
        url = process.env.REACT_APP_URL_ACV_FR;
        break;
      case UNIVERS.AutresMarques:
        url = process.env.REACT_APP_URL_Autres_marques;
        break;
      case UNIVERS.EquationWelcome:
        url = process.env.REACT_APP_URL_Equation_Welcome;
        break;
      case UNIVERS.Oem:
        url = process.env.REACT_APP_URL_OEM;
        break;
      case UNIVERS.Pacific:
        url = process.env.REACT_APP_URL_Pacific;
        break;
      case UNIVERS.Sauter:
        url = process.env.REACT_APP_URL_Sauter;
        break;
      case UNIVERS.Ygnis:
        url = process.env.REACT_APP_URL_Ygnis_INT;
        break;
    }
  }
  return url;
};

export const getApiUrl = (univers: string) => {
  return `${getApiUrlBase(univers)}/api`;
};

export const getUniversForUrl = (brand: string, forUniversRequest?: boolean) => {
  switch (brand) {
    case UNIVERS.AtlanticFR:
      return UNIVERS.Atlantic;
    case UNIVERS.AcvFR:
      return UNIVERS.Acv;
    case UNIVERS.ThermorFR:
      return forUniversRequest ? UNIVERS.EspaceSav : UNIVERS.Thermor;
  }
  return brand;
};

export const getBrandFolder = (brand: string) => {
  switch (brand) {
    case UNIVERS.AtlanticFR:
    case UNIVERS.ThermorFR:
      return getUniversForUrl(brand);
  }
  return 'other';
};

export const isGroupSSO = (listSSO: any) => {
  const lst_group = listSSO.filter((u: { sso_code: string | string[] }) => {
    return u.sso_code && (u.sso_code.indexOf('group') !== -1 || u.sso_code.indexOf('inter') !== -1);
  });

  return lst_group && lst_group.length > 0;
};

export const getProSSO = (listSSO: any) => {
  return listSSO
    .filter((sso: any) => [UNIVERS.Atlantic, UNIVERS.Thermor].includes(sso.sso_code))
    .map((sso: any) => {
      return {
        ...sso,
        url: getSSOUrl(sso.sso_code),
      };
    });
};

export const getSSOListWithUrl = (listSSO: any) => {
  return listSSO.map((sso: any) => {
    return {
      ...sso,
      url: getSSOUrl(sso.sso_code),
    };
  });
};

export function getSSOUrl(sso_code: string) {
  return getApiUrlBase(getUniversFromUrl()) + '/wso/redirect/' + sso_code;
}

export const checkAccess = (user: AuthModel, accesSlug: string) => {
  let result = false;

  if (user?.acces) {
    result = user.acces.filter((acces: AccessModel) => acces.slug === accesSlug).length > 0;
  }
  return result;
};

export const isArticleAvailable = (article: Article) => {
  return article && article.articleReseau?.statut !== 4 && article.articleReseau?.statut !== 5;
};
export const getArticlePriceHT = (article: Article, listSSO: any, isLoggedIn: any) => {
  return isArticleAvailable(article) && (isLoggedIn || !isGroupSSO(listSSO)) && article?.articleReseau.prix_ht;
};
export const allowArticleAddToCard = (article: Article, listSSO: any, user: any) => {
  return (
    !article.ajoutPanier ||
    (article.articleReseau.prix_ht || 0) <= 0 ||
    article.article_uuid ||
    !article.articleReseau.isCommandable ||
    !isArticleAvailable(article)
  );
};

export const showArticleCreditCardIcon = (article: Article, user: AuthModel) => {
  let { articleReseau, paiementCb, isEnCompte } = article;
  const isLoggedIn = user?.active;
  const hasAccessCommande = isLoggedIn && checkAccess(user, SLUG_ACCESS_KEYS.Commande);
  return (
    isLoggedIn &&
    !isEnCompte &&
    hasAccessCommande &&
    paiementCb &&
    articleReseau?.disponibilite &&
    articleReseau?.isCommandable &&
    (articleReseau?.prix_ht as number) > 0
  );
};

export const disableArticleAddtoCartButton = (
  article: Article,
  user: AuthModel,
  listeSSO: SSOItem[],
  disabledLogin: boolean
) => {
  let { articleReseau, article_uuid, ajoutPanier } = article;
  const isLoggedIn = user?.active;
  const proSSOList = getProSSO(listeSSO);
  const canViewDiscountedPrice = checkAccess(user, SLUG_ACCESS_KEYS.SeeDiscountedPrice);

  return (
    (!isLoggedIn && (disabledLogin || !proSSOList?.length)) ||
    (!articleReseau) ||
    (articleReseau?.prix_ht as number) <= 0 ||
    !!article_uuid ||
    !articleReseau?.isCommandable ||
    !isArticleAvailable(article) ||
    (isLoggedIn && (!canViewDiscountedPrice || !ajoutPanier))
  );
};

export const getBreadcrumbQueryParams = (locationPath: string, id?: string, reseau_uuid?: string) => {
  if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Family, end: true }, locationPath)) {
    return { famille_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: true }, locationPath)) {
    return { produit_uuid: id, reseau_uuid };
  } else if (
    matchPath({ path: APP_URL.Base + '/' + APP_URL.Product, end: true }, locationPath) ||
    matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductSchema, end: true }, locationPath) ||
    matchPath({ path: APP_URL.Base + '/' + APP_URL.ProductReseau, end: true }, locationPath)
  ) {
    return { produit_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.Article, end: true }, locationPath)) {
    return { article_uuid: id };
  } else if (matchPath({ path: APP_URL.Base + '/' + APP_URL.ArticleReseau, end: true }, locationPath)) {
    return { article_uuid: id, reseau_uuid };
  }
  return null;
};

export const renderPrice = (price: number) => {
  if (price > 0) return formatNumberEuro(price);
  else return 'article.prix.consult';
};
export const formatNumberEuro = (price: number) => {
  price = price ?? 0;
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
};

export const getDocGARight = (tags: string) => {
  const droits = ['PFS_PUBLIC', 'PFS_PRO', 'PFS_SATC', 'PFS_SAVA', 'PFS_CMISTE'];

  let documentTags = tags.split(','),
    finalTags: any[] = [];

  documentTags.forEach((tag) => {
    if (droits.includes(tag)) finalTags.push(tag);
  });

  return finalTags;
};

/**
 * Returns client type by user and reseau uuid
 * @param  {[AuthModel]} user
 * @param  {[string]} reseau_uuid
 * @return {[Boolen]}
 */
export function getClientType(user: AuthModel, reseau_uuid: string) {
  if (!user.societe || !user.societe.societe_reseau) return null;
  let societe_reseau = user.societe.societe_reseau.find((sr) => sr.reseau_uuid === reseau_uuid);
  if (!societe_reseau) return null;
  return societe_reseau.station_sav ? 'station-sav' : 'client-en-compte';
}

/**
 * Retourne si le type de garantie est compatatible avec le type de demande
 * @param  { [ProductWarrantyType]} typeGarantie
 * @param  { [string]} warrantyRequstType
 * @return { [Boolean]}
 */

export function isTypeGarantiesAllowed(typeGarantie: ProductWarrantyType, warrantyRequstType: string) {
  let typeAllowed: string[] = [];
  if (warrantyRequstType === WarrantyType.Commande) {
    typeAllowed = ['fiche-dincident', 'fiche-dincident-mod', 'avg', 'avg-mod'];
  } else if (warrantyRequstType === WarrantyType.Retour) {
    typeAllowed = ['fiche-dincident', 'fiche-dincident-mod'];
  }
  return typeGarantie && typeAllowed.indexOf(typeGarantie.slug) > -1;
}

export function isWarrantyRequestAllowedForUniverse(univers: string) {
  return univers !== UNIVERS.Sauter;
}

export function getWarrantyPrintUrl(warranty_request_uuid: string, univers_uuid: string, lang: string) {
  return `${getApiUrl(
    getUniversFromUrl()
  )}/garanties/demande/${warranty_request_uuid}/impression?${univers_uuid}&lang=${lang}`;
}
