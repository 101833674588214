import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from './product/productModels';
import { Article } from './article/articleModels';
import { BreadcrumbModel } from './breadcrumb/breadcrumbModels';

export interface AnalyticsState {
  product?: Product;
  article?: Article;
  breadcrumbList?: BreadcrumbModel[];
}
const initialState: AnalyticsState = {};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setProduct(state, payload: PayloadAction<Product>) {
      state.product = payload.payload;
    },
    setArticle(state, payload: PayloadAction<Article>) {
      state.article = payload.payload;
    },
    setBreadcrumbList(state, payload: PayloadAction<BreadcrumbModel[]>) {
      state.breadcrumbList = payload.payload;
    },
  },
});

export const { setArticle, setProduct, setBreadcrumbList } = analyticsSlice.actions;
export default analyticsSlice.reducer;
