import {RefObject, useEffect} from 'react';

export type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler?: Handler,
  refOpener?: RefObject<HTMLElement>
): void {
  useEffect(() => {
    if (ref.current && handler && refOpener?.current) {
      const callBack = (event: MouseEvent) => {
        const el = ref?.current;

        // Do nothing if clicking ref's element or descendent elements
        if (
          !el ||
          el.contains(event.target as Node) ||
          refOpener?.current?.contains(event.target as Node)
        ) {
          return;
        }

        handler(event);
      };

      document.documentElement.addEventListener('click', callBack);
      return () =>
        document.documentElement.removeEventListener('click', callBack);
    }
  }, [ref, handler, refOpener]);
}

export default useOnClickOutside;
