import React from 'react';

export const SvgSave = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="20"
      height="20"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="css-i6dzq1"
    >
      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
      <polyline points="17 21 17 13 7 13 7 21"></polyline>
      <polyline points="7 3 7 8 15 8"></polyline>
    </svg>
  );
};


export const SvgThermorForum = () => {
  return (
<svg width="50" height="50" viewBox="0 0 50 50">
  <path fill="currentColor" d="M474.923,590.066a1.163,1.163,0,0,1,.338-0.82,1.149,1.149,0,0,1,.816-0.34h16.154a1.15,1.15,0,0,1,.816.34,1.164,1.164,0,0,1,0,1.64,1.15,1.15,0,0,1-.816.34H476.077a1.149,1.149,0,0,1-.816-0.34A1.163,1.163,0,0,1,474.923,590.066Zm18.462,5.8a1.163,1.163,0,0,0-.338-0.82,1.15,1.15,0,0,0-.816-0.34H476.077a1.149,1.149,0,0,0-.816.34,1.164,1.164,0,0,0,0,1.64,1.149,1.149,0,0,0,.816.34h16.154a1.15,1.15,0,0,0,.816-0.34A1.163,1.163,0,0,0,493.385,595.866Zm19.615,0v12.76a3.474,3.474,0,0,1-3.462,3.48h-1.153v4.64a1.156,1.156,0,0,1-1.3,1.151,1.146,1.146,0,0,1-.591-0.259l-6.6-5.532H488.769a3.456,3.456,0,0,1-2.446-1.02,3.493,3.493,0,0,1-1.015-2.46v-3.48h-4.085l-6.578,7.714a1.155,1.155,0,0,1-2.03-.754v-6.96h-1.153a3.458,3.458,0,0,1-2.447-1.02,3.494,3.494,0,0,1-1.015-2.46v-16.24a3.494,3.494,0,0,1,1.015-2.46,3.458,3.458,0,0,1,2.447-1.02h25.384a3.458,3.458,0,0,1,2.447,1.02,3.494,3.494,0,0,1,1.015,2.46v6.96h9.23a3.458,3.458,0,0,1,2.447,1.02A3.494,3.494,0,0,1,513,595.866Zm-16.154,6.96a1.149,1.149,0,0,0,.816-0.34,1.163,1.163,0,0,0,.338-0.82v-16.24a1.163,1.163,0,0,0-.338-0.82,1.149,1.149,0,0,0-.816-0.34H471.462a1.157,1.157,0,0,0-1.154,1.16v16.24a1.157,1.157,0,0,0,1.154,1.16h2.307a1.149,1.149,0,0,1,.816.34,1.162,1.162,0,0,1,.338.82v4.985l4.894-5.739a1.152,1.152,0,0,1,.875-0.406h16.154Zm13.846-6.96a1.157,1.157,0,0,0-1.154-1.16h-9.23v6.96a3.494,3.494,0,0,1-1.015,2.46,3.458,3.458,0,0,1-2.447,1.02h-9.231v3.48a1.164,1.164,0,0,0,.339.82,1.146,1.146,0,0,0,.815.34h11.539a1.151,1.151,0,0,1,.739.269l5.03,4.215v-3.324a1.162,1.162,0,0,1,.338-0.82,1.149,1.149,0,0,1,.816-0.34h2.307a1.157,1.157,0,0,0,1.154-1.16v-12.76Z" transform="translate(-465.5 -574.922)"/>
</svg>
  );
};


export const SvgThermorHome = () => {
  return (

<svg width="50" height="50" viewBox="0 0 50 50">
  <path fill="currentColor" d="M47.921,25.282a1.1,1.1,0,0,1-1.027.688H42.887V44H9.226a1.108,1.108,0,0,1-1.109-1.1V25.97H4.111a1.1,1.1,0,0,1-.775-1.894L24.727,3.311a1.106,1.106,0,0,1,1.549,0L47.668,24.076A1.1,1.1,0,0,1,47.921,25.282Zm-37.335-.517V41.257H40.419V24.765a1.544,1.544,0,0,1,1.546-1.538,0.435,0.435,0,0,0,.306-0.747L25.5,6.2,8.733,22.48a0.435,0.435,0,0,0,.306.746A1.544,1.544,0,0,1,10.586,24.765Z" transform="translate(-0.5 1.5)"/>
</svg>
  );
};



export const SvgThermorTool = () => {
  return (
<svg width="50" height="50" viewBox="0 0 50 50">
  <path fill="currentColor" d="M686.7,588.884a1.105,1.105,0,0,0-1.782-.421l-4.136,4.342a2.637,2.637,0,0,1-.872.616,2.579,2.579,0,0,1-2.063,0,2.652,2.652,0,0,1-.874-0.619,2.828,2.828,0,0,1-.591-0.916,2.931,2.931,0,0,1,0-2.169,2.829,2.829,0,0,1,.592-0.917l4.14-4.345a1.148,1.148,0,0,0,.244-1.034,1.106,1.106,0,0,0-.647-0.806,8.774,8.774,0,0,0-2.459-.561h-0.018l-0.094-.007-0.118-.009a9.4,9.4,0,0,0-4.13.627,9.7,9.7,0,0,0-3.5,2.357,10.673,10.673,0,0,0-2.465,10.046l-13.554,14.176a5.249,5.249,0,0,0,0,7.181,4.783,4.783,0,0,0,1.574,1.1,4.659,4.659,0,0,0,3.723,0,4.789,4.789,0,0,0,1.573-1.1l13.549-14.171a9.563,9.563,0,0,0,9.607-2.582A10.786,10.786,0,0,0,686.7,588.884Zm-29.924,26.56a2.627,2.627,0,0,1-.873-0.619,2.8,2.8,0,0,1-.592-0.916,2.929,2.929,0,0,1,0-2.168,2.805,2.805,0,0,1,.593-0.917l13.967-14.609a1.2,1.2,0,0,0,.282-0.537,1.238,1.238,0,0,0-.021-0.621,8.355,8.355,0,0,1,1.8-8.437,7.512,7.512,0,0,1,2.727-1.848,7.256,7.256,0,0,1,2.666-.508c0.183,0,.368.006,0.553,0.021h0a1.771,1.771,0,0,1,.3.049l-2.754,2.868a5.248,5.248,0,0,0,0,7.18,4.8,4.8,0,0,0,1.578,1.1,4.7,4.7,0,0,0,3.728,0,4.8,4.8,0,0,0,1.578-1.1l2.692-2.816a8.122,8.122,0,0,1-2.155,6.492,7.558,7.558,0,0,1-3.751,2.172,7.323,7.323,0,0,1-4.281-.272,1.049,1.049,0,0,0-1.143.262l-13.967,14.608a2.658,2.658,0,0,1-.872.616A2.579,2.579,0,0,1,656.776,615.444Z" transform="translate(-645.094 -574.968)"/>
</svg>
  );
};

export const SvgThermorContact = () => {
  return (
<svg  width="50" height="50" viewBox="0 0 50 50">
  <path fill="currentColor" d="M599.491,600.869h0.014a9.41,9.41,0,0,0,8.43-5.228h2.973l0.245-.726a11.925,11.925,0,0,0,.622-3.819,12.284,12.284,0,0,0-24.566,0v1.063h2.917A9.3,9.3,0,0,0,599.491,600.869Zm6.98-7.353h-3.487a3.2,3.2,0,1,0,0,2.125h2.469a7.265,7.265,0,0,1-5.953,3.1h-0.008a7.142,7.142,0,1,1,0-14.283h0a7.2,7.2,0,0,1,7.245,7.141A7.07,7.07,0,0,1,606.471,593.516Zm-5.438,1.063a1.1,1.1,0,0,1-1.113,1.092h0a1.089,1.089,0,0,1-.777-1.868,1.116,1.116,0,0,1,.778-0.318h0.006A1.1,1.1,0,0,1,601.033,594.579Zm-1.541-13.474a10.076,10.076,0,0,1,10.135,9.991,9.926,9.926,0,0,1-.3,2.42h-0.654a9.023,9.023,0,0,0,.212-1.915,9.4,9.4,0,0,0-18.642-1.568h-0.829A10.133,10.133,0,0,1,599.492,581.105Zm4.259,20.715h-8.76v0a12.268,12.268,0,0,0-12,12.192V619a0.994,0.994,0,0,0,1,.991H615A0.99,0.99,0,0,0,616,619v-4.981A12.263,12.263,0,0,0,603.751,601.82Zm10.254,16.185H609.1v-5.72a1,1,0,0,0-1.385-.953,0.995,0.995,0,0,0-.54.56,0.97,0.97,0,0,0-.066.383v5.73H591.877v-5.73a1,1,0,0,0-.292-0.7,1.027,1.027,0,0,0-1.408,0,1,1,0,0,0-.293.7v5.73h-4.9v-3.989A10.268,10.268,0,0,1,595.241,603.8h8.507a10.265,10.265,0,0,1,10.257,10.216v3.987Z" transform="translate(-574.5 -574.485)"/>
</svg>
  );
};

export const SvgThermorHelp = () => {
  return (
<svg  width="50" height="50" viewBox="0 0 50 50">
  <path fill="currentColor" d="M550.5,615.812a15.309,15.309,0,1,1,10.828-4.484A15.316,15.316,0,0,1,550.5,615.812Zm0,2.188a17.5,17.5,0,1,0-12.374-5.126A17.5,17.5,0,0,0,550.5,618Zm-6-22.343a0.521,0.521,0,0,0,.147.384,0.539,0.539,0,0,0,.174.117,0.525,0.525,0,0,0,.207.039h1.8a0.608,0.608,0,0,0,.582-0.547,2.732,2.732,0,0,1,2.936-2.48c1.5,0,2.874.75,2.874,2.555,0,1.389-.818,2.028-2.111,3-1.472,1.069-2.638,2.319-2.555,4.346l0.007,0.475a0.546,0.546,0,0,0,.547.538h1.774a0.548,0.548,0,0,0,.547-0.547v-0.229c0-1.571.6-2.028,2.209-3.251,1.332-1.013,2.721-2.137,2.721-4.5,0-3.3-2.791-4.9-5.847-4.9C547.739,590.656,544.7,591.947,544.5,595.657Zm3.406,12.606a2.236,2.236,0,1,0,2.208-2.056A2.061,2.061,0,0,0,547.9,608.263Z" transform="translate(-525.5 -575.5)"/>
</svg>
  );
};