import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../components/shared/button';
import Input from '../../../components/shared/inputs/input';
import Select from '../../../components/shared/inputs/select';
import Loader from '../../../components/shared/loader';
import Popup from '../../../components/shared/popup';
import { Text } from '../../../components/shared/text';
import { WarrantyInstallerFormFields, WarrantyType } from '../../../project/defines';
import { useGetClientsQuery } from '../../../store/auth/authApi';
import { useAppSelector } from '../../../store/hooks';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../utils/hooks/useTranslation';
import useYupValidationResolver from '../../../utils/hooks/useYupValidationResolver';
import ClientsDetail from '../../clients/detail';

const validationSchema = yup.object({
  // client_uuid: yup.string().required("input.validations.required"),
});

const InstallerForm: React.FC<{
  request: WarrantyRequest;
  setGetFormValues: Dispatch<SetStateAction<() => FieldValues>>;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
}> = ({ request, setGetFormValues, setIsFormValid }) => {
  const [isClientPopupOpen, setIsClientPopupOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const societe_uuid = useAppSelector((state) => state.auth.societe_uuid);
  const {
    data: clients,
    refetch,
    isLoading,
  } = useGetClientsQuery({ societe_uuid }, { skip: !societe_uuid || !request.garantie_type?.isMO });
  const show = (clients?.length as number) > 0 && request.garantie_type?.isMO;

  const resolver = useYupValidationResolver(validationSchema);
  let defaultValues = useMemo(() => {
    const result: FieldValues = {};
    Object.values(WarrantyInstallerFormFields).forEach((field: string) => {
      const f = field as keyof WarrantyRequest;
      result[f] = request[f] === null ? '' : request[f];
    });
    return result;
  }, [request]);

  const {
    control,
    trigger,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<FieldValues>({
    resolver,
    defaultValues,
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      trigger(name);
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    setGetFormValues(() => () => getValues());
  }, [getValues, setGetFormValues]);

  useEffect(() => {
    setIsFormValid(!show || isValid);
  }, [show, isValid, setIsFormValid]);

  return (
    <>
      {isLoading && <Loader />}
      {show || request.typedemande === WarrantyType.Retour ? (
        <view data-border="none" data-scroll="" data-space="5" data-auto-height="" data-max-length="300">
          {show && (
            <>
              <group data-space-vertical="10">
                <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
                  garantie.demande.etapes.3.form.coordonnees.installateur.label
                </Text>
              </group>
              <group
                data-direction="column"
                data-border=""
                data-radius-top="0"
                data-radius="10"
                data-space="20"
                data-background="highlight"                
              >
                <Select
                  allowSearch
                  allowClear
                  showArrow
                  label="garantie.demande.etapes.3.form.coordonnees.installateur.label"
                  dataLength="autofit"
                  size="large"
                  name={WarrantyInstallerFormFields.client_uuid}
                  placeholder={t('garantie.demande.etapes.3.form.coordonnees.installateur.placeholder')}
                  translateLabel
                  control={control}
                  options={
                    clients?.map((client) => ({
                      value: client.uuid,
                      text: `${client.nom ?? client.raison_sociale} ${client.prenom ?? ''}`,
                    })) ?? []
                  }
                  isGroup
                />
                <group data-space-vertical="10">
                  <Button
                    large
                    primary
                    title={t('garantie.demande.etapes.3.form.coordonnees.autreInstallateur')}
                    text="garantie.demande.etapes.3.form.coordonnees.autreInstallateur"
                    onClick={() => setIsClientPopupOpen(true)}
                  />
                </group>
              </group>
            </>
          )}
          {request.typedemande === WarrantyType.Retour && (
            <group
              data-direction="column"
              //data-border=""
              //data-radius-top="0"
              //data-radius="10"
              //data-space="20"
              //data-background="highlight"             
            >
              <Input
                label="garantie.demande.etapes.3.form.no_bon_retour_client"
                dataLength="autofit"
                size="large"
                aria-invalid="false"
                placeholder={t('garantie.demande.etapes.3.form.no_bon_retour_client')}
                translateLabel
                isGroup
                name={WarrantyInstallerFormFields.no_bon_retour_client}
                control={control}
                data-appearance="none"
              />
            </group>
          )}
          {isClientPopupOpen && (
            <Popup
              title="clients.formulaires.ajout_modification.ajoutLibelle"
              onClose={() => {
                setIsClientPopupOpen(false);
              }}
            >
              <ClientsDetail client={{}} setOpenDetail={setIsClientPopupOpen} isNew={true} refetch={refetch} />
            </Popup>
          )}
        </view>
      ) : (
        <></>
      )}
    </>
  );
};

export default InstallerForm;
