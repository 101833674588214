import { PropsWithChildren, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input, { InputType } from '../../components/shared/inputs/input';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useAddCollaborateurMutation, useUpdateCollaborateurMutation } from '../../store/auth/authApi';
import { AccessModel, CollaborateurModel } from '../../store/auth/authModels';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RegexpPatterns } from '../../utils/defines';
import { getKeyUpHandler } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import useYupValidationResolver from '../../utils/hooks/useYupValidationResolver';

const validationSchema = yup.object({
  nom: yup.string().required('input.validations.required'),
  prenom: yup.string().required('input.validations.required'),
  email: yup.string().required('input.validations.required').matches(RegexpPatterns.email, 'input.validations.email'),
  telephone: yup
    .string()
    .nullable()
    .matches(RegexpPatterns.phone, 'input.validations.phone')
    .transform((value) => (value ? value : null)),
});

const CollaborateurDetail: React.FC<
  PropsWithChildren<{
    collaborateur?: CollaborateurModel | { acces: [] };
    setOpenDetail: any;
    isNew: boolean;
    acces: AccessModel[];
    refetch: any;
    setNewlyAddedKey: any;
  }>
> = ({ collaborateur, setOpenDetail, isNew, acces, refetch, setNewlyAddedKey }) => {
  const { t } = useTranslation();
  const { auth, siteData } = useAppSelector((state) => state);
  const univers = siteData?.universInfo?.univers;

  const [collaborateurAcces, setCollaborateurAcces] = useState<any>(
    isNew
      ? acces?.filter((item) => item.default).map((item) => item.uuid)
      : collaborateur?.acces?.map((item) => item?.uuid)
  );
  const [triggerUpdate, { isLoading: isLoadingUpgate }] = useUpdateCollaborateurMutation();
  const [triggerAdd, { isLoading: isLoadingAdd }] = useAddCollaborateurMutation();
  const dispatch = useAppDispatch();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, watch, control, formState } = useForm<FieldValues>({ defaultValues: collaborateur, resolver });

  const onChange = (e: any, _acces: AccessModel) => {
    let newData: any;
    if (e.target.checked) {
      newData = [...collaborateurAcces, _acces.uuid];
      if (_acces.slug === 'commande') {
        const remiseUuid = acces.find((item) => item.slug === 'voir-les-prix-remisés')?.uuid;
        if (!collaborateurAcces.find((uuid: string) => uuid === remiseUuid)) {
          newData.push(remiseUuid);
        }
      }
    } else {
      newData = collaborateurAcces.filter((item: any) => item !== _acces.uuid);

      if (_acces.slug === 'voir-les-prix-remisés') {
        const commandeUuid = acces.find((item) => item.slug === 'commande')?.uuid;
        newData = newData.filter((item: string) => item !== commandeUuid);
      }
    }
    setCollaborateurAcces(newData);
  };
  return (
    <>
      {(isLoadingUpgate || isLoadingAdd) && <Loader />}

      <group data-scroll="" data-max-length="700" data-text="test">
        <group data-direction="column" data-border="" data-space="20">
          {/* <group data-direction="column" data-gap="5">
            <Text
              data-weight="700"
              data-accent=""
              data-text-size="large"
              data-wrap="wrap"
            >
              {isNew
                ? "societe.collaborateurs.formulaires.ajout_modification.ajoutLibelle"
                : "societe.collaborateurs.formulaires.ajout_modification.modificationLibelle"}
            </Text>
          </group>
          <space data-height="10"></space>
          <Separator horizontal/>
          <space data-height="10"></space> */}
          <group data-gap="10">
            <group data-direction="column" data-gap="10">
              <group data-gap="10" data-adaptive="600">
                <group data-length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.nom.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid={!!formState.errors.nom}
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.nom.placeholder')}
                    translateLabel
                    isGroup
                    name="nom"
                    control={control}
                  />
                </group>
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.prenom.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid={!!formState.errors.prenom}
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.prenom.placeholder')}
                    translateLabel
                    isGroup
                    name="prenom"
                    control={control}
                  />
                </group>
              </group>
              <group data-gap="10" data-adaptive="600">
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.email.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid={!!formState.errors.email}
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.email.placeholder')}
                    translateLabel
                    isGroup
                    name="email"
                    control={control}
                  />
                </group>
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.telephone.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.telephone.placeholder')}
                    translateLabel
                    isGroup
                    name="telephone"
                    data-appearance="none"
                    control={control}
                  />
                </group>
              </group>

              <group data-gap="10" data-adaptive="600">
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.fax.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.fax.placeholder')}
                    translateLabel
                    isGroup
                    name="fax"
                    control={control}
                  />
                </group>
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.adresse.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.adresse.placeholder')}
                    translateLabel
                    isGroup
                    name="adresse"
                    control={control}
                  />
                </group>
              </group>
              <group data-gap="10" data-adaptive="600">
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.adresseComplement.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t(
                      'societe.collaborateurs.formulaires.ajout_modification.adresseComplement.placeholder'
                    )}
                    translateLabel
                    isGroup
                    name="adresse_complement"
                    control={control}
                  />
                </group>
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.codePostal.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.codePostal.placeholder')}
                    translateLabel
                    isGroup
                    name="code_postal"
                    control={control}
                  />
                </group>
              </group>
            </group>

            <group data-direction="column" data-gap="10">
              <group data-gap="10" data-adaptive="600">
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.codePostal.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.codePostal.placeholder')}
                    translateLabel
                    isGroup
                    name="code_postal"
                    control={control}
                  />
                </group>
                <group data-Length="autofit" data-width="auto">
                  <Input
                    label="societe.collaborateurs.formulaires.ajout_modification.ville.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.ville.placeholder')}
                    translateLabel
                    isGroup
                    name="ville"
                    control={control}
                  />
                </group>
              </group>
              <Input
                label="profil.adresseLivraison.pays.libelle"
                size="large"
                aria-invalid="false"
                translateLabel
                isGroup
                name="pays"
                dataLength="auto"
                options={
                  siteData?.countries?.map((item) => ({
                    text: item.nom,
                    value: item.code,
                  })) ?? []
                }
                value={watch().pays || 'FR'}
                control={control}
                placeholder={t('profil.adresseLivraison.pays.libelle')}
                type={InputType.Select}
              />
            </group>

            <group>
              <space data-height="10"></space>
              <space data-height="10"></space>
              <group data-direction="column" data-gap="5">
                <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                  societe.collaborateurs.formulaires.ajout_modification.acces.libelle
                </Text>
              </group>
              <space data-height="10"></space>
              <Separator horizontal />
              <space data-height="10"></space>
              <group data-direction="column" data-gap="10" data-type="grid" data-grid-size="200">
                {collaborateurAcces &&
                  acces.map((item) => {
                    let checked = collaborateurAcces.indexOf(item.uuid) > -1;
                    return (
                      <Input
                        data-wrap="wrap"
                        key={item.uuid}
                        //label={item.libelle}
                        label={t('acces.' + item.slug)}
                        dataLength="autofit"
                        size="medium"
                        aria-invalid="false"
                        translateLabel
                        isGroup
                        name="acces"
                        type={InputType.Checkbox}
                        classic
                        checked={checked}
                        onChange={(e: any) => {
                          onChange(e, item);
                        }}
                      />
                    );
                  })}
              </group>
            </group>
          </group>
        </group>
      </group>
      <Separator horizontal data-margin="none" />
      <group data-type="grid" data-gap="10" data-space="20">
        <div
          className="button large primary"
          title={t('societe.collaborateurs.formulaires.boutons.validation.title')}
          onClick={handleSubmit(async (data: any) => {
            try {
              const result: any = isNew
                ? await triggerAdd({
                    ...data,
                    acces: collaborateurAcces,
                    societe_uuid: auth.societe_uuid,
                    univers_uuid: univers?.uuid,
                  })
                : await triggerUpdate({
                    ...data,
                    acces: collaborateurAcces,
                  });
              if (!result?.error) {
                setNewlyAddedKey?.(isNew ? result.data.uuid : '');
                refetch();
                dispatch(
                  addNotification({
                    type: 'success',
                    message: t(`global.notifications.collaborateur.${isNew ? 'createTitle' : 'updateTitle'}`),
                    description: t(`global.notifications.collaborateur.${isNew ? 'createMessage' : 'updateMessage'}`),
                    duration: 5,
                  })
                );
                setOpenDetail(false);
              } else {
                dispatch(
                  addNotification({
                    type: 'error',
                    message: 'Error',
                    description: result?.error.data ? result?.error.data.error : result?.error.error,
                    duration: 5,
                  })
                );
              }
            } catch (error: any) {
              ApiErrorHandler(error, dispatch, t, `global.notifications.collaborateur.collaborateurErrorTitle`);
            }
          })}
          onKeyUp={getKeyUpHandler()}
        >
          <Text>societe.collaborateurs.formulaires.boutons.validation.libelle</Text>
        </div>
        <div
          className="button large outline"
          title={t('societe.collaborateurs.formulaires.boutons.retour.title')}
          onClick={() => {
            setOpenDetail(false);
          }}
          onKeyUp={getKeyUpHandler()}
        >
          <Text>societe.collaborateurs.formulaires.boutons.retour.libelle</Text>
        </div>
      </group>
    </>
  );
};

export default CollaborateurDetail;
