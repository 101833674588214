import React from 'react';
import AccountMenu from '../../components/accountMenu';
import { getKeyUpHandler } from '../../utils/helpers';
import useOpener from '../../utils/hooks/useOpener';
const OpenizerExamples: React.FC = () => {
  const {
    toggle: toggleTop1,
    Wrapper: WrapperTop1,
    refOpener: refTop1,
    refWrapper: refWrapperTop1,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop2,
    Wrapper: WrapperTop2,
    refOpener: refTop2,
    refWrapper: refWrapperTop2,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop3,
    Wrapper: WrapperTop3,
    refOpener: refTop3,
    refWrapper: refWrapperTop3,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop4,
    Wrapper: WrapperTop4,
    refOpener: refTop4,
    refWrapper: refWrapperTop4,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop5,
    Wrapper: WrapperTop5,
    refOpener: refTop5,
    refWrapper: refWrapperTop5,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop6,
    Wrapper: WrapperTop6,
    refOpener: refTop6,
    refWrapper: refWrapperTop6,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop7,
    Wrapper: WrapperTop7,
    refOpener: refTop7,
    refWrapper: refWrapperTop7,
  } = useOpener('topLeft');
  const {
    toggle: toggleTop8,
    Wrapper: WrapperTop8,
    refOpener: refTop8,
    refWrapper: refWrapperTop8,
  } = useOpener('topLeft');

  return (
    <>
      <view data-space="20">
        <view data-border="no">
          <div
            className="button large outline"
            data-effect="material"
            style={{ position: 'absolute' }}
            onClick={toggleTop1}
            onKeyUp={getKeyUpHandler()}
            ref={refTop1 as any}
          >
            <text className="">Top Left</text>
          </div>

          <div
            className="button large outline"
            data-effect="material"
            style={{ bottom: 0, position: 'absolute' }}
            onClick={toggleTop2}
            onKeyUp={getKeyUpHandler()}
            ref={refTop2 as any}
          >
            <text className="">Bottom Left</text>
          </div>

          <div
            className="button large outline"
            data-effect="material"
            style={{ bottom: 0, left: '50%', transform: 'translateX(-50%)', position: 'absolute' }}
            onClick={toggleTop3}
            onKeyUp={getKeyUpHandler()}
            ref={refTop3 as any}
          >
            <text className="">Bottom Center</text>
          </div>
          <div
            className="button large outline"
            data-effect="material"
            style={{ bottom: 0, right: '0', position: 'absolute' }}
            onClick={toggleTop4}
            onKeyUp={getKeyUpHandler()}
            ref={refTop4 as any}
          >
            <text className="">Bottom Right</text>
          </div>
          <div
            className="button large outline"
            data-effect="material"
            style={{ top: '50%', transform: 'translateY(-50%)', left: '0', position: 'absolute' }}
            onClick={toggleTop5}
            onKeyUp={getKeyUpHandler()}
            ref={refTop5 as any}
          >
            <text className="">Left Center</text>
          </div>
          <div
            className="button large outline"
            data-effect="material"
            style={{ top: '50%', transform: 'translateY(-50%)', right: '0', position: 'absolute' }}
            onClick={toggleTop6}
            onKeyUp={getKeyUpHandler()}
            ref={refTop6 as any}
          >
            <text className="">Right Center</text>
          </div>
          <div
            className="button large outline"
            data-effect="material"
            style={{ top: '0', left: '50%', transform: 'translateX(-50%)', position: 'absolute' }}
            onClick={toggleTop7}
            onKeyUp={getKeyUpHandler()}
            ref={refTop7 as any}
          >
            <text className="">Top Center</text>
          </div>
          <div
            className="button large outline"
            data-effect="material"
            style={{ right: '0', position: 'absolute' }}
            onClick={toggleTop8}
            onKeyUp={getKeyUpHandler()}
            ref={refTop8 as any}
          >
            <text className="">Top Right</text>
          </div>
        </view>
      </view>

      <WrapperTop1>
        <AccountMenu ref={refWrapperTop1} />
      </WrapperTop1>
      <WrapperTop2>
        <AccountMenu ref={refWrapperTop2} />
      </WrapperTop2>
      <WrapperTop3>
        <AccountMenu ref={refWrapperTop3} />
      </WrapperTop3>
      <WrapperTop4>
        <AccountMenu ref={refWrapperTop4} />
      </WrapperTop4>
      <WrapperTop5>
        <AccountMenu ref={refWrapperTop5} />
      </WrapperTop5>
      <WrapperTop6>
        <AccountMenu ref={refWrapperTop6} />
      </WrapperTop6>
      <WrapperTop7>
        <AccountMenu ref={refWrapperTop7} />
      </WrapperTop7>
      <WrapperTop8>
        <AccountMenu ref={refWrapperTop8} />
      </WrapperTop8>
    </>
  );
};
export default OpenizerExamples;
