import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
    BrandComponent,
    useBrandComponent
} from '../brands/useBrandComponentHook';
import ScrollToTop from '../utils/helpers/scrollToTop';

const Layout: React.FC<PropsWithChildren<{hasBrandLayout?: boolean}>> = ({
  children,
  hasBrandLayout = true,
}) => {
  const [isMount, setIsMount] = useState(false);
  const BrandLayout = useBrandComponent(BrandComponent.Layout);

  useEffect(() => {
    setIsMount(true);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return isMount ? (
    <>
      <ScrollToTop />
      <BrandLayout simple={!hasBrandLayout}>
        <Outlet />
      </BrandLayout>
    </>
  ) : (
    <></>
  );
};

export default Layout;
