import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Button from '../../components/shared/button';
import { DatePicker } from '../../components/shared/inputs/datePicker';
import Input, { InputType, Password } from '../../components/shared/inputs/input';
import Select, { TreeSelect } from '../../components/shared/inputs/select';
import Separator from '../../components/shared/separator';

const InputComponents: React.FC = () => {
    const treeData = [
        {
            value: 'parent 11',
            title: 'parent 11',
            children: [
                {
                    value: 'parent 1-0',
                    title: 'parent 1-0',
                    children: [
                        {
                            value: 'leaf1',
                            title: 'leaf1',
                        },
                        {
                            value: 'leaf2',
                            title: 'leaf2',
                        },
                    ],
                },
                {
                    value: 'parent 1-1',
                    title: 'parent 1-1',
                    children: [
                        {
                            value: 'leaf3',
                            title: <b style={{ color: '#08c' }}>leaf3</b>,
                        },
                    ],
                },
            ],
        },
    ];

    const { register, handleSubmit, reset, control } = useForm<FieldValues>({
        defaultValues: {
            asd: '',
            input1: 'bbb',
            Notes: `In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.`,
        },
    });
    return (
        <view data-vertical="" data-adaptive="">
            <view data-space="30" data-gap="20">
                <group
                    data-border=""
                    data-radius="15"
                    data-space="30"
                    data-background="highlight"
                >
                    <group data-direction="column" data-gap="5">
                        <text
                            data-weight="700"
                            data-accent=""
                            data-text-size="large"
                            data-wrap="wrap"
                        >
                            General Information
                        </text>
                        <text data-light="" data-wrap="wrap">
                            Some info may be visible to other people using Our services.
                        </text>
                    </group>
                    <space data-height="20"></space>
                    <Separator horizontal/>
                    <space data-height="20"></space>
                    <group data-direction="column" data-gap="10">
                        <Input
                            dataLength="autofit"
                            disabled
                            label="Sample Disabled Input Component"
                            name="Disabled_input"
                            readOnly
                            value="This is Disabled"
                        />

                        <group data-gap="10">
                            <label
                                className="field"
                                data-label="left"
                                data-multi-element=""
                                data-length="autofit"
                            >
                                <div className="form_fields">
                                    <div className="field_cont">
                                        <text data-space="5">Lastname</text>
                                        <Separator vertical/>
                                        <input type="text" placeholder="Lastname" />
                                        <i></i>
                                    </div>
                                </div>
                            </label>
                            <label
                                className="field"
                                data-label="left"
                                data-multi-element=""
                                data-length="autofit"
                            >
                                <div className="form_fields">
                                    <div className="field_cont">
                                        <text data-space="5">Name</text>
                                        <Separator vertical/>
                                        <input type="text" placeholder="Name" />
                                        <i></i>
                                    </div>
                                </div>
                            </label>
                        </group>

                        <Separator horizontal/>

                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="field_cont">
                                    <icon>call</icon>
                                    <Separator vertical/>
                                    <input type="text" placeholder="Phone Number" />
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </group>

                    <space data-height="20"></space>
                    <Separator horizontal/>
                    <space data-height="20"></space>

                    <group data-type="grid" data-gap="10">
                        <div className="button large primary">
                            <text>Save Changes</text>
                        </div>
                        <div className="button large outline">
                            <text>Cancel</text>
                        </div>
                    </group>
                </group>

                <group
                    data-border=""
                    data-radius="15"
                    data-space="30"
                    data-background="highlight"
                >
                    <group data-direction="column" data-gap="10">
                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="data_label">Your Name</div>
                                <group data-gap="10">
                                    <div className="field_cont" data-fit="1.5">
                                        <input type="text" placeholder="Name" />
                                        <i></i>
                                    </div>
                                    <div className="field_cont" data-fit="2.5">
                                        <input type="text" placeholder="Surname" />
                                        <i></i>
                                    </div>
                                </group>
                            </div>
                        </label>

                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="data_label">
                                    <text>Sample group</text>
                                    <text data-weight="400" data-position="right">
                                        Optional
                                    </text>
                                </div>
                                <group data-gap="10">
                                    <div className="field_cont" data-fit="1.5">
                                        <input type="text" placeholder="Name" />
                                        <i></i>
                                    </div>
                                    <div className="field_cont" data-fit="2.5">
                                        <input type="text" placeholder="Surname" />
                                        <i></i>
                                    </div>
                                    <div className="field_cont" data-fit="3">
                                        <input type="text" placeholder="Surname" />
                                        <i></i>
                                    </div>
                                </group>
                            </div>
                        </label>
                    </group>
                </group>

                <group
                    data-border=""
                    data-radius="15"
                    data-space="30"
                    data-background="highlight"
                >
                    <group data-direction="column">
                        <text data-weight="700" data-text-size="large" data-wrap="wrap">
                            Password Change
                        </text>
                        <text data-light="" data-wrap="wrap">
                            Your password is Case Sensitive. It should contain a minimum of 8
                            characters and at least one each of Uppercase, Lowercase, and
                            Special characters
                        </text>
                    </group>
                    <space data-height="20"></space>
                    <Separator horizontal/>
                    <space data-height="20"></space>
                    <group data-direction="column" data-gap="10">
                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="field_cont">
                                    <text data-space="5">Old Password</text>
                                    <Separator vertical/>
                                    <input type="password" placeholder="" />
                                    <i></i>
                                </div>
                            </div>
                        </label>

                        <Separator horizontal/>

                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="field_cont">
                                    <text data-space="5">Create Password</text>
                                    <Separator vertical/>
                                    <input type="password" placeholder="" />
                                    <i></i>
                                </div>
                            </div>
                        </label>
                        <label
                            className="field"
                            data-label="left"
                            data-multi-element=""
                            data-length="autofit"
                        >
                            <div className="form_fields">
                                <div className="field_cont">
                                    <text data-space="5">Repeat Password</text>
                                    <Separator vertical/>
                                    <input type="password" placeholder="" />
                                    <i></i>
                                </div>
                            </div>
                        </label>
                    </group>

                    <space data-height="20"></space>
                    <Separator horizontal/>
                    <space data-height="20"></space>

                    <group data-type="grid" data-gap="10">
                        <div className="button large primary">
                            <text>Update Password</text>
                        </div>
                        <div className="button large ">
                            <text>Cancel</text>
                        </div>
                    </group>
                </group>
            </view>

            <view data-space="20" data-container="">
                <group direction="column" data-gap="20" data-align="start">




                    <group direction="column">
                        <group direction="row" data-gap="10" data-align="center">
                            <div className="field" data-length="auto">
                                <div className="form_fields">
                                    <div className="field_cont">
                                        <input
                                            type="text"
                                            placeholder="Family, a product, a reference"
                                            {...register('family')}
                                        />
                                        <i></i>
                                    </div>
                                </div>
                            </div>
                            <group
                                data-width="auto"
                                data-length="forcefit"
                                data-justify="center"
                                data-space="10"
                            >
                                <text data-weight="700">Or</text>
                            </group>
                            <div className="field" data-length="auto" data-multi-element="">
                                <div className="form_fields">
                                    <div className="field_cont">
                                        <text data-space="5">Serial No</text>
                                        <Separator vertical/>
                                        <input
                                            type="text"
                                            placeholder="Product Serial Number"
                                            {...register('serialNumber')}
                                        />
                                        <i></i>
                                    </div>
                                </div>
                            </div>
                            <div className="field" data-length="auto" data-multi-element="">
                                <div className="form_fields">
                                    <div className="field_cont">
                                        <text data-space="5">Product</text>
                                        <Separator vertical/>
                                        <input
                                            type="text"
                                            placeholder="Keywords ..."
                                            {...register('product')}
                                        />
                                        <text data-space="5">Piece</text>
                                        <Separator vertical/>
                                        <input
                                            type="text"
                                            placeholder="Keywords ..."
                                            {...register('price')}
                                        />
                                        <i></i>
                                    </div>
                                </div>
                            </div>
                            <Button
                                data-length="auto"
                                className="button primary mini large"
                            >
                                <icon>search</icon>
                            </Button>
                        </group>
                    </group>

                    <Separator horizontal/>



                    <group direction="" data-gap="20">
                        <div className="field" data-length="280">
                            <div className="form_fields validationError">
                                <div className="data_label">Search Here</div>
                                <div className="field_cont">
                                    <input type="text" placeholder="Placeholder" />
                                    <i></i>
                                </div>
                                <errormessage>
                                    <text data-wrap="wrap" className="errorMessage">
                                        Name is required
                                    </text>
                                </errormessage>
                            </div>
                        </div>
                        <space horizontal=""></space>

                        <label className="field" data-label="left" data-multi-element="">
                            <div className="form_fields">
                                <div className="data_label">Search Here</div>
                                <div className="field_cont">
                                    <text data-space="5">Label</text>
                                    <Separator vertical/>
                                    <input type="text" placeholder="Placeholder" />
                                    <Separator vertical/>
                                    <div className="button outline  micro">
                                        <icon>navigate_next</icon>
                                    </div>

                                    <div className="button primary adaptive">
                                        <icon>search</icon>
                                        <text>Search</text>
                                    </div>
                                    <i></i>
                                </div>
                            </div>
                        </label>

                        <label className="field" data-label="left" data-multi-element="">
                            <div className="form_fields">
                                <div className="data_label">Search Here</div>
                                <div className="field_cont">
                                    <icon>search</icon>
                                    <Separator vertical/>
                                    <input type="text" placeholder="Placeholder" />
                                    <div className="button primary">
                                        <text>Go</text>
                                    </div>
                                    <i></i>
                                </div>
                            </div>
                        </label>

                        <label className="field" data-label="left" data-multi-element="">
                            <div className="form_fields">
                                <div className="data_label">Search Here</div>
                                <div className="field_cont">
                                    <icon>folder</icon>
                                    <Separator vertical/>
                                    <input type="text" placeholder="Placeholder" />
                                    <Separator vertical/>
                                    <input type="text" placeholder="Placeholder" />
                                    <div className="button primary adaptive">
                                        <icon>add</icon>
                                        <text>Browse ...</text>
                                    </div>
                                    <i></i>
                                </div>
                            </div>
                        </label>

                        <Separator horizontal/>
                    </group>

                    <group data-gap="20">
                        <Input
                            label="Input"
                            dataLength="200"
                            size="medium"
                            aria-invalid="false"
                            {...register('input1')}
                        />
                        <Input
                            name="asd"
                            dataLength="200"
                            size="large"
                            label="Large Input component"
                        />
                        <Input
                            dataLength="280"
                            disabled
                            label="Sample Disabled Input Component"
                            name="Disabled_input"
                            readOnly
                            value="This is Disabled"
                        />
                    </group>

                    <label
                        className="field"
                        data-label="left"
                        data-multi-element=""
                        data-length="140"
                    >
                        <div className="form_fields">
                            <div className="field_cont">
                                <div className="button micro">
                                    <icon>remove</icon>
                                </div>
                                <Separator vertical/>
                                <input
                                    type="text"
                                    data-text-align="center"
                                    placeholder="0"
                                    value={0}
                                    readOnly
                                />
                                <Separator vertical/>
                                <div className="button micro">
                                    <icon>add</icon>
                                </div>
                                <i></i>
                            </div>
                        </div>
                    </label>

                    <group
                        data-direction="column"
                        data-gap="10"
                        data-border=""
                        data-radius="10"
                        data-space="10"
                        data-width="auto"
                    >
                        <Input
                            icon="mail"
                            dataLength="240"
                            placeholder="E-mail"
                            name="mail_input"
                        />

                        <Password
                            icon="lock"
                            placeholder="Password"
                            dataLength="240"
                            name="password_1"
                        />
                    </group>
                    <group>
                        <Input
                            label="Code"
                            placeholder="000 - 000 "
                            name="input_with_button"
                            button={<Button primary adaptive icon="barcode" text="Verify" />}
                        />
                    </group>

                    <group data-gap="20">
                        <Input
                            type={InputType.TextArea}
                            dataLength="410"
                            resize=""
                            placeholder="Notes"
                            aria-invalid="false"
                            {...register('Notes')}
                            defaultValue="In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly used to demonstrate the
                            visual form of a document or a typeface without
                            relying on meaningful content. In publishing and
                            graphic design, Lorem ipsum is a placeholder text
                            commonly used to demonstrate the visual form of a
                            document or a typeface without relying on meaningful
                            content."
                        />

                        <Input
                            dataLength="410"
                            type={InputType.TextArea}
                            name="textarea_error"
                            resize=""
                            placeholder="Type or paste a note"
                            invalid
                            error={{ message: 'error' }}
                        />
                    </group>
                </group>
            </view>

            <view data-size="small">
                <view data-space="20">
                    <group>
                        <group data-gap="20">
                            <Input
                                label="NumberInput"
                                control={control}
                                name="numberik"
                                placeholder="Select Number"
                                type={InputType.Number}
                                min={1}
                                max={10}
                            />
                            <Select
                                label="Person"
                                placeholder="Select a person"
                                name="dropdown"
                                dataLength="240"
                                allowClear
                                options={[
                                    'Wolverine',
                                    'Spider-Man',
                                    'Thor',
                                    'Iron Man',
                                    'Hulk',
                                    'Captain America',
                                    'Daredevil',
                                    'Punisher',
                                    { value: 1, text: 'Yondu Udonta' },
                                    { value: 'Star Lord' },
                                ]}
                            />

                            <Select
                                label="Hello"
                                placeholder="Select a person"
                                name="dropdown_multy"
                                showSearch
                                mode="multiple"
                                dataLength="320"
                                allowClear
                                options={[
                                    'Hulk',
                                    'Captain America',
                                    'Daredevil',
                                    'Punisher',
                                    { value: 1, text: 'Yondu Udonta' },
                                    { value: 'Star Lord' },
                                ]}
                            />

                            <DatePicker
                                allowClear
                                name="dt"
                                label="Birthday"
                                min='2023/04/10'
                                max='2023/04/15'
                                control={control}
                                placeholder="Select a Date"
                            />

                            <TreeSelect
                                name="treeSelect"
                                label="TreeSelect"
                                placeholder="Select a node"
                                treeData={treeData}
                            />
                        </group>

                        <space horizontal=""></space>
                    </group>
                </view>
                <toolbar data-space="20">
                    <group data-type="grid" data-gap="10">
                        <Button
                            primary
                            large
                            text="Submit"
                            onClick={handleSubmit((data: any) => console.log(data))}
                        />
                        <Button outline large text="Reset" onClick={() => reset()} />
                    </group>
                </toolbar>
            </view>
        </view>
    );
};
export default InputComponents;
