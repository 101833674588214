import { ColumnType } from 'antd/lib/table';
import { useState } from 'react';
import { checkAccess } from '../..//project/helpers';
import Button from '../../components/shared/button';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import Popup from '../../components/shared/popup';
import { useConfirmationPopup } from '../../components/shared/popup/confirmationPopup';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { SLUG_ACCESS_KEYS } from '../../project/defines';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useDeleteCollaborateurMutation, useGetAccesQuery, useGetCollaborateursQuery } from '../../store/auth/authApi';
import { AuthModel } from '../../store/auth/authModels';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTranslation from '../../utils/hooks/useTranslation';
import CollaborateurDetail from './detail';

const Collaborateur: React.FC = () => {
  const { auth } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [collaborateur, setCollaborateur] = useState<any>(null);
  const [newlyAddedKey, setNewlyAddedKey] = useState<string>('');

  const dispatch = useAppDispatch();
  const {
    data: collaborateurs,
    refetch,
    isFetching: isCollaborateursFetching,
  } = useGetCollaborateursQuery({ societe_uuid: auth?.societe?.uuid }, { skip: !auth?.societe?.uuid });
  const [trigger] = useDeleteCollaborateurMutation();
  const { data: acces } = useGetAccesQuery({});
  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });

  const { open, popup } = useConfirmationPopup();

  const deleteCollaborateur = async (record: any) => {
    try {
      setIsLoading(true);
      await trigger(record.uuid);
      refetch();
      setIsLoading(false);
      dispatch(
        addNotification({
          type: 'success',
          message: t('global.notifications.collaborateur.deleteTitle'),
          description: t('global.notifications.collaborateur.deleteMessage'),
          duration: 5,
        })
      );
    } catch (error: any) {
      ApiErrorHandler(error, dispatch, t, 'global.notifications.collaborateur.collaborateurErrorTitle');
    }
  };
  type TableRecord = Record<string, any>;
  const columns: ColumnType<TableRecord>[] = [
    {
      title: t('societe.collaborateurs.nom'),
      dataIndex: 'nom',
      sorter: true,
      width: 120,
    },
    {
      title: t('societe.collaborateurs.prenom'),
      dataIndex: 'prenom',
      sorter: true,
    },
    {
      title: t('societe.collaborateurs.mail'),
      dataIndex: 'email',
      sorter: true,
      width: 180,
    },
    {
      title: t('societe.collaborateurs.administrateur'),
      dataIndex: 'uuid',
      render: (value: string, record: Record<string, any>) => (
        <Text>
          {checkAccess(record as AuthModel, SLUG_ACCESS_KEYS.GestionCollaborateurs) ? 'global.oui' : 'global.non'}
        </Text>
      ),
      width: 120,
    },
    {
      dataIndex: 'commandButtons',
      width: 125,
      fixed: 'right',
      render: (value: string, record: Record<string, any>) =>
        checkAccess(auth, SLUG_ACCESS_KEYS.GestionCollaborateurs) && (
          <group data-wrap="no" data-gap="5">
            <Separator vertical />
            <Button
              mini
              icon="edit"
              onClick={() => {
                setOpenDetail(true);
                setCollaborateur(record);
                setIsNew(false);
              }}
            />
            <Separator vertical />
            <Button
              mini
              icon="delete"
              onClick={() =>
                open({
                  title: t('societe.collaborateurs.formulaires.suppression.suppressionLibelle'),
                  message: `${t('societe.collaborateurs.formulaires.suppression.texte')} ${record.prenom} ${
                    record.nom
                  }`,
                  positiveCallback: () => {
                    deleteCollaborateur(record);
                  },
                  positiveButtonText: t('societe.collaborateurs.formulaires.boutons.suppression.libelle'),
                  negativeButtonText: t('societe.collaborateurs.formulaires.boutons.retour.libelle'),
                  negativeCallBack: () => {},
                })
              }
            />
          </group>
        ),
    },
  ];

  return (
    <group data-direction="column">
      {popup}
      {openDetail && (
        <Popup
          title={
            isNew
              ? 'societe.collaborateurs.formulaires.ajout_modification.ajoutLibelle'
              : 'societe.collaborateurs.formulaires.ajout_modification.modificationLibelle'
          }
          onClose={() => {
            setOpenDetail(false);
          }}
        >
          <CollaborateurDetail
            collaborateur={collaborateur || { acces: [] }}
            setOpenDetail={setOpenDetail}
            isNew={isNew}
            acces={acces ?? []}
            refetch={refetch}
            setNewlyAddedKey={setNewlyAddedKey}
          />
        </Popup>
      )}
      {checkAccess(auth, SLUG_ACCESS_KEYS.GestionCollaborateurs) && (
        <>
          <group data-wrap="no" data-gap="10">
            <Button
              micro
              icon="add"
              primary
              onClick={() => {
                setOpenDetail(true);
                setCollaborateur({ acces: [] });
                setIsNew(true);
              }}
            />
          </group>
          <space data-height="10"></space>
        </>
      )}

      <Table
        view={ListViewType.Table}
        columns={columns}
        isLoading={isCollaborateursFetching || isLoading}
        dataSource={collaborateurs ?? []}
        pagination={false}
        setSorter={setSort}
        pagingProps={pagingProps.pagination}
        sort={sort}
        defaultSortField="nom"
        keyField="uuid"
        newlyAddedKey={newlyAddedKey}
        tableLayout="auto"
      />
      <PaginationToolbar {...pagingProps} total={collaborateurs ? collaborateurs.length : 0} />
    </group>
  );
};

export default Collaborateur;
