import { PropsWithChildren } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '../../components/shared/inputs/input';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useAddClientMutation, useUpdateClientMutation } from '../../store/auth/authApi';
import { ClientModel } from '../../store/auth/authModels';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RegexpPatterns } from '../../utils/defines';
import { getKeyUpHandler } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import useYupValidationResolver from '../../utils/hooks/useYupValidationResolver';

const validationSchema = yup.object({
  nom: yup.string().required('input.validations.required'),
  email: yup
    .string()
    .nullable()
    .matches(RegexpPatterns.email)
    .transform((value) => (value ? value : null)),
  telephone: yup
    .string()
    .nullable()
    .matches(RegexpPatterns.phone)
    .transform((value) => (value ? value : null)),
});

const ClientsDetail: React.FC<
  PropsWithChildren<{
    client?: ClientModel | {};
    setOpenDetail: any;
    isNew: boolean;
    refetch: any;
    setNewlyAddedKey?: any;
  }>
> = ({ client, setOpenDetail, isNew, refetch, setNewlyAddedKey }) => {
  const { t } = useTranslation();
  const { auth } = useAppSelector((state) => state);
  const [triggerUpdate, { isLoading: isLoadingUpgate }] = useUpdateClientMutation();
  const [triggerAdd, { isLoading: isLoadingAdd }] = useAddClientMutation();
  const dispatch = useAppDispatch();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control, formState } = useForm<FieldValues>({ defaultValues: client, resolver });

  return (
    <>
      {(isLoadingUpgate || isLoadingAdd) && <Loader />}

      <group data-scroll="">
        <group data-direction="column" data-border="" data-space="20">
          {/* <group data-direction="column" data-gap="5">
            <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
              {isNew
                ? 'clients.formulaires.ajout_modification.ajoutLibelle'
                : 'clients.formulaires.ajout_modification.modificationLibelle'}
            </Text>
          </group>
          <space data-height="10"></space>
          <Separator horizontal/>
          <space data-height="10"></space> */}
          <group data-gap="10">
            <group data-direction="column" data-gap="10" data-length="autofit">
              <Input
                label="clients.formulaires.ajout_modification.raisonSociale.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid={!!formState.errors.nom}
                placeholder={t('clients.formulaires.ajout_modification.raisonSociale.placeholder')}
                translateLabel
                isGroup
                name="raison_sociale"
                control={control}
              />

              <group data-gap="10">
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.nom.libelle"
                    dataLength="autofit"
                    size="medium"
                    placeholder={t('societe.collaborateurs.formulaires.ajout_modification.prenom.placeholder')}
                    translateLabel
                    isGroup
                    name="nom"
                    control={control}
                    invalid={formState.errors.nom}
                    error={formState.errors.nom}
                  />
                </group>
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.prenom.libelle"
                    dataLength="autofit"
                    size="medium"
                    placeholder={t('clients.formulaires.ajout_modification.prenom.placeholder')}
                    translateLabel
                    isGroup
                    name="prenom"
                    control={control}
                  />
                </group>
              </group>
              <group data-gap="10">
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.email.libelle"
                    dataLength="autofit"
                    size="medium"
                    placeholder={t('clients.formulaires.ajout_modification.email.placeholder')}
                    translateLabel
                    name="email"
                    isGroup
                    control={control}
                    aria-invalid={!!formState.errors.email}
                    error={
                      formState.errors.email ? { ...formState.errors.email, message: 'input.validations.email' } : null
                    }
                  />
                </group>
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.telephone.libelle"
                    dataLength="autofit"
                    size="medium"
                    placeholder={t('clients.formulaires.ajout_modification.telephone.placeholder')}
                    translateLabel
                    name="telephone"
                    isGroup
                    control={control}
                    aria-invalid={!!formState.errors.telephone}
                    error={
                      formState.errors.telephone
                        ? { ...formState.errors.telephone, message: 'input.validations.telephone' }
                        : null
                    }
                  />
                </group>
              </group>
            </group>
            <group data-direction="column" data-gap="10">
              <Input
                label="clients.formulaires.ajout_modification.adresse.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('clients.formulaires.ajout_modification.adresse.placeholder')}
                translateLabel
                isGroup
                name="adresse"
                control={control}
              />
              <Input
                label="clients.formulaires.ajout_modification.adresseComplement.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('clients.formulaires.ajout_modification.adresseComplement.placeholder')}
                translateLabel
                isGroup
                name="adresse_complement"
                control={control}
              />

              <group data-gap="10">
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.codePostal.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('clients.formulaires.ajout_modification.codePostal.placeholder')}
                    translateLabel
                    isGroup
                    name="code_postal"
                    control={control}
                  />
                </group>
                <group data-length="forcefit" data-fit="1">
                  <Input
                    label="clients.formulaires.ajout_modification.ville.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('clients.formulaires.ajout_modification.ville.libelle')}
                    translateLabel
                    isGroup
                    name="ville"
                    control={control}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
      <Separator horizontal data-margin="none" />
      <group data-type="grid" data-gap="10" data-space="10">
        <div
          className="button large primary"
          title={t('societe.collaborateurs.formulaires.boutons.validation.title')}
          onClick={handleSubmit(async (data: any) => {
            try {
              const result: any = isNew
                ? await triggerAdd({
                    ...data,
                    societe_uuid: auth.societe_uuid,
                  })
                : await triggerUpdate({
                    ...data,
                  });
              if (!result?.error) {
                setNewlyAddedKey?.(isNew ? result.data.uuid : '');
                refetch();
                dispatch(
                  addNotification({
                    type: 'success',
                    message: t(`global.notifications.client.${isNew ? 'createTitle' : 'updateTitle'}`),
                    description: t(`global.notifications.client.${isNew ? 'createMessage' : 'updateMessage'}`),
                    duration: 5,
                  })
                );
                setOpenDetail(false);
              } else {
                dispatch(
                  addNotification({
                    type: 'error',
                    message: 'global.notifications.client.clientErrorTitle',
                    description: result?.error.data ? result?.error.data.error : result?.error.error,
                    duration: 5,
                  })
                );
              }
            } catch (error: any) {
              ApiErrorHandler(error, dispatch, t, `global.notifications.collaborateur.collaborateurErrorTitle`);
            }
          })}
          onKeyUp={getKeyUpHandler()}
        >
          <Text>societe.collaborateurs.formulaires.boutons.validation.libelle</Text>
        </div>
        <div
          className="button large outline"
          title={t('societe.collaborateurs.formulaires.boutons.retour.title')}
          onClick={() => {
            setOpenDetail(false);
          }}
          onKeyUp={getKeyUpHandler()}
        >
          <Text>societe.collaborateurs.formulaires.boutons.retour.libelle</Text>
        </div>
      </group>
    </>
  );
};

export default ClientsDetail;
