import { SatcFamillesModel } from '../../store/satc/satcModels';
import Popup from '../../components/shared/popup';
import { useMemo, useState, useRef } from 'react';
import ListView from '../../components/shared/list/listView';
import { ListViewType } from '../../components/shared/list/useListHook';
import Separator from '../../components/shared/separator';

const TabPhotos: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { photos } = data;
  const [photoState, setPhotoState] = useState<{ show: boolean; title?: string; photoUrl?: string }>({ show: false });
  const photosData = useMemo(() => {
    const arr: any[] = [];
    if (Object.keys(photos).length) {
      Object.keys(photos).forEach((key: any) => {
        arr.push(photos[key]);
      });
    }
    return arr;
  }, [photos]);
  const refBtn = useRef(null);

  return (
    <group
      data-space="5"
      data-space-vertical="20"
      data-gap="20"
      
      direction="row"
      ref={refBtn}
    >
      {photoState.show && (
        <Popup
          windowContProps={{ "data-length": 700 }}
          title={photoState.title}
          refOpener={refBtn} 
          closeOnOutsideClick
          onClose={() => {
            setPhotoState({ show: false });
          }}
        >
          <group data-contain="">
            <picture data-object-fit="contain" data-space="10">
              <img data-radius="5" src={photoState.photoUrl} alt="" />
            </picture>
          </group>
        </Popup>
      )}
      <ListView
        keyField="uuid"
        data-template="150"
        view={ListViewType.Block}
        listProps={{ "data-gap": 15 }}
        dataSource={photosData}
        getItemElement={({ data }) => (
          <PhotoItem
            data={data}
            onClick={() => {
              setPhotoState({
                show: true,
                title: data.title,
                photoUrl: data.download,
              });
            }}
          />
        )}
      />
    </group>
  );
};

export default TabPhotos;

const PhotoItem: React.FC<{ data: any; onClick: () => void }> = ({ data, onClick }) => {
  const { title, download } = data;
  return (
    <group data-shrink="no" data-radius="5" data-contain="" data-border="" data-cursor="pointer" onClick={onClick}>
      <group data-gap="5" data-space="10" data-align="center">
        <text data-wrap="wrap" data-ellipsis="" >{title}</text>
      </group>
      <Separator horizontal data-margin="none" />
      <group data-ratio="1:1">
        <picture>
          <img src={download} alt="" />
        </picture>
      </group>
    </group>
  );
};
