import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, getUniversFromUrl } from '../project/helpers';
import { logout } from '../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import DarkModeToggle from './shared/darkModeToggle';
import { Text } from './shared/text';
import { APP_URL } from '../project/defines';
import { getKeyUpHandler } from '../utils/helpers';
interface AccountMenuProps {
  onClose?: () => void;
}

const AccountMenu: React.FC<AccountMenuProps> = React.forwardRef<HTMLDivElement, AccountMenuProps>(
  ({ onClose }, ref) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
    const lang = useAppSelector((state) => state.language.currentLanguage);

    return (
      <div className="menu_cont thermor_user_launcher" data-elevation="2" ref={ref}>
        <group data-space="10" data-background="main-background" data-max-length="300">
          <div className="list_menu">
            <ul>
              <li
                onClick={() => {
                  navigate(APP_URL.Profile);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonProfil.texte</Text>
              </li>
              <li className="separator"></li>
              <li
                onClick={() => {
                  navigate(APP_URL.Societe);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonSociete.texte</Text>
              </li>
              <li
                onClick={() => {
                  navigate(APP_URL.Clients);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonClients.texte</Text>
              </li>

              <li className="separator"></li>
              <DarkModeToggle />
              <li className="separator"></li>
              {/* <li className="separator"></li>
            <li>
              <icon></icon>
              <group data-gap="10" data-align="center">
                <text>Version</text>
                <Separator vertical/>
                <text light="">2.0.0</text>
              </group>
            </li> */}
              <li
                onClick={() => {
                  onClose?.();
                  dispatch(logout());
                  window.location.replace(
                    `${getApiUrl(getUniversFromUrl())}/logout?univers_uuid=${univers?.uuid}&lang=${lang}`
                  );
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonDeconnexion.texte</Text>
              </li>
            </ul>
          </div>
        </group>
      </div>
    );
  }
);

export default AccountMenu;
