// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

import { Article, ArticleRequestModel } from './articleModels';

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: baseQuery('articles'),
  endpoints: (builder) => {
    return {
      getArticle: builder.query<Article, ArticleRequestModel>({
        query: ({ univers_uuid, lang, pays_uuid, id, reseau_uuid }) => {
          return {
            url: id ?? '',
            method: 'get',
            params: {
              univers_uuid,
              lang,
              pays_uuid,
              reseau_uuid,
            },
          };
        },
      }),
      getUseCases: builder.query<any, ArticleRequestModel>({
        query: ({ univers_uuid, lang, pays_uuid, id, reseau_uuid }) => {
          return {
            url: `${id}/casutilisation`,
            method: 'get',
            params: reseau_uuid ? { univers_uuid, lang, pays_uuid, reseau_uuid } : { univers_uuid, lang, pays_uuid },
          };
        },
      }),
    };
  },
});

export const { useGetArticleQuery, useLazyGetArticleQuery, useGetUseCasesQuery } = articleApi;
