import { useRef, useState } from 'react';
import CGVPopupContent from '../../../components/CGVPopupContent';
import Popup from '../../../components/shared/popup';
import { Text } from '../../../components/shared/text';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import { getKeyUpHandler } from '../../../utils/helpers';

export const CGVLinkButton: React.FC<{ request: WarrantyRequest; }> = ({ request }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refBtn = useRef(null);

  return (
    <>
      <a /*eslint-disable-line*/ ref={refBtn} className="medium" data-link="" onClick={() => setIsOpen(true)} onKeyUp={getKeyUpHandler()}>
        <Text>garantie.demande.etapes.3.form.coordonnees.cgv</Text>
      </a>
      {isOpen && (
        <Popup closeOnOutsideClick refOpener={refBtn} onClose={() => setIsOpen(false)}>
          <CGVPopupContent reseau={request.reseau_uuid!} />
        </Popup>
      )}
    </>
  );
};

export default CGVLinkButton;
