import { useEffect, useState } from 'react';
import Input from '../../components/shared/inputs/input';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddArticleToCartMutation, useLazySearchArticleQuery } from '../../store/shoppingCart/shoppingCartApi';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import useOpener from '../../utils/hooks/useOpener';
import useTranslation from '../../utils/hooks/useTranslation';
import { addNotification } from '../../store/componentsSlice';
import Loader from '../../components/shared/loader';
import classNames from 'classnames';
import { setCartData } from '../../store/shoppingCart/shoppingCartSlice';
import { Text } from '../../components/shared/text';
import { FieldValues, useForm } from 'react-hook-form';
import useYupValidationResolver from '../../utils/hooks/useYupValidationResolver';
import * as yup from 'yup';

const validationSchema = yup.object({
  searchArticle: yup.string().matches(/^(\d*)$/g, { message: 'panier.articleSearch.errorOnlyNumbersAllowed' }),
});

const ArticleCartSearch: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchResult, setSearchResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);

  const { open, close, Wrapper, refOpener, refWrapper } = useOpener('bottom', true, false);

  const [triggerSearch] = useLazySearchArticleQuery();
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [addArticleToCart, addArticleResult] = useAddArticleToCartMutation();

  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm<FieldValues>({
    defaultValues: { searchArticle: '' },
    mode: 'onChange',
    resolver,
  });

  const handleResult = (result: any) => {
    if (result) {
      const { isFetching, isLoading, isSuccess, isError, error } = result;
      if (isSuccess && !isFetching && !isLoading) {
        setSearchResult(result);
        if (result.data.data.length === 1) {
          let singleItem = result.data.data[0];
          addArticleToCart({
            code_article: singleItem.code,
            reseau_uuid: singleItem.reseau_uuid,
            univers_uuid: univers?.uuid!,
          }).then(addArticleResponseHandler);
        }
      } else {
        setSearchResult(null);
        if (isError) {
          dispatch(
            addNotification({
              type: 'error',
              message: t('global.notifications.paniers.title'),
              description: error?.data?.error,
              duration: 5,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    searchResult?.data?.data ? open() : close();
  }, [searchResult]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isValid && close();
  }, [isValid]); //eslint-disable-line

  const addArticleResponseHandler = (response: any) => {
    if (response.error) {
      dispatch(
        addNotification({
          type: 'error',
          message: t('global.notifications.paniers.titleSingle'),
          description: t(response.error?.data?.error),
          duration: 5,
        })
      );
    } else {
      dispatch(setCartData(response.data));
      dispatch(
        addNotification({
          type: 'success',
          message: t('global.notifications.paniers.titleSingle'),
          description: t('global.notifications.paniers.created'),
          duration: 5,
        })
      );
    }
  };

  return (
    <group data-width="auto" ref={refOpener} data-position="right" data-length="forcefit">
      <group>
        <Input
          isGroup
          control={control}
          data-min-length="250"
          icon="search"
          data-appearance="none"
          validationMessageOnTop
          dataLength="320"
          name="searchArticle"
          maxLength={6}
          min={0}
          onKeyDown={async (e: any) => {
            close();
            if (e.key === 'Enter') {
              const isFormValid = await trigger();
              if (!isFormValid) {
                return;
              }
              let keywords = e.target.value;
              setIsLoading(true);
              const result = await triggerSearch({ keywords, lang, pays_uuid, univers: univers?.uuid! }, true);
              setIsLoading(false);
              handleResult(result);
            }
          }}
          onClick={() => {
            searchResult?.data?.data && open();
          }}
          placeholder={t('panier.panierEnCours.saisieCodeArticle')}
        />
        {(isLoading || addArticleResult.isLoading) && <Loader />}
      </group>

      <Wrapper>
        <group data-space="10" data-background="main-background" data-border="" ref={refWrapper} data-length="340">
          {searchResult && (
            <div className="list_view" data-direction="column">
              {searchResult.data?.data?.length === 0 ? (
                <div>
                  <Text light>recherche.noResultats</Text>
                </div>
              ) : (
                searchResult.data?.data?.map((item: any) => {
                  return (
                    <group
                      key={item.code}
                      {...(item.ajoutPanier ? { 'data-interactive': '' } : { 'data-light': '' })}
                      data-radius="5"
                      data-align="center"
                      data-space="10"
                      data-gap="5"
                      data-wrap="no"
                      onClick={() => {
                        if (item.ajoutPanier) {
                          addArticleToCart({
                            code_article: item.code,
                            reseau_uuid: item.reseau_uuid,
                            univers_uuid: univers?.uuid!,
                          }).then(addArticleResponseHandler);
                          close();
                        }
                      }}
                      title={item.ajoutPanier ? '' : t('global.notifications.paniers.unauthorizednetwork')}
                      className={classNames('item')}
                    >
                      <group data-direction="column" data-contain="" data-max-length="fit" data-gap="5">
                        <group data-gap="5">
                          <text data-weight="700">{`[${item.reference}]`}</text>
                          <text>{item.libelle}</text>
                        </group>
                        <text data-light="" data-wrap="wrap">
                          {item.articleReseau.reseau.nom}
                        </text>
                      </group>
                    </group>
                  );
                })
              )}
            </div>
          )}
        </group>
      </Wrapper>
    </group>
  );
};

export default ArticleCartSearch;
