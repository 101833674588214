import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Input, { InputType, Password } from '../../components/shared/inputs/input';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useUpdateLoggedUserMutation, useUpdateLoggedUserPasswordMutation } from '../../store/auth/authApi';
import { UserPasswordRequestModel } from '../../store/auth/authModels';
import { setUserData } from '../../store/auth/authSlice';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getKeyUpHandler } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';

const Profile: React.FC = () => {
  const { auth, siteData } = useAppSelector((state) => state);
  const [trigger] = useUpdateLoggedUserMutation();
  const [triggerUpdatePassword, { isLoading: isPassLoading }] = useUpdateLoggedUserPasswordMutation();
  const [isGeneralLoading, setIsGeneralLoading] = useState<boolean>(false);
  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleSubmit, watch, control } = useForm<FieldValues>({ defaultValues: auth });
  const [passwords, setPasswords] = useState<UserPasswordRequestModel>({
    current: '',
    new_password: '',
    new_password_confirmation: '',
    users_uuid: auth.uuid,
  });

  const passwordsChange = (e: any) => {
    setPasswords((item) => ({ ...item, users_uuid: auth.uuid, [e.target.name]: e.target.value }));
  };
  return (
    <section>
      <space data-height="20"></space>
      <wrapper>
        <group data-gap="20" data-align="start" data-grid-size="350" data-type="grid">
          <group
            data-direction="column"
            data-height="fit"
            data-border=""
            data-radius="10"
            data-space="20"
            data-background="highlight"
          >
            {isGeneralLoading && <Loader />}
            <group data-direction="column" data-gap="5">
              <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                profil.informationsGenerales.libelle
              </Text>
            </group>
            <space data-height="10"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <group data-direction="column" data-gap="10">
              <Input
                label="profil.informationsGenerales.identifiant.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.informationsGenerales.nom.placeholder')}
                translateLabel
                disabled
                isGroup
                name="email"
                control={control}
              />

              <Input
                label="profil.informationsGenerales.nom.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.informationsGenerales.nom.placeholder')}
                translateLabel
                disabled
                isGroup
                name="nom"
                control={control}
              />

              <Input
                label="profil.informationsGenerales.prenom.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.informationsGenerales.prenom.placeholder')}
                translateLabel
                disabled
                isGroup
                name="prenom"
                control={control}
              />
              <Input
                label="profil.informationsGenerales.telephone.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                translateLabel
                disabled
                isGroup
                name="telephone"
                control={control}
                data-appearance="none"
              />
              <Input
                label="profil.informationsGenerales.fax.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.informationsGenerales.fax.placeholder')}
                translateLabel
                isGroup
                name="fax"
                control={control}
              />
            </group>

            <space data-height="10" data-margin="bottom"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <group data-type="grid" data-gap="10">
              <div
                className="button large primary"
                title={t('profil.boutons.validerModifications.titre')}
                onClick={handleSubmit(async (data: any) => {
                  try {
                    setIsGeneralLoading(true);
                    const result = await trigger({
                      users_uuid: data.uuid,
                      societe_uuid: data.societe_uuid,
                      fax: data.fax,
                    });
                    setIsGeneralLoading(false);
                    dispatch(setUserData((result as any).data));
                    dispatch(
                      addNotification({
                        type: 'success',
                        message: t('global.notifications.profil.updateTitle'),
                        description: t('global.notifications.profil.updateMessage'),
                        duration: 5,
                      })
                    );
                  } catch (error: any) {
                    ApiErrorHandler(error, dispatch, t, 'global.notifications.profil.updateErrorTitle');
                  }
                })}
                onKeyUp={getKeyUpHandler()}
              >
                <Text>profil.boutons.validerModifications.texte</Text>
              </div>
            </group>
          </group>

          <group
            data-direction="column"
            data-height="fit"
            data-border=""
            data-radius="10"
            data-space="20"
            data-background="highlight"
          >
            {isAddressLoading && <Loader />}
            <group data-direction="column" data-gap="5">
              <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                profil.adresseLivraison.libelle
              </Text>
            </group>
            <space data-height="10"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <group data-direction="column" data-gap="10">
              <Input
                label="profil.adresseLivraison.checkbox.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                translateLabel
                isGroup
                name="livraison_idem_societe"
                control={control}
                type={InputType.Checkbox}
                classic
                checked={watch().livraison_idem_societe}
              />

              {!watch().livraison_idem_societe && (
                <>
                  <Separator horizontal />
                  <Input
                    label="profil.adresseLivraison.pays.libelle"
                    size="large"
                    aria-invalid="false"
                    translateLabel
                    isGroup
                    name="pays"
                    dataLength="auto"
                    options={
                      siteData?.countries?.map((item) => ({
                        text: item.nom,
                        value: item.code,
                      })) ?? []
                    }
                    value={watch().pays}
                    control={control}
                    placeholder={t('profil.adresseLivraison.pays.libelle')}
                    type={InputType.Select}
                  />
                  <Separator horizontal />
                  <Input
                    label="profil.adresseLivraison.adresse.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('profil.adresseLivraison.adresse.placeholder')}
                    translateLabel
                    isGroup
                    name="adresse"
                    control={control}
                  />

                  <Input
                    label="profil.adresseLivraison.adresseComplement.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('profil.adresseLivraison.adresseComplement.placeholder')}
                    translateLabel
                    isGroup
                    name="adresse_complement"
                    control={control}
                  />

                  <Input
                    label="profil.adresseLivraison.codePostal.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('profil.adresseLivraison.codePostal.placeholder')}
                    translateLabel
                    isGroup
                    name="code_postal"
                    control={control}
                  />

                  <Input
                    label="profil.adresseLivraison.ville.libelle"
                    dataLength="autofit"
                    size="medium"
                    aria-invalid="false"
                    placeholder={t('profil.adresseLivraison.ville.placeholder')}
                    translateLabel
                    isGroup
                    name="ville"
                    control={control}
                  />
                </>
              )}
            </group>
            <space data-height="10" data-margin="bottom"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <group data-type="grid" data-gap="10">
              <div
                className="button large primary"
                title={t('profil.boutons.validerModifications.titre')}
                onClick={handleSubmit(async (data: any) => {
                  try {
                    setIsAddressLoading(true);
                    const result = await trigger({
                      users_uuid: data.uuid,
                      societe_uuid: data.societe_uuid,
                      adresse: data.adresse,
                      adresse_complement: data.adresse_complement,
                      code_postal: data.code_postal,
                      livraison_idem_societe: data.livraison_idem_societe,
                      pays: data.pays,
                      ville: data.ville,
                    });
                    setIsAddressLoading(false);
                    dispatch(setUserData((result as any).data));
                    dispatch(
                      addNotification({
                        type: 'success',
                        message: t('global.notifications.profil.updateTitle'),
                        description: t('global.notifications.profil.updateMessage'),
                        duration: 5,
                      })
                    );
                  } catch (error: any) {
                    ApiErrorHandler(error, dispatch, t, 'global.notifications.profil.updateErrorTitle');
                  }
                })}
                onKeyUp={getKeyUpHandler()}
              >
                <Text>profil.boutons.validerModifications.texte</Text>
              </div>
            </group>
          </group>

          <group
            data-direction="column"
            data-height="fit"
            data-border=""
            data-radius="10"
            data-space="20"
            data-background="highlight"
          >
            {isPassLoading && <Loader />}
            <group data-direction="column" data-gap="5">
              <Text data-weight="700" data-accent="" data-text-size="large" data-wrap="wrap">
                profil.motDePasse.libelle
              </Text>
            </group>
            <space data-height="10"></space>
            <Separator horizontal />
            <space data-height="10"></space>
            <group data-direction="column" data-gap="10">
              <Password
                label="profil.motDePasse.actuel.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.motDePasse.actuel.placeholder')}
                translateLabel
                isGroup
                name="current"
                value={passwords.current}
                onChange={passwordsChange}
              />

              <Password
                label="profil.motDePasse.nouveau.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.motDePasse.nouveau.placeholder')}
                translateLabel
                isGroup
                name="new_password"
                value={passwords.new_password}
                onChange={passwordsChange}
              />

              <Password
                label="profil.motDePasse.confirmation.libelle"
                dataLength="autofit"
                size="medium"
                aria-invalid="false"
                placeholder={t('profil.motDePasse.confirmation.placeholder')}
                translateLabel
                isGroup
                name="new_password_confirmation"
                value={passwords.new_password_confirmation}
                onChange={passwordsChange}
              />
            </group>
            <space data-height="10" data-margin="bottom"></space>
            <Separator horizontal />
            <group data-type="grid" data-gap="10">
              <div
                className="button large primary"
                title={t('profil.boutons.validerModifications.titre')}
                onClick={async () => {
                  try {
                    const result: any = await triggerUpdatePassword(passwords).unwrap();
                    dispatch(setUserData(result));
                    dispatch(
                      addNotification({
                        type: 'success',
                        message: t('global.notifications.profil.updateTitle'),
                        description: t('global.notifications.profil.updatePassword'),
                        duration: 5,
                      })
                    );
                  } catch (error: any) {
                    ApiErrorHandler(error, dispatch, t, 'global.notifications.profil.updateErrorTitle');
                  }
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <Text>profil.boutons.validerModifications.texte</Text>
              </div>
            </group>
          </group>
        </group>
      </wrapper>
      <space data-height="20"></space>
    </section>
  );
};

export default Profile;
