export const SvgThermorForum = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <path
        fill="currentColor"
        d="M474.923,590.066a1.163,1.163,0,0,1,.338-0.82,1.149,1.149,0,0,1,.816-0.34h16.154a1.15,1.15,0,0,1,.816.34,1.164,1.164,0,0,1,0,1.64,1.15,1.15,0,0,1-.816.34H476.077a1.149,1.149,0,0,1-.816-0.34A1.163,1.163,0,0,1,474.923,590.066Zm18.462,5.8a1.163,1.163,0,0,0-.338-0.82,1.15,1.15,0,0,0-.816-0.34H476.077a1.149,1.149,0,0,0-.816.34,1.164,1.164,0,0,0,0,1.64,1.149,1.149,0,0,0,.816.34h16.154a1.15,1.15,0,0,0,.816-0.34A1.163,1.163,0,0,0,493.385,595.866Zm19.615,0v12.76a3.474,3.474,0,0,1-3.462,3.48h-1.153v4.64a1.156,1.156,0,0,1-1.3,1.151,1.146,1.146,0,0,1-.591-0.259l-6.6-5.532H488.769a3.456,3.456,0,0,1-2.446-1.02,3.493,3.493,0,0,1-1.015-2.46v-3.48h-4.085l-6.578,7.714a1.155,1.155,0,0,1-2.03-.754v-6.96h-1.153a3.458,3.458,0,0,1-2.447-1.02,3.494,3.494,0,0,1-1.015-2.46v-16.24a3.494,3.494,0,0,1,1.015-2.46,3.458,3.458,0,0,1,2.447-1.02h25.384a3.458,3.458,0,0,1,2.447,1.02,3.494,3.494,0,0,1,1.015,2.46v6.96h9.23a3.458,3.458,0,0,1,2.447,1.02A3.494,3.494,0,0,1,513,595.866Zm-16.154,6.96a1.149,1.149,0,0,0,.816-0.34,1.163,1.163,0,0,0,.338-0.82v-16.24a1.163,1.163,0,0,0-.338-0.82,1.149,1.149,0,0,0-.816-0.34H471.462a1.157,1.157,0,0,0-1.154,1.16v16.24a1.157,1.157,0,0,0,1.154,1.16h2.307a1.149,1.149,0,0,1,.816.34,1.162,1.162,0,0,1,.338.82v4.985l4.894-5.739a1.152,1.152,0,0,1,.875-0.406h16.154Zm13.846-6.96a1.157,1.157,0,0,0-1.154-1.16h-9.23v6.96a3.494,3.494,0,0,1-1.015,2.46,3.458,3.458,0,0,1-2.447,1.02h-9.231v3.48a1.164,1.164,0,0,0,.339.82,1.146,1.146,0,0,0,.815.34h11.539a1.151,1.151,0,0,1,.739.269l5.03,4.215v-3.324a1.162,1.162,0,0,1,.338-0.82,1.149,1.149,0,0,1,.816-0.34h2.307a1.157,1.157,0,0,0,1.154-1.16v-12.76Z"
        transform="translate(-465.5 -574.922)"
      />
    </svg>
  );
};

export const SvgAtlanticSolutions = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M42.179,46.2a0.5,0.5,0,0,1,.5-0.5h1.77a0.5,0.5,0,0,1,.5.5v1.771a0.5,0.5,0,0,1-.5.5H42.68a0.5,0.5,0,0,1-.5-0.5V46.2Zm1,1.27h0.768V46.7H43.181v0.768Zm-1,2.844a0.5,0.5,0,0,1,.5-0.5h1.77a0.5,0.5,0,0,1,.5.5v1.771a0.5,0.5,0,0,1-.5.5H42.68a0.5,0.5,0,0,1-.5-0.5V50.316Zm1,1.27h0.768V50.817H43.181v0.768Zm-0.5,6.453h1.77a0.5,0.5,0,0,1,.5.5V60.31a0.5,0.5,0,0,1-.5.5H42.68a0.5,0.5,0,0,1-.5-0.5V58.539A0.5,0.5,0,0,1,42.68,58.038Zm0.5,1.77h0.768V59.041H43.181v0.768Zm-1-5.38a0.5,0.5,0,0,1,.5-0.5h1.77a0.5,0.5,0,0,1,.5.5V56.2a0.5,0.5,0,0,1-.5.5H42.68a0.5,0.5,0,0,1-.5-0.5v-1.77Zm1,1.269h0.768V54.93H43.181V55.7Zm4.314,7.3H39.928a0.5,0.5,0,0,1,0-1h7.065V44H36.237v6.444a0.5,0.5,0,1,1-1,0V43.5a0.5,0.5,0,0,1,.5-0.5H47.495a0.5,0.5,0,0,1,.5.5V62.493A0.5,0.5,0,0,1,47.495,62.994Zm-7.06-5.748a0.5,0.5,0,0,1-.707.061l-1-.846v6.033a0.5,0.5,0,0,1-.5.5H30.389a0.5,0.5,0,0,1-.5-0.5V56.463l-1,.844a0.5,0.5,0,1,1-.645-0.768L33.986,51.7a0.5,0.5,0,0,1,.646,0l5.742,4.836A0.5,0.5,0,0,1,40.435,57.246Zm-6.841,4.746h1.64V58.124h-1.64v3.867Zm4.127-6.375-3.413-2.875L30.89,55.619v6.373h1.7V57.623a0.5,0.5,0,0,1,.5-0.5h2.642a0.5,0.5,0,0,1,.5.5v4.369h1.485V55.616Zm3.331-.3a0.5,0.5,0,1,1-1,0V54.934H39.3a0.5,0.5,0,0,1,0-1h1.25a0.5,0.5,0,0,1,.5.5v0.885Zm-0.5-6.843h-1.77a0.5,0.5,0,0,1-.5-0.5V46.2a0.5,0.5,0,0,1,.5-0.5h1.77a0.5,0.5,0,0,1,.5.5v1.771A0.5,0.5,0,0,1,40.551,48.475ZM40.05,46.7H39.283v0.768H40.05V46.7Zm1,5.383a0.5,0.5,0,0,1-.5.5h-1.77a0.5,0.5,0,0,1-.5-0.5V50.316a0.5,0.5,0,0,1,.5-0.5h1.77a0.5,0.5,0,0,1,.5.5v1.771Zm-1-1.27H39.283v0.768H40.05V50.817Zm-0.29,8.228a0.5,0.5,0,0,1,0-1h0.79a0.5,0.5,0,0,1,.5.5v1.77a0.5,0.5,0,0,1-.5.5H39.76a0.5,0.5,0,0,1,0-1h0.289V59.045H39.76Z"
        transform="translate(-23.031 -38)"
      />
    </svg>
  );
};

export const SvgAtlanticCommandes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M47.487,107H28.425a0.487,0.487,0,0,1-.487-0.488V83.455a0.488,0.488,0,0,1,.487-0.488H47.487a0.488,0.488,0,0,1,.487.488v23.055A0.487,0.487,0,0,1,47.487,107ZM47,83.943H28.912v22.078H47V83.943Zm-15.647,2.4H44.558a0.488,0.488,0,0,1,0,.977H31.352A0.488,0.488,0,0,1,31.352,86.345Zm0,3.463H44.558a0.488,0.488,0,0,1,0,.977H31.352A0.488,0.488,0,0,1,31.352,89.808Zm0,3.463H44.558a0.488,0.488,0,0,1,0,.977H31.352A0.488,0.488,0,0,1,31.352,93.271Zm0.13,5.99h0.6a2.758,2.758,0,0,1,.911-1.52,2.451,2.451,0,0,1,1.6-.548h0a5.317,5.317,0,0,1,1.452.223l-0.173.977a3.392,3.392,0,0,0-1.238-.244,1.42,1.42,0,0,0-.891.266,1.728,1.728,0,0,0-.545.846h2.385l-0.13.689H33.066a1.127,1.127,0,0,0-.022.253l0.022,0.257h2.308l-0.126.682H33.186a1.632,1.632,0,0,0,.517.836,1.327,1.327,0,0,0,.868.291,2.722,2.722,0,0,0,1.411-.484v1.038a3.1,3.1,0,0,1-.774.316,3.267,3.267,0,0,1-.75.088,2.267,2.267,0,0,1-1.553-.551,2.777,2.777,0,0,1-.843-1.531H31.352l0.13-.684h0.5a0.962,0.962,0,0,1-.013-0.215,2.432,2.432,0,0,1,.021-0.3H31.352Z"
        transform="translate(-22.953 -79.984)"
      />
    </svg>
  );
};

export const SvgAtlanticDarwin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M88.681,104.978H77.318A2.329,2.329,0,0,1,75,102.641V85.34A2.33,2.33,0,0,1,77.318,83H88.681A2.329,2.329,0,0,1,91,85.34v17.3A2.329,2.329,0,0,1,88.681,104.978ZM89.994,85.34a1.321,1.321,0,0,0-1.314-1.326H77.318A1.322,1.322,0,0,0,76,85.34v17.3a1.322,1.322,0,0,0,1.314,1.326H88.681a1.321,1.321,0,0,0,1.314-1.326V85.34ZM87.926,97a0.785,0.785,0,0,1-.51.787,3.223,3.223,0,0,1-1.2.215c-0.2.005-.4,0-0.629,0-0.007-.1-0.012-0.188-0.018-0.266a9.3,9.3,0,0,1-1.1.266,6.925,6.925,0,0,1-4.936-1.012A3.33,3.33,0,0,1,78.1,94.48a4.635,4.635,0,0,1,.132-1.841,3.319,3.319,0,0,1,1.945-2.1,7.039,7.039,0,0,1,2.406-.586,16.977,16.977,0,0,1,1.938-.04,6.119,6.119,0,0,1,2.29.47,1.688,1.688,0,0,1,1.1,1.288,1.567,1.567,0,0,1,.018.256H87.926C87.926,93.616,87.919,95.308,87.926,97Zm-2.507-3.054V92.28a0.433,0.433,0,0,0-.435-0.553,5.516,5.516,0,0,0-2.958.151,1.968,1.968,0,0,0-1.442,2.116,2.123,2.123,0,0,0,1.435,2.058,4.6,4.6,0,0,0,1.785.283,3.365,3.365,0,0,0,1.453-.354h0a0.261,0.261,0,0,0,.164-0.274C85.414,95.119,85.419,94.531,85.419,93.944Z"
        transform="translate(-68 -78.984)"
      />
    </svg>
  );
};

export const SvgAtlanticFormation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M225.457,59.535H223.2l0.5,1.013a0.6,0.6,0,0,1-.346.829l-1.71.576-0.579,1.7a0.6,0.6,0,0,1-.329.354h-0.463c-0.013-.005-0.029,0-0.042-0.01l-1.619-.793L216.994,64c-0.012.006-.026,0-0.039,0.01H216.49a0.592,0.592,0,0,1-.33-0.354l-0.58-1.7-1.708-.576a0.6,0.6,0,0,1-.362-0.337,0.589,0.589,0,0,1,.016-0.492l0.5-1.013H203.551A0.6,0.6,0,0,1,203,59.174V43.307A0.6,0.6,0,0,1,203.314,43h22.379a0.6,0.6,0,0,1,.307.3V59.185A0.6,0.6,0,0,1,225.457,59.535Zm-9.924-.332-0.627,1.263,1.341,0.452a0.6,0.6,0,0,1,.376.374l0.454,1.334L218.348,62a0.607,0.607,0,0,1,.531,0l1.27,0.622,0.454-1.334a0.6,0.6,0,0,1,.376-0.374l1.341-.452L221.693,59.2a0.592,0.592,0,0,1,0-.528l0.628-1.263-1.342-.453a0.6,0.6,0,0,1-.376-0.374l-0.454-1.334-1.27.624a0.607,0.607,0,0,1-.531,0l-1.271-.624-0.454,1.334a0.6,0.6,0,0,1-.376.374l-1.341.453,0.627,1.263A0.592,0.592,0,0,1,215.533,59.2Zm9.324-15.063H204.152v14.2h9.877l-0.5-1.013a0.589,0.589,0,0,1-.016-0.492,0.6,0.6,0,0,1,.361-0.337l1.709-.576,0.58-1.7a0.6,0.6,0,0,1,.834-0.344l1.619,0.8,1.619-.8a0.6,0.6,0,0,1,.834.344l0.579,1.7,1.71,0.576a0.6,0.6,0,0,1,.346.829l-0.5,1.013h1.66V44.14Zm-2.03,4.413H206.146a0.6,0.6,0,1,1,0-1.194h16.681A0.6,0.6,0,1,1,222.827,48.552Zm-16.681,2.09H214.5a0.6,0.6,0,1,1,0,1.193h-8.354A0.6,0.6,0,1,1,206.146,50.642Z"
        transform="translate(-199.5 -38.5)"
      />
    </svg>
  );
};

export const SvgAtlanticLogiciels = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M190.718,58.991h-2.284V43H193V58.991h-2.281ZM192,44.01h-2.563v0.866h0.747a0.5,0.5,0,0,1,0,1.006h-0.747v0.868h1.283a0.5,0.5,0,0,1,0,1.006h-1.283v0.866h0.747a0.5,0.5,0,0,1,0,1.006h-0.747v0.866h1.283a0.5,0.5,0,0,1,0,1.006h-1.283v0.867h0.747a0.5,0.5,0,0,1,0,1.006h-0.747V54.24h1.283a0.5,0.5,0,0,1,0,1.006h-1.283v0.867h0.747a0.5,0.5,0,0,1,0,1.006h-0.747v0.867H192V44.01ZM186.49,58.991h-9.145A1.347,1.347,0,0,1,176,57.642V44.352A1.347,1.347,0,0,1,177.345,43h9.146a1.348,1.348,0,0,1,1.344,1.349V57.641A1.349,1.349,0,0,1,186.49,58.991Zm0.343-14.639a0.342,0.342,0,0,0-.342-0.343h-9.146a0.342,0.342,0,0,0-.342.343v13.29a0.343,0.343,0,0,0,.342.343h9.145a0.343,0.343,0,0,0,.343-0.344V44.352Zm-2.109,4.566h-6.049a0.984,0.984,0,0,1-.981-0.986V45.406a0.984,0.984,0,0,1,.981-0.986h6.049a0.985,0.985,0,0,1,.982.986v2.526A0.985,0.985,0,0,1,184.724,48.918Zm-0.02-3.512-6.008.02v2.506l6.028-.02ZM178.7,49.393h1.82a0.984,0.984,0,0,1,.981.986v1.829a0.984,0.984,0,0,1-.981.985H178.7a0.984,0.984,0,0,1-.981-0.985V50.379A0.984,0.984,0,0,1,178.7,49.393Zm0.021,2.815,1.8-.021-0.02-1.808-1.779.02v1.808ZM178.7,53.667h1.82a0.985,0.985,0,0,1,.981.986v1.828a0.984,0.984,0,0,1-.981.986H178.7a0.984,0.984,0,0,1-.981-0.986V54.653A0.985,0.985,0,0,1,178.7,53.667Zm0.021,2.815,1.8-.021-0.02-1.808-1.779.02v1.809Zm4.186-7.088h1.819a0.985,0.985,0,0,1,.982.986v1.829a0.985,0.985,0,0,1-.982.985H182.9a0.985,0.985,0,0,1-.982-0.985V50.379A0.985,0.985,0,0,1,182.9,49.393Zm0.02,2.815,1.8-.021-0.02-1.808-1.779.02v1.808Zm-0.02,1.459h1.819a0.985,0.985,0,0,1,.982.986v1.828a0.985,0.985,0,0,1-.982.986H182.9a0.985,0.985,0,0,1-.982-0.986V54.653A0.985,0.985,0,0,1,182.9,53.667Zm0.02,2.815,1.8-.021-0.02-1.808-1.779.02v1.809Z"
        transform="translate(-169.5 -36)"
      />
    </svg>
  );
};

export const SvgAtlanticPanier = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M184.85,153.342l1.921-9.423H167.849l-0.658-3.138-5.7-2.8-0.494.99L166.2,141.5l2.628,12.642h15.2s1.5,0.948.921,2.144a1.639,1.639,0,0,1-1.373.826H168.454v1.091h15.135a2.727,2.727,0,0,0,2.415-1.43C186.973,154.953,184.85,153.342,184.85,153.342Zm-15.081-.305-1.7-8.026h17.386l-1.648,8.026h-14.04Zm1.81,6.708a2.086,2.086,0,1,0,2.084,2.085,2.079,2.079,0,0,0-2.084-2.085h0Zm0,3.076a0.99,0.99,0,1,1,.989-0.99,0.986,0.986,0,0,1-.989.99h0m11.189-3.076a2.089,2.089,0,0,0-.01,4.177h0.01a2.046,2.046,0,0,0,2.083-2.009c0-.025,0-0.051,0-0.077a2.086,2.086,0,0,0-2.082-2.091h0m0.989,2.091a0.989,0.989,0,1,1-.994-0.989h0a0.951,0.951,0,0,1,.988.914c0,0.025,0,.05,0,0.075"
        transform="translate(-158.891 -135.938)"
      />
    </svg>
  );
};

export const SvgAtlanticProduit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M165.994,47.451a3.574,3.574,0,0,0-2.382-3.4,12.643,12.643,0,0,0-4.87-1.034,34.432,34.432,0,0,0-4.121.086,14.539,14.539,0,0,0-5.115,1.289,7.22,7.22,0,0,0-4.126,4.625,10.6,10.6,0,0,0-.281,4.055,7.391,7.391,0,0,0,3.032,5.525c3.179,2.268,6.77,2.688,10.5,2.232a19.1,19.1,0,0,0,2.346-.585c0.013,0.171.024,0.37,0.04,0.585,0.477,0,.906.014,1.335,0a6.627,6.627,0,0,0,2.572-.476,1.728,1.728,0,0,0,1.089-1.734C165.994,54.9,166,51.18,166,47.458m-5.679,8.925a6.963,6.963,0,0,1-3.09.78,9.512,9.512,0,0,1-3.8-.62A4.664,4.664,0,0,1,150.367,52a4.33,4.33,0,0,1,3.077-4.66,11.351,11.351,0,0,1,6.29-.332,0.944,0.944,0,0,1,.923,1.219v3.661c0,1.293-.012,2.584.007,3.88a0.576,0.576,0,0,1-.349.6"
        transform="translate(-140.5 -37)"
      />
    </svg>
  );
};

export const SvgAtlanticReglement = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M121.972,63a11.015,11.015,0,1,1,11.014-11.014A11.028,11.028,0,0,1,121.972,63Zm0-20.846a9.832,9.832,0,1,0,9.832,9.832A9.843,9.843,0,0,0,121.972,42.153Zm2.612,13.026a4.159,4.159,0,0,0,.55-0.552,0.591,0.591,0,1,1,.912.753,5.333,5.333,0,0,1-8.211,0,5.239,5.239,0,0,1-.879-1.59h-2.448a0.591,0.591,0,0,1,0-1.182h2.163a5.393,5.393,0,0,1-.016-1.2h-1.423a0.591,0.591,0,0,1,0-1.183h1.7a5.309,5.309,0,0,1,9.11-1.632,0.591,0.591,0,1,1-.911.754,4.12,4.12,0,0,0-6.931.879H121.4a0.591,0.591,0,0,1,0,1.183h-3.545a4.23,4.23,0,0,0,.022,1.2h2.8a0.591,0.591,0,0,1,0,1.182h-2.451a4.082,4.082,0,0,0,.525.836A4.152,4.152,0,0,0,124.584,55.179Z"
        transform="translate(-106.984 -36.984)"
      />
    </svg>
  );
};

export const SvgAtlanticServices = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 30 30">
      <path
        fill="currentcolor"
        d="M90.627,48.763l-4.19,2.38a2,2,0,0,1,.172.809v0.319a1.992,1.992,0,0,1-.139.717c-0.005.013-.008,0.027-0.013,0.041a2.032,2.032,0,0,1-.378.592l-0.02.024a2.072,2.072,0,0,1-.582.435h0a2.071,2.071,0,0,1-.731.211h0v0.036a2.446,2.446,0,0,1-2.036,2.394,2.46,2.46,0,0,1-2.459,2.192H76.62l-3.553,2.018a0.513,0.513,0,0,1-.695-0.183,0.5,0.5,0,0,1,.186-0.683l3.789-2.153h3.9a1.429,1.429,0,0,0,1.4-1.153h-0.55a0.5,0.5,0,1,1,0-1h1.115a1.46,1.46,0,0,0,1.515-1.432V54.313H82.742a0.5,0.5,0,1,1,0-1h1.791a1.051,1.051,0,0,0,1.058-1.041V51.952a1.051,1.051,0,0,0-1.058-1.041H79.7l-0.62,1.656a2.28,2.28,0,0,1-2.912,1.317A2.236,2.236,0,0,1,74.9,52.725a2.18,2.18,0,0,1-.066-1.706l0.484-1.287-1.137.646a1.405,1.405,0,0,0-.715,1.125l-0.006,2.121h0v0.287l-4.635,2.634a0.513,0.513,0,0,1-.695-0.183,0.5,0.5,0,0,1,.186-0.683l4.129-2.346,0.006-1.866a2.413,2.413,0,0,1,1.223-1.956L75.811,48.3a0.488,0.488,0,0,1,.054-0.018l0.159-.422a2.245,2.245,0,0,1,.969-1.143l1.856-1.055a2.614,2.614,0,0,1,1.257-.344l3.285,0.061,3.939-2.237a0.512,0.512,0,0,1,.694.183,0.5,0.5,0,0,1-.186.684l-4.062,2.306h0l-0.122.069-0.14,0h0l-3.427-.063a1.3,1.3,0,0,0-.649.166l-1.93,1.1a1.235,1.235,0,0,0-.529.627l-1.191,3.163a1.205,1.205,0,0,0,.036.94,1.234,1.234,0,0,0,.7.639,1.257,1.257,0,0,0,1.605-.726l0.742-1.982h0l0.122-.327h5.54a2.075,2.075,0,0,1,1.268.438L90.118,47.9a0.512,0.512,0,0,1,.694.183A0.5,0.5,0,0,1,90.627,48.763Z"
        transform="translate(-64.484 -37.031)"
      />
    </svg>
  );
};
