import { createSlice } from '@reduxjs/toolkit';
import { AuthModel } from './authModels';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../project/defines';

const initialState: AuthModel = {
  acces: [],
  active: false,
  admin: false,
  adresse: '',
  adresse_complement: '',
  code_postal: '',
  collaborateur: false,
  created_at: '',
  culture: '',
  email: '',
  fax: '',
  gestionnaire: false,
  hasDoublonEmail: false,
  has_access_demande_garantie: false,
  livraison_idem_societe: false,
  login: '',
  nom: '',
  pays: '',
  pays_uuid: '',
  pfs_admin_user: false,
  prenom: '',
  roles: [],
  session_code: '',
  societe: null,
  societe_nature_codes: null,
  societe_uuid: '',
  telephone: '',
  updated_at: '',
  uuid: '',
  ville: '',
  wso_accessToken: '',
  wso_expires: 0,
  wso_refreshToken: '',
  external_id: undefined,
  isLoggedIn: false,
};

const setData = (target: any, source: any, reset?: boolean) => {
  Object.keys(initialState).forEach((k: string) => {
    if (source[k]) {
      target[k] = source[k];
    } else if (reset) {
      target[k] = undefined;
    }
  });
};

const loadStateFromStorage = (state: any) => {
  const auth = getLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
  const newState = auth ? JSON.parse(auth) : { ...initialState };
  setData(state, newState, true);
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reloadIsLoggedInFromStorage(state) {
      loadStateFromStorage(state);
    },
    setUserData(state, payload) {
      setData(state, payload.payload, true);
      setLocalStorageItem(LOCAL_STORAGE_KEYS.Auth, JSON.stringify(payload.payload));
    },
    clearUserData(state) {
      setData(state, {}, true);
    },
  },
});

export const { setUserData, clearUserData, reloadIsLoggedInFromStorage } = authSlice.actions;
export default authSlice.reducer;
