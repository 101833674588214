import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useMemo, useState } from 'react';
import Button from '../../components/shared/button';
import Input from '../../components/shared/inputs/input';
import Radio, { RadioType } from '../../components/shared/inputs/radio';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table, { ColumnType } from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import OptionBar from '../../components/shared/optionBar';
import { useConfirmationPopup } from '../../components/shared/popup/confirmationPopup';
import { Text } from '../../components/shared/text';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useDeleteWarrantyMutation, useGetWarrantyHistoryQuery } from '../../store/warrantyHistory/warrantyHistoryApi';
import { formatDate } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import Popup from '../../components/shared/popup';
import WarrantyHistoryDetail from './detail';
import Separator from '../../components/shared/separator';
import { DEFAULT_DATE_FORMAT } from '../../project/defines';

const WarrantyHistory: React.FC = () => {
  const { auth } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const [archive, setArchive] = useState(false);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const [filter, setFilter] = useState('');
  const [warrantyHistory, setWarrantyHistory] = useState<any>(null);
  const { data, isFetching } = useGetWarrantyHistoryQuery(
    {
      societe_uuid: auth?.societe?.uuid ?? '',
      univers_uuid: univers?.uuid ?? '',
      lang,
      archive,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [trigger] = useDeleteWarrantyMutation();

  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const { open, popup } = useConfirmationPopup();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const columns: ColumnType[] = [
    {
      key: 'reference',
      title: t('commandes.monHistoriqueCommandes.reference'),
      dataIndex: 'reference',
      sorter: true,
      searchable: true,
      width: 120,
    },
    {
      key: 'text_etat',
      title: t('garantie.historique.datatable.etat'),
      dataIndex: 'text_etat',
      //width: 200,
      sorter: true,
      searchable: true,
    },
    {
      key: 'installateur.raison_sociale',
      title: t('garantie.historique.datatable.installateur'),
      dataIndex: 'installateur.raison_sociale',
      //width: 200,
      sorter: true,
    },
    {
      key: 'reference_commande',
      title: t('garantie.historique.datatable.dossier'),
      dataIndex: 'reference_commande',
      width: 140,
      searchable: true,
      sorter: true,
    },
    {
      key: 'date',
      title: t('garantie.historique.datatable.dateDemande'),
      dataIndex: 'date_demande',
      width: 140,
      sorter: true,
      searchable: true,
      type: 'date',
      render: (value) => formatDate(value, DEFAULT_DATE_FORMAT),
    },
    {
      dataIndex: 'commandButtons',
      width: 120,
      fixed: 'right',
      render: (value: string, record: Record<string, any>) => (
        <group data-wrap="no" data-gap="10">
          <Button
            mini
            icon="zoom_in"
            onClick={() => {
              setOpenDetail(true);
              setWarrantyHistory(record);
            }}
          />
          <Separator vertical />
          {record.etape !== 'etape_end' && (
            <Button
              mini
              icon="delete"
              onClick={() => {
                open({
                  title: `${t('garantie.historique.modale.title').toUpperCase()} ${record.reference}`,
                  message: `${t('garantie.historique.modale.texteSuppression')}`,
                  positiveCallback: () => {
                    deleteWarranty(record);
                  },
                  positiveButtonText: t('garantie.listingDemandes.modale.buttons.valider'),
                  negativeButtonText: t('garantie.listingDemandes.modale.buttons.annuler'),
                  negativeCallBack: () => {},
                });
              }}
            />
          )}
        </group>
      ),
    },
  ];
  const deleteWarranty = async (record: any) => {
    try {
      setIsLoading(true);
      await trigger(record.uuid);
      setIsLoading(false);
      dispatch(
        addNotification({
          type: 'success',
          message: t('global.notifications.demande.deleteTitle'),
          description: t('global.notifications.demande.deleteMessage'),
          duration: 5,
        })
      );
    } catch (error: any) {
      ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
    }
  };

  const gridData = useMemo(() => {
    let result = data?.map((item) => ({ ...item, text_etat: t(item.libelle_etat ?? '') })) ?? [];
    if (filter) {
      let filt = filter.toLocaleLowerCase();
      return result.filter((d: any) =>
        columns
          .filter((c) => c.searchable)
          .some((c) => {
            let val = d[c.dataIndex as string];
            return val && (c.type === 'date' ? formatDate(val) : val.toLowerCase()).search(filt) !== -1;
          })
      );
    }
    return result;
  }, [data, filter]); // eslint-disable-line

  return (
    <section>
      {popup}
      {openDetail && (
        <Popup
          title={t('garantie.historique.modale.title', { reference: warrantyHistory.reference })}
          fixSize="medium"
          onClose={() => {
            setOpenDetail(false);
          }}
        >
          <WarrantyHistoryDetail warrantyHistory={warrantyHistory} setOpenDetail={setOpenDetail} />
        </Popup>
      )}
      <wrapper>
        <group data-space-vertical="20">
          <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
            garantie.historique.libelle
          </Text>
        </group>
        <group data-space-vertical="20" data-gap="10">
          <Input
            icon="search"
            dataLength="forcefit"
            data-min-length="300"
            name="searchArticle"
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t('global.datatable.recherche')}
          />
          <OptionBar compact data-length="forcefit">
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="false"
              value={archive.toString()}
              onChange={() => {
                setArchive(false);
              }}
              tooltip={t('garantie.historique.buttons.recentes.title')}
              label="garantie.historique.buttons.recentes.label"
            />
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="true"
              value={archive.toString()}
              onChange={() => {
                setArchive(true);
              }}
              tooltip={t('garantie.historique.buttons.archivees.title')}
              label="garantie.historique.buttons.archivees.label"
            />
          </OptionBar>
        </group>
        <group data-background="main-background" data-direction="column">
          <Table
            view={ListViewType.Table}
            columns={columns}
            isLoading={isFetching || isLoading}
            dataSource={gridData}
            pagination={false}
            setSorter={setSort}
            pagingProps={pagingProps.pagination}
            sort={sort}
            keyField="uuid"
            bordered={true}
            size={'small' as SizeType}
            tableLayout="auto"
          />
          <PaginationToolbar {...pagingProps} total={gridData ? gridData.length : 0} />
        </group>
      </wrapper>
    </section>
  );
};

export default WarrantyHistory;
