import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/shared/button';
import Loader from '../../../components/shared/loader';
import { APP_URL, WarrantyRequestSteps } from '../../../project/defines';
import { getAppUrl, getClientType } from '../../../project/helpers';
import { ApiErrorHandler } from '../../../store/apiErrorHandler';
import { addNotification } from '../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Product } from '../../../store/product/productModels';
import { currentCountryUuid } from '../../../store/sideData/siteDataSlice';
import { useCreateOrUpdateWarrantyRequestMutation } from '../../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../utils/hooks/useTranslation';
import StepButtons from '../stepButtons';
import ProductInfo from './../productInfo';
import ProductCatalogPopup from './catalogPopup';
import ProductSearchAutocomplete from './productSearchAutocomplete';

const Stage1: React.FC<{ request: WarrantyRequest; setRequest: (request: WarrantyRequest) => void }> = ({
  request,
  setRequest,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const user = useAppSelector((state) => state.auth);

  const { state } = useLocation();

  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(request.produit);

  const [createOrUpdateDemande, { isLoading }] = useCreateOrUpdateWarrantyRequestMutation();

  useEffect(() => {
    if (state?.uuid && state?.uuid !== request?.uniqueID) {
      setSelectedProduct(undefined);
    }
  }, [state?.uuid, request]);

  const onSelect = useCallback((p: Product) => {
    setSelectedProduct(p);
  }, []);

  const onClickPreviousStep = () => {
    navigate(getAppUrl(APP_URL.HomePage));
  };

  const onClickNextStep = async () => {
    if (selectedProduct) {
      let reseau_uuid = selectedProduct?.reseau.uuid;
      let clientType = getClientType(user, reseau_uuid);
      try {
        let result = await createOrUpdateDemande({
          uuid: request?.uuid ?? '',
          produit_uuid: selectedProduct.uuid,
          reseau_uuid: reseau_uuid,
          univers_uuid: univers?.uuid ?? '',
          garantie_type_uuid: (selectedProduct.typesGarantie as any)[clientType!].uuid,
          etape: WarrantyRequestSteps.etape2,
          typedemande: request.typedemande,
          pays_uuid: pays_uuid,
        }).unwrap();
        dispatch(
          addNotification({
            type: 'success',
            message: t(`global.notifications.demande.${request.uuid ? 'updateTitle' : 'createTitle'}`),
            description: t(`global.notifications.demande.${request.uuid ? 'updateMessage' : 'createMessage'}`),
            duration: 5,
          })
        );
        if (request?.uuid) {
          setRequest({ ...result, produit: { ...selectedProduct } });
        } else {
          result?.uuid &&
            navigate(getAppUrl(APP_URL.WarrantyReplacement, { routeParams: { reference: result.uuid } }), {
              replace: true,
            });
        }
      } catch (error: any) {
        ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
      }
    }
  };
  return (
    <section>
      {isLoading && <Loader />}
      <wrapper>
        <group data-space-vertical="20" data-gap="30">
          <Button
            data-length="forcefit"
            large
            primary
            text="garantie.demande.etapes.1.modalLink"
            icon="search"
            onClick={() => setIsCatalogOpen(true)}
          />
          <ProductSearchAutocomplete request={request} onSelect={onSelect} />
          {selectedProduct && <ProductInfo product={selectedProduct} />}
          <StepButtons
            request={request}
            disableRight={!selectedProduct}
            onClickLeft={onClickPreviousStep}
            onClickRight={onClickNextStep}
          />
          <ProductCatalogPopup
            request={request}
            title="garantie.demande.etapes.1.titreModal"
            isOpen={isCatalogOpen}
            onClose={() => setIsCatalogOpen(false)}
            onSelect={onSelect}
          />
        </group>
      </wrapper>
    </section>
  );
};

export default Stage1;
