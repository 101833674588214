import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/demo.css';
import ButtonsAndIcons from './buttonsAndIcons';
import CheckboxAndSwitchers from './checkboxSwitchers';
import InputComponents from './inputComponents';
import Landing from './navigation';
import ListViewDemo from './listView';
import Popups from './popups';
import ProductBrowser from './productBrowser';
import ThemePicker from './themePicker';
import Typeface from './typeface';
import TableDemo from './tableDemo';
import StickyElement from '../../components/shared/stickystuck';
import DarkModeToggle from '../../components/shared/darkModeToggle';
import TabStrip from '../../components/shared/tabstrip';
import Tab from '../../components/shared/tabstrip/tab';
import OpenizerExamples from './operizerExamples';
import Switchable from './switchable';
import Catalog from './catalog';
import Separator from '../../components/shared/separator';

const Template: React.FC = () => {
  return (
    <>
      <StickyElement>
        <toolbar>
          <Link to="../">
            <div className="button mini rounded">
              <icon>home</icon>
            </div>
          </Link>
          <Separator vertical />
          <nav launcher="">
            <ul>
              <li>
                <Link to="../">Home</Link>
              </li>
            </ul>
          </nav>
          <text data-light="" data-ellipsis="">
            Dark Mode Switcher
          </text>
          <DarkModeToggle />
        </toolbar>
        <toolbar data-type="snap" data-backdrop="">
          <ThemePicker />
        </toolbar>
      </StickyElement>

      <toolbar border="no">
        <group data-align="center" data-wrap="no">
          <text data-light="" data-space="10" data-ellipsis="">
            Primary & Secondary Color Palettes
          </text>

          <div className="palette">
            <div data-background="main-lighter"></div>
            <div data-background="main-light"></div>
            <div data-background="main"></div>
            <div data-background="main-dark"></div>
            <div data-background="main-darker"></div>

            <div data-background="secondary-darker"></div>
            <div data-background="secondary-dark"></div>
            <div data-background="secondary"></div>

            <div data-background="secondary-light"></div>
            <div data-background="secondary-lighter"></div>
          </div>
        </group>
      </toolbar>
      <Separator horizontal data-margin="none" />
      <TabStrip
        onChange={(index) => {
          console.log(index);
        }}
        skipSecondaryTabs={false}
        selectedIndex={0}
        id="myTabStrip"
      >
        <Tab title="Buttons & Icons" primary>
          <ButtonsAndIcons />
        </Tab>
        <Tab title="Typeface">
          <Typeface />
        </Tab>
        <Tab title="Checkbox & Switchers" primary>
          <CheckboxAndSwitchers />
        </Tab>
        <Tab title="Input Components" primary>
          <InputComponents />
        </Tab>
        <Tab title="List view" primary>
          <ListViewDemo />
        </Tab>
        <Tab title="Popups" primary>
          <Popups />
        </Tab>
        <Tab title="Navigations" primary>
          <Landing />
        </Tab>
        <Tab title="Context Menu">
          <ProductBrowser />
        </Tab>
        <Tab title="Table Demo">
          <TableDemo />
        </Tab>
        <Tab title="Openizer">
          <OpenizerExamples />
        </Tab>
        <Tab title="Switchable">
          <Switchable />
        </Tab>
        <Tab title="Catalog">
          <Catalog />
        </Tab>
      </TabStrip>
    </>
  );
};
export default Template;
