import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import Scroll from '../scroll';
import classNames from 'classnames';
import { Text } from '../text';
import { getKeyUpHandler } from '../../../utils/helpers';

/**
 * TabStrip is a custom tabstrip component in React that allows for additional functionality.
 * @param {function} props.onChange - (optional) callback function that takes the index of the selected tab as a parameter.
 * @param {boolean} props.skipSecondaryTabs - (optional) boolean that determines whether secondary tabs should be skipped.
 * @param {number} props.selectedIndex - (optional) the index of the selected tab.
 * @param {string} props.id - (optional) the id of the tab strip.
 * @param {Array} children - array of ReactNode components to be used as tabs.
 */

const TabStrip: React.FC<
  PropsWithChildren<{
    onChange?: (e: number, children: any[]) => void;
    skipSecondaryTabs?: boolean;
    selectedIndex?: number;
    selectedCallback?: (children: any[]) => number;
    id?: string;
    noScroll?: boolean;
  }>
> = ({ children, onChange, skipSecondaryTabs, selectedIndex, selectedCallback, id, noScroll = false }) => {
  const [selected, setSelected] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleSelect = (index: number) => {
    if (onChange) {
      onChange(index || 0, React.Children.toArray(children));
    }
    setSelected(index || 0);
  };

  useEffect(() => {
    const childrenArr = React.Children.toArray(children);
    if (ref.current && childrenArr.length) {
      setSelected((selectedCallback ? selectedCallback?.(childrenArr) : selectedIndex) ?? 0);
    }
  }, [selectedIndex, children, selectedCallback]);

  const processedChildren = ((!skipSecondaryTabs && children) ||
    React.Children.toArray(children).filter((child) => (child as ReactElement)?.props?.primary) ||
    children) as ReactElement[] | ReactElement;
  
  let tabNavItems;
  if (noScroll) {
    tabNavItems = (

        <group  data-gap="5">
          {React.Children.map(
            processedChildren,
            (child: ReactElement, index) => {
              return (
                <group
                  data-name="tab-nav-item"
                  data-cursor="pointer"
                  data-width="auto"
                  data-interactive=""
                  data-space="10"
                  data-radius="5"
                  data-border="inset"
                  onClick={() => handleSelect(index)}
                  onKeyUp={getKeyUpHandler()}
                  data-index={index}
                  data-effect="material"
                  className={classNames({
                    selected: selected === index,
                    disabled: child.props.disabled,
                  })}
                >
                  {child?.props.icon && <icon>{child.props.icon}</icon>}

                  {child?.props.title && <Text data-weight="700" data-ellipsis="">{child.props.title}</Text>}
                </group>
              );
            }
          )}
        </group>

    );
  } else {
    tabNavItems = (
      <div className="tab_strip_nav" id={id}>
        <toolbar data-type="snap">
          <Scroll className="nav_strip">
            <ul>
              {React.Children.map(
                processedChildren,
                (child: ReactElement, index) => {
                  return (
                    <li
                      onClick={() => handleSelect(index)}
                      onKeyUp={getKeyUpHandler()}
                      data-index={index}
                      data-effect="material"
                      className={classNames({
                        selected: selected === index,
                        disabled: child.props.disabled,
                      })}
                    >
                      {child?.props.icon && <icon>{child.props.icon}</icon>}

                      {child?.props.title && <Text>{child.props.title}</Text>}
                    </li>
                  );
                }
              )}
            </ul>
          </Scroll>
        </toolbar>
      </div>
    );
  }
  
  return (
    <div className="tab_strip" ref={ref}>

        {tabNavItems}

      <div className="tab_strip_content">
        {React.Children.map(processedChildren, (child, index) => {
          return (
            <div
              className={`tab_strip_tab${
                selected === index ? " selected" : ""
              }`}
            >
              {child.props.children}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabStrip;
