import { DatePicker as AntDatepicker, DatePickerProps as AntDatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { DEFAULT_DATE_FORMAT } from '../../../project/defines';
import Input, { InputProps, InputType } from './input';

export type DatePickerProps = InputProps &
  Omit<AntDatePickerProps, keyof InputProps> & {
    min?: string;
    max?: string;
    showTime?: any;
    delayBlur?: boolean;
    useDayJS?: boolean;
  };

export const DatePickerInner = forwardRef<ReactElement, PropsWithChildren<DatePickerProps>>(
  (
    {
      value,
      onChange,
      delayBlur = false,
      onBlur,
      onFocus,
      min,
      max,
      showTime,
      allowClear,
      useDayJS,

      format = DEFAULT_DATE_FORMAT,
      ...rest
    },
    ref
  ) => {
    const [blurTimeout, setBlurTimeout] = useState(setTimeout(() => {}));
    let dateFormat = format;
    if (showTime) {
      dateFormat = `${dateFormat} ${showTime?.format || 'HH:mm'}`;
    }
    const onCustomChange = (v: Dayjs, strValue: string) => {
      onChange?.((useDayJS ? v : strValue) as any);
    };

    const disableDate = useCallback(
      (current: Dayjs) => {
        return (min && dayjs(min) >= current) || (max && dayjs(max) <= current);
      },
      [min, max]
    );

    // preventing lose focus
    const blur = delayBlur
      ? (e: any) => {
          e?.persist();
          onBlur && setBlurTimeout(setTimeout(() => onBlur(e), 300));
        }
      : onBlur;
    const focus = useCallback(
      (e: any) => {
        delayBlur && clearTimeout(blurTimeout);
        onFocus?.(e);
      },
      [delayBlur, onFocus, blurTimeout]
    );

    const formattedValue = useDayJS ? value : dayjs(value as string, dateFormat as string);
    return (
      <AntDatepicker
        ref={ref}
        disabledDate={disableDate}
        onChange={onCustomChange}
        onBlur={blur}
        onFocus={focus}
        format={dateFormat}
        showTime={showTime}
        value={value ? formattedValue : null}
        allowClear={allowClear}
        {...(rest as any)}
      />
    );
  }
);

export const DatePicker = forwardRef<ReactElement, PropsWithChildren<DatePickerProps>>((props, ref) => {
  return <Input ref={ref} {...props} type={InputType.DatePicker} />;
});
