import React from 'react';
import Badge from '../../components/shared/badge';
import { Link } from 'react-router-dom';

import {
  SvgHamburger,
  SvgHamburgerToLeft,
  SvgHamburgerToRight,
  SvgLoader,
  SvgMinus,
  SvgPlus,
} from '../../components/svg';
import Button from '../../components/shared/button';
import {
  SvgAtlanticCommandes,
  SvgAtlanticDarwin,
  SvgAtlanticFormation,
  SvgAtlanticLogiciels,
  SvgAtlanticPanier,
  SvgAtlanticProduit,
  SvgAtlanticReglement,
  SvgAtlanticServices,
  SvgAtlanticSolutions,
} from '../../brands/atlantic/components/svg';
import Separator from '../../components/shared/separator';
const ButtonsAndIcons: React.FC = () => {
  return (
    <view>
      <form>
        <view data-vertical="true" data-adaptive>
          <view>
            <toolbar>
              <div className="caption">
                <text data-weight="700" data-space="10" data-ellipsis="">
                  Buttons
                </text>
              </div>
            </toolbar>
            <view>
              <demo_wrap>
                <Button primary text="Primary Button" />

                <Button accent text="Accent Button" icon="arrow_forward" />

                <Button material outline text="Outline Button" textFirst={false} icon="search" />

                <Button highlight text="Highlight Button" textFirst={false} icon="play_arrow" />

                <Button text="Simple Button" />

                <Link data-effect="material" to="" className="button">
                  <icon>favorite_border</icon> <text>Anchor Button</text>
                </Link>

                <Button icon="star" adaptive text="Responsive Button" />
              </demo_wrap>
              <demo_wrap>
                <Button primary mini rounded icon="home" />
                <Separator vertical />
                <Button accent mini rounded icon="navigate_next" />
                <Separator vertical />
                <Button outline mini rounded icon="search" />

                <Button outline mini large icon="arrow_outward" />
                <Separator vertical />
                <Button primary mini icon="search" />

                <Button accent mini icon="arrow_forward" />

                <Button highlight mini icon="info" />

                <Button mini icon="play_arrow" />
                <Separator vertical />
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgHamburger />
                  </icon>
                </Button>
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgPlus />
                  </icon>
                </Button>
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgMinus />
                  </icon>
                </Button>
                <Button
                  rounded
                  primary
                  large
                  toggleClassName="open"
                  material
                  text="Hamburger To Back"
                  textFirst={false}
                >
                  <icon>
                    <SvgHamburgerToLeft />
                  </icon>
                </Button>
                <Button outline rounded large toggleClassName="open" material text="Hamburger To Back">
                  <icon>
                    <SvgHamburgerToRight />
                  </icon>
                </Button>

                <Button rounded primary large text="Please Wait" textFirst={false}>
                  <icon>
                    <SvgLoader />
                  </icon>
                </Button>
              </demo_wrap>
              <demo_wrap>
                <Button primary large icon="inventory_2" text="Large Button" textFirst={false} />

                <Button accent large icon="arrow_outward" text="Large Button" />

                <Button large text="Large Button" />

                <Button outline large text="Large Button" />

                <Button highlight large text="Large Button" />

                <Button primary large wide text="Large Button Wide" />

                <Button outline fit large text="Large Button Fit" />

                <Button highlight large fit text="Large Button Fit" />
              </demo_wrap>
            </view>
          </view>

          <view>
            <toolbar>
              <text data-weight="700" data-space="10" data-ellipsis="">
                Custom Icons Demo
              </text>
            </toolbar>
            <scroller>
              <grid>
                <wrap>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticSolutions />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticCommandes />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticDarwin />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticFormation />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticLogiciels />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticPanier />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticProduit />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticReglement />
                    </icon>
                  </item>
                  <item data-effect="material">
                    <icon data-icon-size="" data-color="secondary">
                      <SvgAtlanticServices />
                    </icon>
                  </item>
                </wrap>
              </grid>
              <toolbar>
                <text data-weight="700" data-space="10" data-ellipsis="">
                  Material Icons Demo
                </text>
                <Separator vertical />
                <a
                  data-link=""
                  target="_blank"
                  rel="noreferrer"
                  href="https://fonts.google.com/icons?icon.style=Rounded"
                >
                  <text data-weight="700" data-space="10" data-ellipsis="">
                    More icons ...
                  </text>
                </a>
              </toolbar>

              <grid>
                <wrap>
                  <item className="selected" data-effect="material">
                    <icon>settings</icon> <text>Settings</text>
                  </item>
                  <item data-effect="material">
                    <icon>home</icon> <text>home</text>
                  </item>
                  <item data-effect="material">
                    <icon>favorite</icon> <text>Heart</text>
                  </item>
                  <item data-effect="material">
                    <icon>nest_eco_leaf </icon> <text>leaf</text>
                  </item>

                  <item data-effect="material">
                    <icon>laundry</icon>
                    <text>Icon</text>
                  </item>
                  <item data-effect="material">
                    <icon>chair</icon>
                    <text>Icon</text>
                  </item>
                  <item data-effect="material">
                    <icon>shield</icon>
                    <text>Icon</text>
                  </item>
                </wrap>
              </grid>
              <Separator horizontal />
              <grid>
                <wrap>
                  <item data-effect="material">
                    <icon data-fill="">settings</icon> <text>Settings</text>
                  </item>
                  <item data-effect="material">
                    <icon data-fill="">home</icon> <text>home</text>
                  </item>
                  <item data-effect="material">
                    <icon data-fill="">favorite</icon> <text>Heart</text>
                  </item>
                  <item data-effect="material">
                    <icon data-fill="">delete</icon> <text>Delete</text>
                  </item>
                  <item data-effect="material">
                    <icon data-fill="">nest_eco_leaf </icon> <text>leaf</text>
                  </item>
                </wrap>
              </grid>
              <Separator horizontal />
              <grid>
                <wrap>
                  <item>
                    <icon data-icon-weight="100">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="200">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="300">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="500">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="600">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="700">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                </wrap>
              </grid>
            </scroller>
          </view>

          <view data-size="small">
            <toolbar>
              <text data-weight="700" data-space="10" data-ellipsis="">
                Badges
              </text>
            </toolbar>
            <scroller>
              <demo_wrap>
                <group data-gap="10">
                  <Badge text="Simple Badge" />
                  <Badge text="Simple Badge" filled />

                  <text data-space="10">Inherits Color from Main Theme</text>
                  <Separator horizontal />

                  <Badge color="red" text="Red" />
                  <Badge color="pink" text="pink" />
                  <Badge color="purple" text="purple" />
                  <Badge color="deep-purple" text="deep-purple" />
                  <Badge color="indigo" text="indigo" />
                  <Badge color="blue" text="blue" />
                  <Badge color="light-blue" text="light-blue" />
                  <Badge color="cyan" text="cyan" />
                  <Badge color="teal" text="teal" />
                  <Badge color="green" text="green" />
                  <Badge color="light-green" text="light-green" />
                  <Badge color="lime" text="lime" />
                  <Badge color="yellow" text="yellow" />
                  <Badge color="amber" text="amber" />
                  <Badge color="orange" text="orange" />
                  <Badge color="deep-orange" text="deep-orange" />
                  <Badge color="brown" text="brown" />
                  <Badge color="grey" text="grey" />
                  <Badge color="blue-grey" text="blue-grey" />
                  <Separator horizontal />
                  <Badge color="cyan" text="cyan" filled />
                  <Badge color="teal" text="teal" filled />
                  <Badge color="green" text="green" filled />
                  <Badge color="light-green" text="light-green" filled />
                  <Badge color="lime" text="lime" filled />
                </group>
              </demo_wrap>
            </scroller>
          </view>
        </view>
        <toolbar></toolbar>
      </form>
    </view>
  );
};
export default ButtonsAndIcons;
