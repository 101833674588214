import { Pagination, TablePaginationConfig } from 'antd';
import Select from '../inputs/select';
import Separator from '../separator';
import { Text } from '../text';

export const PaginationToolbar: React.FC<{
  total?: number;
  pagination: TablePaginationConfig;
  setPagination: Function;
}> = ({ total = 0, pagination, setPagination }) => (
  <group data-space="5" data-background="highlight" data-name="ant-pagination" data-wrap="no">
    {total > 0 && (
      <group data-align="center" data-gap="5">
        <Pagination
          {...pagination}
          showSizeChanger={false}
          total={total}
          onChange={(current) => {
            setPagination({ ...pagination, current });
          }}
        />
        <Separator vertical data-adaptive="desktop" />
        <label data-minimal="">
          <Text light data-space="10" data-adaptive="desktop">
            global.datatable.size
          </Text>
          <Separator vertical />
          <Select
            showAction={['focus']}
            dropdownMatchSelectWidth={false}
            bordered={false}
            options={[10, 25, 50, 100, 200, 500]}
            value={pagination.pageSize}
            onChange={(value: any) => {
              setPagination({ ...pagination, pageSize: value });
            }}
          />
        </label>
        <Separator vertical data-adaptive="desktop" />

        <Text light data-space="10" data-adaptive="desktop">
          global.datatable.total
        </Text>
        <Text dataWeight="700" data-adaptive="desktop">
          {total}
        </Text>
      </group>
    )}
  </group>
);
