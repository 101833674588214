// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import {
  Order,
  OrdersDetailRequestModel,
  OrdersListRequestModel
} from './orderModels';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseQuery('commandes'),
  endpoints: (builder) => {
    return {
      getOrdersHistory: builder.query<Order[], OrdersListRequestModel>({
        query: ({ societe_uuid, ...params }) => {
          return {
            url: `/societe/${societe_uuid}`,
            method: 'get',
            params,
          };
        },
      }),
      getOrdersDetails: builder.query<Order, OrdersDetailRequestModel>({
        query: ({ order_uuid, ...params }) => {
          return {
            url: `/${order_uuid}`,
            method: 'get',
            params,
          };
        }
      }),
      getProductOrders: builder.query<Order[], string>({
        query: (product_uuid) => {
          return {
            url: `/me/${product_uuid}`,
            method: 'get'
          };
        }
      }),
    };
  }
});

export const { useGetOrdersHistoryQuery, useGetOrdersDetailsQuery, useGetProductOrdersQuery } = ordersApi;
