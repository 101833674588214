import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS } from '../../project/defines';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/helpers/localStorage';
import { Language, LanguageState } from './languageModels';

const loadStateFromStorage: () => LanguageState = () => {
  let lang = getLocalStorageItem(LOCAL_STORAGE_KEYS.Language),
    languageState;
  try {
    if (lang) {
      languageState = JSON.parse(lang);
    }
  } catch (e) {
    console.log(e);
  }

  if (languageState) {
    return languageState;
  } else {
    return { currentLanguage: DEFAULT_LANGUAGE };
  }
};

let initialState: LanguageState = loadStateFromStorage();

export const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    setLanguages(state, action: PayloadAction<Language[]>) {
      state.languages = action.payload;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.Language, JSON.stringify(state));
    },
    setCurrentLanguage(state, action: PayloadAction<string>) {
      state.currentLanguage = action.payload;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.Language, JSON.stringify(state));
    },
    setTranslations(state, action: PayloadAction<any>) {
      state.translations = action.payload;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.Language, JSON.stringify(state));
    },
  },
});

export const { setCurrentLanguage, setLanguages, setTranslations } = languageSlice.actions;

export default languageSlice.reducer;

export const languages = (state: RootState) => state.language.languages;
