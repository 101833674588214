import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/shared/inputs/input';
import Radio, { RadioType } from '../../components/shared/inputs/radio';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table, { ColumnType } from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import OptionBar from '../../components/shared/optionBar';
import { Text } from '../../components/shared/text';
import { useAppSelector } from '../../store/hooks';
import { formatDate } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import { getStatusText } from '../orderHistory/orderDetails';
import { useGetDevisBySocieteQuery } from '../../store/devis/devisApi';
import { getApiUrl, getUniversFromUrl } from '../../project/helpers';
import { DEFAULT_DATE_FORMAT } from '../../project/defines';

const OrderHistory: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers_uuid = useAppSelector((state) => state.siteData?.universInfo?.univers?.uuid) ?? '';
  const societe_uuid = useAppSelector((state) => state.auth.societe_uuid) ?? '';
  const [archive, setArchive] = useState(!!state?.archive);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (state?.archive !== archive) {
      navigate('', { replace: true, state: { archive } });
    }
  }, [archive]); //eslint-disable-line

  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const { data, isLoading, isFetching } = useGetDevisBySocieteQuery({ lang, univers_uuid, societe_uuid, archive });

  const columns: ColumnType[] = [
    {
      key: 'devis_no',
      title: t('devis.monHistoriqueDevis.no_devis'),
      dataIndex: 'devis_no',
      sorter: true,
      searchable: true,
    },
    {
      key: 'reference',
      title: t('devis.monHistoriqueDevis.reference'),
      dataIndex: 'reference',
      sorter: true,
      searchable: true,
      width: 250,
    },

    {
      key: 'created_at',
      title: t('devis.monHistoriqueDevis.dateDevis'),
      dataIndex: 'created_at',
      sorter: true,
      searchable: true,
      type: 'date',
      width: 150,
      render: (value) => formatDate(value, DEFAULT_DATE_FORMAT),
    },
    {
      key: 'uuid',
      dataIndex: 'uuid',
      width: 60,
      render: (value, record) => (
        <group data-wrap="" data-gap="10">
          <a
            className="button mini"
            target="_blank"
            rel="noreferrer"
            href={`${getApiUrl(getUniversFromUrl())}/devis/download/${record.uuid}/${record.devis_no}-${
              record.reference.replace('/', '-')
            }${record.image_guid.indexOf('.docx') !== -1 ? '.docx' : '.pdf'}?lang=${lang}`}
          >
            <icon>{record.image_guid.indexOf('.docx') !== -1 ? 'description' : 'picture_as_pdf'}</icon>
          </a>
        </group>
      ),
    },
  ];

  const quotes = useMemo(() => {
    let result = data?.map((d: any) => ({ ...d, status: getStatusText(d.etat, t) })) ?? [];
    if (filter) {
      let filt = JSON.stringify(filter.toLocaleLowerCase()).replace(/((^")|("$))/g, '');
      return result.filter((d: any) =>
        columns
          .filter((c) => c.searchable)
          .some((c) => {
            let val = d[c.dataIndex as string];
            return (c.type === 'date' ? formatDate(val) : val.toLowerCase()).search(filt) !== -1;
          })
      );
    }
    return result;
  }, [data, filter]); //eslint-disable-line

  return (
    <section>
      <wrapper>
        <group data-space-vertical="20">
          <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
            devis.monHistoriqueDevis.libelle
          </Text>
        </group>
        <group data-space-vertical="20" data-gap="10">
          <Input
            icon="search"
            dataLength="forcefit"
            data-min-length="300"
            name="searchArticle"
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t('global.datatable.recherche')}
          />
          <OptionBar compact data-length="forcefit">
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="false"
              value={archive.toString()}
              onChange={() => {
                setArchive(false);
              }}
              tooltip={t('devis.monHistoriqueDevis.boutons.recentes.title')}
              label="devis.monHistoriqueDevis.boutons.recentes.label"
            />
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="true"
              value={archive.toString()}
              onChange={() => {
                setArchive(true);
              }}
              tooltip={t('devis.monHistoriqueDevis.boutons.archivees.title')}
              label="devis.monHistoriqueDevis.boutons.archivees.label"
            />
          </OptionBar>
        </group>
        <group data-background="main-background" data-direction="column">
          <Table
            loading={isLoading || isFetching}
            keyField="uuid"
            setSorter={setSort}
            dataSource={quotes}
            columns={columns}
            sort={sort}
            pagingProps={pagingProps.pagination}
            pagination={false}
            size={'small' as SizeType}
            bordered={true}
          />
          <PaginationToolbar {...pagingProps} total={quotes?.length || 0} />
        </group>
      </wrapper>
    </section>
  );
};

export default OrderHistory;
