import React, { ReactElement, useCallback, useState } from 'react';
import Popup from '../../shared/popup';

interface ConfirmationPopupProps {
  title: string;
  message?: string;
  positiveCallback: () => void;
  positiveButtonText: string;
  negativeCallBack?: () => void;
  negativeButtonText: string;
  content?: ReactElement;
}

const ConfirmationPopup: React.FC<{
  title: string;
  message?: string;
  positiveCallback: () => void;
  positiveButtonText: string;
  negativeCallBack?: () => void;
  negativeButtonText: string;
  isOpen: boolean;
  setIsOpen: (isopen: boolean) => void;
  content?: ReactElement;
}> = ({
  title,
  message,
  positiveCallback,
  positiveButtonText,
  negativeCallBack,
  negativeButtonText,
  isOpen,
  setIsOpen,
  content,
}) => {
  const onClose = useCallback(() => setTimeout(() => setIsOpen(false)), [setIsOpen]);
  return (
    <>
      {isOpen && (
        <Popup onClose={onClose} title={title}>
          <group>
            <group data-space="30" data-direction="column">
              <text data-wrap="wrap" data-text-size="medium" data-wrap-line="">
                {message}
              </text>
              {content}
            </group>
            <group data-space="30" data-gap="10" data-background="highlight" data-type="grid">
              <button
                type="button"
                data-effect="material"
                className="button primary large"
                onClick={() => {
                  positiveCallback?.();
                  onClose();
                }}
              >
                <text>{positiveButtonText}</text>
              </button>
              <button
                type="button"
                data-effect="material"
                className="button outline large"
                onClick={() => {
                  negativeCallBack?.();
                  onClose();
                }}
              >
                <text>{negativeButtonText || 'Cancel'}</text>
              </button>
            </group>
          </group>
        </Popup>
      )}
    </>
  );
};

export const useConfirmationPopup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [popupProps, setPopupProps] = useState<ConfirmationPopupProps | null>(null);

  const popup =
    isPopupOpen && popupProps ? (
      <ConfirmationPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} {...popupProps} />
    ) : (
      <></>
    );

  return {
    open: (popup: ConfirmationPopupProps) => {
      setPopupProps(popup);
      setIsPopupOpen(true);
    },
    isPopupOpen,
    popup,
    setContent: (content: ReactElement) => setPopupProps({ ...(popupProps as ConfirmationPopupProps), content }),
  };
};

export default ConfirmationPopup;
