
import { Text } from '../../components/shared/text';
import { isArticleAvailable } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';


const CardPaymentIcon: React.FC<{ article: Article; }> = ({ article }) => {
  let { articleReseau, article_uuid, paiementCb, isEnCompte } = article;
  if (!articleReseau ||
    !articleReseau.isCommandable ||
    articleReseau.prix_ht as number <= 0 ||
    article_uuid ||
    !paiementCb ||
    !isArticleAvailable(article)) return null;

  return !isEnCompte ? <group data-direction='column' data-background="main" data-radius="10" data-space="10" data-width="auto" data-color="white">
    <Text data-weight="700">article.paiement</Text>
    <icon data-icon-size='large'>credit_card</icon>
  </group > : <></>;
};

export default CardPaymentIcon;