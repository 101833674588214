import React from 'react';
import Separator from '../../components/shared/separator';

const Typeface: React.FC = () => {
  return (
    <view data-vertical="" data-adaptive="">
      <view data-size="small">
        <toolbar data-sticky="top" data-backdrop="">
          <text data-space="10">Currently in Use</text>
        </toolbar>
        <view data-space="20" data-border="none">
          <text data-weight="700" data-text-size="xxx-large" data-wrap="wrap" data-accent="">
            Plus<br></br>Jakarta<br></br>Sans
          </text>
          <space data-height="30"></space>
          <text data-wrap="wrap" data-light="">
            Plus Jakarta Sans is a fresh take on geometric sans serif styles, designed by Gumpita Rahayu from Tokotype.
            The fonts were originally commissioned by 6616 Studio for Jakarta Provincial Government program's +Jakarta
            City of Collaboration identity in 2020.
          </text>
        </view>
      </view>

      <view data-size="small">
        <toolbar data-sticky="top" data-backdrop="">
          <text data-space="10">Preview</text>
        </toolbar>
        <view data-space="20" data-border="none">
          <text data-light="">Thin 100</text>
          <space data-height="30"></space>
          <text data-wrap="wrap" data-weight="100" data-text-size="x-large">
            The quick brown fox jumps over the lazy dog
          </text>
          <space data-height="30"></space>
          <Separator horizontal />
          <text data-light="">Light 300</text>
          <space data-height="30"></space>
          <text data-wrap="wrap" data-weight="300" data-text-size="x-large">
            The quick brown fox jumps over the lazy dog
          </text>
          <space data-height="30"></space>
          <Separator horizontal />
          <text data-light="">Regular 400</text>
          <space data-height="30"></space>
          <text data-wrap="wrap" data-weight="" data-text-size="x-large">
            The quick brown fox jumps over the lazy dog
          </text>
          <space data-height="30"></space>
          <Separator horizontal />
          <text data-light="">Medium 500</text>
          <space data-height="30"></space>
          <text data-wrap="wrap" data-weight="500" data-text-size="x-large">
            The quick brown fox jumps over the lazy dog
          </text>
          <icon></icon>
          <Separator horizontal />
          <text data-light="">Bold 700</text>
          <icon></icon>
          <text data-wrap="wrap" data-weight="700" data-text-size="x-large">
            The quick brown fox jumps over the lazy dog
          </text>
        </view>
      </view>

      <view data-scroll="">
        <toolbar data-sticky="top" data-backdrop="">
          <text data-space="10">Glyphs</text>
        </toolbar>

        <grid>
          <wrap mini="">
            <div>{'A'}</div>
            <div>{'B'}</div>
            <div>{'C'}</div>
            <div>{'Č'}</div>
            <div>{'Ć'}</div>
            <div>{'D'}</div>
            <div>{'Đ'}</div>
            <div>{'E'}</div>
            <div>{'F'}</div>
            <div>{'G'}</div>
            <div>{'H'}</div>
            <div>{'I'}</div>
            <div>{'J'}</div>
            <div>{'K'}</div>
            <div>{'L'}</div>
            <div>{'M'}</div>
            <div>{'N'}</div>
            <div>{'O'}</div>
            <div>{'P'}</div>
            <div>{'Q'}</div>
            <div>{'R'}</div>
            <div>{'S'}</div>
            <div>{'Š'}</div>
            <div>{'T'}</div>
            <div>{'U'}</div>
            <div>{'V'}</div>
            <div>{'W'}</div>
            <div>{'X'}</div>
            <div>{'Y'}</div>
            <div>{'Z'}</div>
            <div>{'Ž'}</div>
            <div>{'a'}</div>
            <div>{'b'}</div>
            <div>{'c'}</div>
            <div>{'č'}</div>
            <div>{'ć'}</div>
            <div>{'d'}</div>
            <div>{'đ'}</div>
            <div>{'e'}</div>
            <div>{'f'}</div>
            <div>{'g'}</div>
            <div>{'h'}</div>
            <div>{'i'}</div>
            <div>{'j'}</div>
            <div>{'k'}</div>
            <div>{'l'}</div>
            <div>{'m'}</div>
            <div>{'n'}</div>
            <div>{'o'}</div>
            <div>{'p'}</div>
            <div>{'q'}</div>
            <div>{'r'}</div>
            <div>{'s'}</div>
            <div>{'š'}</div>
            <div>{'t'}</div>
            <div>{'u'}</div>
            <div>{'v'}</div>
            <div>{'w'}</div>
            <div>{'x'}</div>
            <div>{'y'}</div>
            <div>{'z'}</div>
            <div>{'ž'}</div>
            <div>{'А'}</div>
            <div>{'Б'}</div>
            <div>{'В'}</div>
            <div>{'Г'}</div>
            <div>{'Ґ'}</div>
            <div>{'Д'}</div>
            <div>{'Ђ'}</div>
            <div>{'Е'}</div>
            <div>{'Ё'}</div>
            <div>{'Є'}</div>
            <div>{'Ж'}</div>
            <div>{'З'}</div>
            <div>{'Ѕ'}</div>
            <div>{'И'}</div>
            <div>{'І'}</div>
            <div>{'Ї'}</div>
            <div>{'Й'}</div>
            <div>{'Ј'}</div>
            <div>{'К'}</div>
            <div>{'Л'}</div>
            <div>{'Љ'}</div>
            <div>{'М'}</div>
            <div>{'Н'}</div>
            <div>{'Њ'}</div>
            <div>{'О'}</div>
            <div>{'П'}</div>
            <div>{'Р'}</div>
            <div>{'С'}</div>
            <div>{'Т'}</div>
            <div>{'Ћ'}</div>
            <div>{'У'}</div>
            <div>{'Ў'}</div>
            <div>{'Ф'}</div>
            <div>{'Х'}</div>
            <div>{'Ц'}</div>
            <div>{'Ч'}</div>
            <div>{'Џ'}</div>
            <div>{'Ш'}</div>
            <div>{'Щ'}</div>
            <div>{'Ъ'}</div>
            <div>{'Ы'}</div>
            <div>{'Ь'}</div>
            <div>{'Э'}</div>
            <div>{'Ю'}</div>
            <div>{'Я'}</div>
            <div>{'а'}</div>
            <div>{'б'}</div>
            <div>{'в'}</div>
            <div>{'г'}</div>
            <div>{'ґ'}</div>
            <div>{'д'}</div>
            <div>{'ђ'}</div>
            <div>{'е'}</div>
            <div>{'ё'}</div>
            <div>{'є'}</div>
            <div>{'ж'}</div>
            <div>{'з'}</div>
            <div>{'ѕ'}</div>
            <div>{'и'}</div>
            <div>{'і'}</div>
            <div>{'ї'}</div>
            <div>{'й'}</div>
            <div>{'ј'}</div>
            <div>{'к'}</div>
            <div>{'л'}</div>
            <div>{'љ'}</div>
            <div>{'м'}</div>
            <div>{'н'}</div>
            <div>{'њ'}</div>
            <div>{'о'}</div>
            <div>{'п'}</div>
            <div>{'р'}</div>
            <div>{'с'}</div>
            <div>{'т'}</div>
            <div>{'ћ'}</div>
            <div>{'у'}</div>
            <div>{'ў'}</div>
            <div>{'ф'}</div>
            <div>{'х'}</div>
            <div>{'ц'}</div>
            <div>{'ч'}</div>
            <div>{'џ'}</div>
            <div>{'ш'}</div>
            <div>{'щ'}</div>
            <div>{'ъ'}</div>
            <div>{'ы'}</div>
            <div>{'ь'}</div>
            <div>{'э'}</div>
            <div>{'ю'}</div>
            <div>{'я'}</div>
            <div>{'Α'}</div>
            <div>{'Β'}</div>
            <div>{'Γ'}</div>
            <div>{'Δ'}</div>
            <div>{'Ε'}</div>
            <div>{'Ζ'}</div>
            <div>{'Η'}</div>
            <div>{'Θ'}</div>
            <div>{'Ι'}</div>
            <div>{'Κ'}</div>
            <div>{'Λ'}</div>
            <div>{'Μ'}</div>
            <div>{'Ν'}</div>
            <div>{'Ξ'}</div>
            <div>{'Ο'}</div>
            <div>{'Π'}</div>
            <div>{'Ρ'}</div>
            <div>{'Σ'}</div>
            <div>{'Τ'}</div>
            <div>{'Υ'}</div>
            <div>{'Φ'}</div>
            <div>{'Χ'}</div>
            <div>{'Ψ'}</div>
            <div>{'Ω'}</div>
            <div>{'α'}</div>
            <div>{'β'}</div>
            <div>{'γ'}</div>
            <div>{'δ'}</div>
            <div>{'ε'}</div>
            <div>{'ζ'}</div>
            <div>{'η'}</div>
            <div>{'θ'}</div>
            <div>{'ι'}</div>
            <div>{'κ'}</div>
            <div>{'λ'}</div>
            <div>{'μ'}</div>
            <div>{'ν'}</div>
            <div>{'ξ'}</div>
            <div>{'ο'}</div>
            <div>{'π'}</div>
            <div>{'ρ'}</div>
            <div>{'σ'}</div>
            <div>{'τ'}</div>
            <div>{'υ'}</div>
            <div>{'φ'}</div>
            <div>{'χ'}</div>
            <div>{'ψ'}</div>
            <div>{'ω'}</div>
            <div>{'ά'}</div>
            <div>{'Ά'}</div>
            <div>{'έ'}</div>
            <div>{'Έ'}</div>
            <div>{'έ'}</div>
            <div>{'Ή'}</div>
            <div>{'ί'}</div>
            <div>{'ϊ'}</div>
            <div>{'ΐ'}</div>
            <div>{'Ί'}</div>
            <div>{'ό'}</div>
            <div>{'Ό'}</div>
            <div>{'ύ'}</div>
            <div>{'ΰ'}</div>
            <div>{'ϋ'}</div>
            <div>{'Ύ'}</div>
            <div>{'Ϋ'}</div>
            <div>{'Ώ'}</div>
            <div>{'Ă'}</div>
            <div>{'Â'}</div>
            <div>{'Ê'}</div>
            <div>{'Ô'}</div>
            <div>{'Ơ'}</div>
            <div>{'Ư'}</div>
            <div>{'ă'}</div>
            <div>{'â'}</div>
            <div>{'ê'}</div>
            <div>{'ô'}</div>
            <div>{'ơ'}</div>
            <div>{'ư'}</div>
            <div>{'1'}</div>
            <div>{'2'}</div>
            <div>{'3'}</div>
            <div>{'4'}</div>
            <div>{'5'}</div>
            <div>{'6'}</div>
            <div>{'7'}</div>
            <div>{'8'}</div>
            <div>{'9'}</div>
            <div>{'0'}</div>
            <div>{'\u2018'}</div>
            <div>{'?'}</div>
            <div>{'\u2019'}</div>
            <div>{'“'}</div>
            <div>{'!'}</div>
            <div>{'”'}</div>
            <div>{'('}</div>
            <div>{'%'}</div>
            <div>{')'}</div>
            <div>{'['}</div>
            <div>{'#'}</div>
            <div>{']'}</div>
            <div>{'{'}</div>
            <div>{'@'}</div>
            <div>{'}'}</div>
            <div>{'/'}</div>
            <div>{'\\'}</div>
            <div>{'-'}</div>
            <div>{'+'}</div>
            <div>{'÷'}</div>
            <div>{'\u00d7'}</div>
            <div>{'='}</div>
            <div>{'&'}</div>
            <div>{'®'}</div>
            <div>{'©'}</div>
            <div>{'$'}</div>
            <div>{'€'}</div>
            <div>{'£'}</div>
            <div>{'¥'}</div>
            <div>{'¢'}</div>
            <div>{':'}</div>
            <div>{';'}</div>
            <div>{','}</div>
            <div>{'.'}</div>
            <div>{'*'}</div>
          </wrap>
        </grid>
      </view>
    </view>
  );
};
export default Typeface;
