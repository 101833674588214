// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import { ProductHistoryItem } from './productHistoryModels';

export const productHistoryApi = createApi({
  reducerPath: 'productHistoryApi',
  baseQuery: baseQuery('produithistory'),
  endpoints: (builder) => {
    return {
      getProductHistory: builder.query<ProductHistoryItem[], {
        univers_uuid: string, lang: string;
      }>({
        query: ({ univers_uuid, lang }) => {
          return {
            url: ``,
            method: 'get',
            params: {
              univers_uuid,
              lang,
            }
          };
        },
      }),
      updateProductHistory: builder.mutation<ProductHistoryItem[], {
            univers_uuid: string, lang: string, produit_uuid: string, reseau_uuid: string
        }>({
            query: ({ univers_uuid, lang, produit_uuid, reseau_uuid }) => {
                return {
                    url: ``,
                    method: 'post',
                    params: {
                        univers_uuid,
                        lang,
                        produit_uuid,
                        reseau_uuid
                    }
                };
            },
        }),
    };
  },
});

export const { useGetProductHistoryQuery, useUpdateProductHistoryMutation } = productHistoryApi;
