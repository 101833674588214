import CookieBot from 'react-cookiebot';
import { useAppSelector } from '../../store/hooks';
import { useEffect } from 'react';

export const CookiebotUnivers: React.FC = () => {
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);

  useEffect(() => {
    if (lang && univers?.cookiebot) {
      const onLoad = () => {
        document?.getElementById('CookieBot')?.setAttribute('data-culture', lang);
        document?.getElementById('CookieDeclaration')?.setAttribute('data-culture', lang);
      };
      window.addEventListener('CookiebotOnLoad', onLoad);

      return () => window.removeEventListener('CookiebotOnLoad', onLoad);
    }
  }, [lang, univers]);

  if (univers?.cookiebot) {
    setTimeout(() => {
      document?.getElementById('CookieBot')?.setAttribute('data-culture', lang);
      document?.getElementById('CookieDeclaration')?.setAttribute('data-culture', lang);
    }, 100);
  }

  return <>{univers?.cookiebot && <CookieBot domainGroupId={univers.cookiebot} />}</>;
};
