import { ReactElement, useEffect, useState } from 'react';
import { getBrandFolder, getUniversForUrl, getUniversFromUrl } from '../project/helpers';


export const useLogo = () => {
  const [logo, setLogo] = useState<ReactElement | null>(null);
  const univers = getUniversFromUrl();
  const universForUrl = getUniversForUrl(univers);
  const brandFolder = getBrandFolder(univers);
  useEffect(() => {
    const callback = (x: any) => {
        x.default && setLogo(<img src={x.default} />); //eslint-disable-line 
    };
    if (brandFolder !== 'other') {
      import(`./${brandFolder}/public/logo.svg`).then(callback);
    } else {
      import(`./${brandFolder}/public/${universForUrl}/logo.svg`).then(callback)
        .catch((err) =>
          import(`./${brandFolder}/public/${universForUrl}/logo.png`).then(callback)
        )
        .catch((err) => {
          console.log('no logo');
        });
    }
  }, []); //eslint-disable-line

  return logo;
};
