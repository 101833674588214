import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/shared/inputs/input';
import Radio, { RadioType } from '../../components/shared/inputs/radio';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table, { ColumnType } from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import OptionBar from '../../components/shared/optionBar';
import { Text } from '../../components/shared/text';
import { APP_URL, DEFAULT_DATE_FORMAT } from '../../project/defines';
import { getAppUrl } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import { useGetOrdersHistoryQuery } from '../../store/orders/ordersApi';
import { formatDate } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import { getStatusText } from './orderDetails';

const OrderHistory: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers_uuid = useAppSelector((state) => state.siteData?.universInfo?.univers?.uuid) ?? '';
  const societe_uuid = useAppSelector((state) => state.auth.societe_uuid);
  const [archive, setArchive] = useState(!!state?.archive);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (state?.archive !== archive) {
      navigate('', { replace: true, state: { archive } });
    }
  }, [archive]); //eslint-disable-line react-hooks/exhaustive-deps

  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const { data, isLoading, isFetching } = useGetOrdersHistoryQuery({ lang, univers_uuid, societe_uuid, archive });

  const columns: ColumnType[] = [
    {
      key: 'reference',
      title: t('commandes.monHistoriqueCommandes.reference'),
      dataIndex: 'reference',
      sorter: true,
      searchable: true,
      width: 250,
    },
    {
      key: 'status',
      title: t('commandes.monHistoriqueCommandes.etat'),
      dataIndex: 'status',
      sorter: true,
      searchable: true,
    },
    {
      key: 'type',
      title: t('commandes.monHistoriqueCommandes.type'),
      dataIndex: 'is_en_compte',
      sorter: true,
      width: 150,
      render: (value) =>
        t(!value ? 'commandes.monHistoriqueCommandes.types.cb' : 'commandes.monHistoriqueCommandes.types.enCompte'),
    },
    {
      key: 'date_creation_commande',
      title: t('commandes.monHistoriqueCommandes.dateCommande'),
      dataIndex: 'date_creation_commande',
      sorter: true,
      searchable: true,
      type: 'date',
      width: 150,
      render: (value) => formatDate(value, DEFAULT_DATE_FORMAT),
    },
    {
      key: 'updated_at',
      title: t('commandes.monHistoriqueCommandes.dateMAJ'),
      dataIndex: 'updated_at',
      type: 'date',
      searchable: true,
      sorter: true,
      width: 150,
      render: (value) => formatDate(value, DEFAULT_DATE_FORMAT),
    },
    {
      key: 'uuid',
      dataIndex: 'uuid',
      width: 60,
      render: (uuid: string) => (
        <group data-wrap="" data-gap="10">
          <Link className="button mini" to={getAppUrl(APP_URL.OrderDetails, { routeParams: { id: uuid } })}>
            {' '}
            <icon>zoom_in</icon>
          </Link>
        </group>
      ),
    },
  ];

  const orders = useMemo(() => {
    let result = data?.map((d: any) => ({ ...d, status: getStatusText(d.etat, t) })) ?? [];
    if (filter) {
      let filt = filter.toLocaleLowerCase();
      return result.filter((d: any) =>
        columns
          .filter((c) => c.searchable)
          .some((c) => {
            let val = d[c.dataIndex as string];
            return (c.type === 'date' ? formatDate(val || '') : (val ?? '').toLowerCase()).search(filt) !== -1;
          })
      );
    }
    return result;
  }, [data, filter]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section>
      <wrapper>
        <group data-space-vertical="20">
          <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
            commandes.monHistoriqueCommandes.libelle
          </Text>
        </group>
        <group data-space-vertical="20" data-gap="10">
          <Input
            icon="search"
            dataLength="forcefit"
            data-min-length="300"
            name="searchArticle"
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t('global.datatable.recherche')}
          />
          <OptionBar compact data-length="forcefit">
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="false"
              value={archive.toString()}
              onChange={() => {
                setArchive(false);
              }}
              tooltip={t('commandes.monHistoriqueCommandes.boutons.recentes.title')}
              label="commandes.monHistoriqueCommandes.boutons.recentes.label"
            />
            <Radio
              radioType={RadioType.Button}
              name="archive"
              radioValue="true"
              value={archive.toString()}
              onChange={() => {
                setArchive(true);
              }}
              tooltip={t('commandes.monHistoriqueCommandes.boutons.archivees.title')}
              label="commandes.monHistoriqueCommandes.boutons.archivees.label"
            />
          </OptionBar>
        </group>
        <group data-background="main-background" data-direction="column">
          <Table
            loading={isLoading || isFetching}
            keyField="uuid"
            setSorter={setSort}
            dataSource={orders}
            columns={columns}
            sort={sort}
            pagingProps={pagingProps.pagination}
            pagination={false}
            size={'small' as SizeType}
            bordered={true}
          />
          <PaginationToolbar {...pagingProps} total={orders?.length || 0} />
        </group>
      </wrapper>
    </section>
  );
};

export default OrderHistory;
