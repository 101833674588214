import { useMemo, useRef, useState } from 'react';
import EmptyPicture from '../../components/emptyPicture';
import Loader from '../../components/shared/loader';
import Popup from '../../components/shared/popup';
import { Text } from '../../components/shared/text';
import { useAppSelector } from '../../store/hooks';
import { Product } from '../../store/product/productModels';
import { useGetSavInfosByProductQuery } from '../../store/sav/savApi';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { getKeyUpHandler } from '../../utils/helpers';

const ProductInfo: React.FC<{ product: Product; forPopup?: boolean }> = ({ product, forPopup = false }) => {
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const lang = useAppSelector((state) => state.language.currentLanguage);

  let family = product.familles ? product.familles.filter((f) => !!f.image).shift() : null;
  const {
    data: savInfos,
    isLoading,
    isFetching,
  } = useGetSavInfosByProductQuery({ product_uuid: product.uuid, pays_uuid, lang });
  const warrantyInfo = useMemo(() => {
    return savInfos
      ?.flat()
      .find((i) => i.application_garantie)
      ?.application_garantie?.trim();
  }, [savInfos]);

  const groupProps = forPopup
    ? { 'data-space': '10', 'data-gap': '5', 'data-adaptive': '800', 'data-max-length': '800', 'data-scroll': '' }
    : { 'data-space': '10', 'data-gap': '5', 'data-adaptive': '800' };

  return (
    <group {...groupProps}>
      <group data-max-length="400" data-max-height="400" data-space="5" data-justify="center">
        <picture data-ratio="1:1" data-border="" data-radius="5">
          {family?.image ? <img src={family.image} alt={product.libelle} /> : <EmptyPicture size="xxx-large" />}
        </picture>
      </group>
      <group
        data-direction="column"
        data-gap="5"
        data-space="10"
        data-align="start"
        data-width="auto"
        data-length="autofit"
      >
        <group data-wrap="no" data-gap="10" data-adaptive="600">
          <text data-wrap="wrap" data-weight="700">
            [{product.reference}]
          </text>
          <text data-wrap="wrap">{product.libelle}</text>
        </group>
        <group data-wrap="no" data-gap="10" data-adaptive="600">
          <Text data-weight="700">garantie.demande.etapes.1.applicationTitre</Text>
          <Text data-wrap="wrap">{warrantyInfo || (isLoading || isFetching ? <Loader /> : 'N/A')}</Text>
        </group>
      </group>
    </group>
  );
};

export const ProductInfoButton: React.FC<{ product: Product }> = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refBtn = useRef(null);
  return (
    <>
      <a className="button medium" data-link="" ref={refBtn} onClick={() => setIsOpen(true)} onKeyUp={getKeyUpHandler()}>
        [{product.reference}] {product.libelle}
      </a>{' '}
      {/*eslint-disable-line*/}
      {isOpen && (
        <Popup closeOnOutsideClick refOpener={refBtn} noHeader onClose={() => setIsOpen(false)}>
          <ProductInfo forPopup product={product} />
        </Popup>
      )}
    </>
  );
};

export default ProductInfo;
