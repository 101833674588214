import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Mode } from '../components/shared/darkModeToggle';
import { popupRoot } from '../components/shared/popup';
import { LOCAL_STORAGE_KEYS } from '../project/defines';
import { getUniversFromUrl } from '../project/helpers';
import { ColorPalette, getSkinConfigByUnivers, SkinConfig } from '../styles/skin';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../utils/helpers/localStorage';

export type NotificationConfig = {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
  description: string;
  duration: number;
  timeout?: any;
};

interface ComponentsState {
  popupsCount: number;
  skinConfig: SkinConfig;
  mode?: Mode;
  satcMode?: boolean;
  satcVisible?: boolean;
  notification: NotificationConfig[];
  devisCount?: number;
  cartCount: number;
  showTarifMessage: boolean;
}

const initialState: ComponentsState = {
  popupsCount: 0,
  devisCount: 0,
  cartCount: 0,
  skinConfig: getSkinConfigByUnivers(getUniversFromUrl()),
  mode: localStorage.getItem(LOCAL_STORAGE_KEYS.Mode) as Mode,
  satcMode: getLocalStorageItem(LOCAL_STORAGE_KEYS.SatcMode)
    ? getLocalStorageItem(LOCAL_STORAGE_KEYS.SatcMode)?.toLowerCase() !== 'false'
    : false,
  satcVisible: getLocalStorageItem(LOCAL_STORAGE_KEYS.SatcVisible)
    ? getLocalStorageItem(LOCAL_STORAGE_KEYS.SatcVisible)?.toLowerCase() !== 'false'
    : false,
  notification: [],
  showTarifMessage: false,
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    addNotification(state, payload: PayloadAction<NotificationConfig>) {
      state.notification = [...state.notification, payload.payload];
    },
    clearNotifications(state) {
      state.notification = [];
    },
    setDevisCount(state, payload: PayloadAction<number>) {
      state.devisCount = payload.payload;
    },
    setCartCount(state, payload: PayloadAction<number>) {
      state.cartCount = payload.payload;
    },
    addPopup(state) {
      if (state.popupsCount === 0) {
        popupRoot.classList.add('open');
      }
      state.popupsCount++;
    },
    removePopup(state) {
      state.popupsCount--;
      if (state.popupsCount === 0) {
        popupRoot.classList?.remove('open');
      }
    },
    setColorTheme(state, payload: PayloadAction<ColorPalette>) {
      state.skinConfig.colorPalette = payload.payload;
    },
    setSkinConfig(state, payload: PayloadAction<SkinConfig>) {
      state.skinConfig = payload.payload;
    },
    setSatcMode(state, payload: PayloadAction<boolean>) {
      state.satcMode = payload.payload;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.SatcMode, payload.payload);
    },
    setSatcVisible(state, payload: PayloadAction<boolean>) {
      state.satcVisible = payload.payload;
      setLocalStorageItem(LOCAL_STORAGE_KEYS.SatcVisible, payload.payload);
    },
    logout(state) {
      state.satcMode = false;
      state.satcVisible = false;
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.SatcMode);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.SatcVisible);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
    },
    setMode(state, payload: PayloadAction<Mode>) {
      state.mode = payload.payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.Mode, state.mode);
    },
    setShowTarifMessage(state, payload: PayloadAction<boolean>) {
      state.showTarifMessage = payload.payload;
    },
  },
});

export const {
  addPopup,
  removePopup,
  setColorTheme,
  setSkinConfig,
  setMode,
  addNotification,
  clearNotifications,
  setSatcMode,
  setDevisCount,
  setSatcVisible,
  logout,
  setCartCount,
  setShowTarifMessage,
} = componentsSlice.actions;
export default componentsSlice.reducer;
