import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { LOCAL_STORAGE_KEYS } from '../project/defines';
import { getApiUrl, getUniversFromUrl } from '../project/helpers';
import { removeLocalStorageItem } from '../utils/helpers/localStorage';
import { reloadIsLoggedInFromStorage } from './auth/authSlice';

const baseInnerQuery = async (apiName: string, args: FetchArgs, api: any) => {
  const newArgs = { ...args };

  if (apiName !== 'translations' && apiName !== 'univers') {
    newArgs.params = { ...newArgs.params };
  }

  return fetchBaseQuery({
    baseUrl: `${getApiUrl(getUniversFromUrl())}/${apiName}`,
    credentials: 'include',
  })(newArgs, api, {});
};

const baseQuery = (apiName: string) => async (args: FetchArgs, api: any) => {
  const { dispatch } = api;

  let response = await baseInnerQuery(apiName, args, api);

  if (response.error?.status === 401 && args.url !== 'me') {
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
    dispatch(reloadIsLoggedInFromStorage());
  }

  return response;
};

export default baseQuery;
