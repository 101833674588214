import { Text } from '../../../components/shared/text';
import { formatNumberEuro } from '../../../project/helpers';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';

const WarrantyBasket: React.FC<{
  request: WarrantyRequest;
}> = ({ request }) => {
  let total = 0,
    totalEcoPart = 0,
    totalEcoContr = 0;

  return request?.articles?.length ? (
    <>
        <div
          //data-min-length="700"
          className="list_view_container cart"
          data-view="grid"
          //data-border=""
          data-radius="5"
        >
          <group className="list_view_scroller">
            <div className="list_view">
              <div className="title">
                <group data-align="center" data-wrap="no">
                  <group data-length="autofit" data-space="10">
                    <Text className="">garantie.demande.etapes.3.panier.article</Text>
                  </group>
                  <group data-length="80" data-space="10">
                    <Text data-position="center" className="">
                      garantie.demande.etapes.2.SelectArticles.qte
                    </Text>
                  </group>
                  {request.typedemande === 'commande' && (
                    <>
                      <group data-length="100" data-space="10">
                        <Text data-position="right" className="">
                          garantie.demande.etapes.3.panier.prixhtu
                        </Text>
                      </group>
                      <group data-length="80" data-space="10">
                        <Text data-position="right" className="">
                          garantie.demande.etapes.3.panier.ecopart
                        </Text>
                      </group>
                      <group data-length="80" data-space="10">
                        <Text data-position="right" className="">
                          garantie.demande.etapes.3.panier.eco_contr
                        </Text>
                      </group>
                      <group data-length="80" data-space="10"  data-sticky="right">
                        <Text data-position="right" className="">
                          garantie.demande.etapes.3.panier.total
                        </Text>
                      </group>
                    </>
                  )}
                </group>
              </div>



              
              {request?.articles?.map((article) => {
                let eco_contr = Math.max(
                  article.articleReseau.poids * article.articleReseau.pu_pmcb * article.pivot.quantite,
                  0.01
                );

                let _total =
                    parseFloat(article?.articleReseau?.prix_ht_remise?.toString?.() ?? '') * article.pivot.quantite +
                    article?.articleReseau?.deee +
                    eco_contr,
                  isAvg = request?.garantie_type?.isAvg && request.satc_attente !== 'refusee';

                total += isAvg ? 0 : _total;
                totalEcoPart += isAvg ? 0 : article?.articleReseau?.deee;
                totalEcoContr += isAvg ? 0 : eco_contr;

                const qte = article.pivot.quantite;

                return (
                  <div className="item" key={article.uuid}>
                    <group  data-align="center" data-space="10" data-gap="5" data-wrap="no">
                      <group data-space="10" data-gap="5" data-wrap="no" data-length="autofit">
                        <text data-weight="800">[{article.reference}]</text>
                        <text data-weight="800" data-wrap="wrap">
                          {article.libelle}
                        </text>
                      </group>
                      <group data-space="10" data-length="80" data-align="center" >
                        <text data-wrap="wrap" data-position="center">
                          {qte}
                        </text>
                      </group>

                      {request.typedemande === 'commande' && (
                        <>
                          <group data-space="10" data-length="100" data-position="left">
                            <Text data-position="right" className="">
                              {formatNumberEuro(isAvg ? 0 : article.articleReseau.prix_ht_remise ?? 0)}
                            </Text>
                          </group>
                          <group data-space="10" data-length="80">
                            <Text data-position="right" className="">
                              {formatNumberEuro(isAvg ? 0 : article.articleReseau.deee ?? 0)}
                            </Text>
                          </group>
                          <group data-space="10" data-length="80">
                            <Text data-position="right" className="">
                              {formatNumberEuro(isAvg ? 0 : eco_contr ?? 0)}
                            </Text>
                          </group>
                          <group data-space="10" data-length="80" data-sticky="right">
                            <Text data-position="right" className="">
                              {formatNumberEuro(isAvg ? 0 : _total)}
                            </Text>
                          </group>
                        </>
                      )}
                    </group>
                  </div>
                );
              })}

              <group>
                {request.typedemande === 'commande' && (
                  <group data-direction="column" data-wrap="no" data-space-vertical="20" data-gap="5">
                    <group data-wrap="no">
                      <group data-length="autofit" data-hide=""></group>

                      <group data-gap="10" data-wrap="no" data-width="auto" data-sticky="right">
                        <Text data-length="130" data-text-align="right" data-space-horizontal="10">
                          garantie.demande.etapes.3.panier.total
                        </Text>
                        <text data-length="80" data-text-align="right" data-space-horizontal="10">
                          {formatNumberEuro(total)}
                        </text>
                      </group>
                    </group>

                    <group data-wrap="no">
                      <group data-length="autofit" data-hide=""></group>

                      <group data-gap="10" data-wrap="no" data-width="auto" data-sticky="right">
                        <Text data-length="130" data-text-align="right" data-space-horizontal="10">
                          garantie.demande.etapes.3.panier.totalEcopart
                        </Text>
                        <text data-length="80" data-text-align="right" data-space-horizontal="10">
                          {formatNumberEuro(totalEcoPart)}
                        </text>
                      </group>
                    </group>
                    <group data-wrap="no">
                      <group data-length="autofit" data-hide=""></group>

                      <group data-gap="10" data-wrap="no" data-width="auto" data-sticky="right">
                        <Text data-length="130" data-text-align="right" data-space-horizontal="10">
                          garantie.demande.etapes.3.panier.totalEcocontr
                        </Text>
                        <text data-length="80" data-text-align="right" data-space-horizontal="10">
                          {formatNumberEuro(totalEcoContr)}
                        </text>
                      </group>
                    </group>
                    <group data-wrap="no">
                      <group data-length="autofit" data-hide=""></group>

                      <group data-gap="10" data-wrap="no" data-width="auto" data-sticky="right">
                        <Text data-length="130" data-text-align="right" data-space-horizontal="10">
                          garantie.demande.etapes.3.panier.tva
                        </Text>
                        <text data-length="80" data-text-align="right" data-space-horizontal="10">
                          {formatNumberEuro(total * 0.2)}
                        </text>
                      </group>
                    </group>

                    <group data-wrap="no">
                      <group data-length="autofit" data-hide=""></group>

                      <group data-gap="10" data-wrap="no" data-width="auto" data-sticky="right">
                        <Text data-length="130" data-text-align="right" data-space-horizontal="10">
                          garantie.demande.etapes.3.panier.totalttc
                        </Text>
                        <text data-length="80" data-text-align="right" data-space-horizontal="10">
                          {formatNumberEuro(total * 1.2)}
                        </text>
                      </group>
                    </group>
                  </group>
                )}
              </group>
            </div>
          </group>
        </div>      
      {request?.articles?.map((article) => {
        let _total = parseFloat(article?.articleReseau?.prix_ht_remise?.toString?.() ?? '') * article.pivot.quantite,
          isAvg = request?.garantie_type?.isAvg && request.satc_attente !== 'refusee';

        total += isAvg ? 0 : _total;
        const qte = article.pivot.quantite;
      })}
    </>
  ) : (
    <></>
  );
};

export default WarrantyBasket;
