import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/shared/loader';
import { Text } from '../../components/shared/text';
import { WarrantyRequestSteps } from '../../project/defines';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { useGetWarrantyRequestQuery } from '../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest as WarrantyRequestModel } from '../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../utils/hooks/useTranslation';
import Stage1 from './stage1';
import Stage2 from './stage2';
import Stage3 from './stage3';
import StageFinal from './stageFinal';
import StepsMenu from './stepsMenu';

const getCurrentStep = (
  request: WarrantyRequestModel,
  setRequest: (request: WarrantyRequestModel) => void,
  changeStep: (step: string) => void
) => {
  switch (request?.etape) {
    case WarrantyRequestSteps.etape2:
      return <Stage2 request={request} setRequest={setRequest} changeStep={changeStep} />;
    case WarrantyRequestSteps.etape3:
      return <Stage3 request={request} setRequest={setRequest} changeStep={changeStep} />;
    case WarrantyRequestSteps.etape_end:
      return <StageFinal request={request} />;
    default:
      return <Stage1 request={request} setRequest={setRequest} />;
  }
};

const WarrantyRequest: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const { state } = useLocation();
  const { reference } = useParams();

  const { data, isLoading, isError } = useGetWarrantyRequestQuery(
    {
      lang,
      univers_uuid: univers?.uuid ?? '',
      pays_uuid,
      demande_uuid: reference ?? '',
      domain: univers?.domain ?? '',
    },
    { skip: !reference || !pays_uuid || !univers, refetchOnMountOrArgChange: true }
  );

  const defaultRequest = {
    typedemande: state?.type,
    etape: WarrantyRequestSteps.etape1,
    uniqueID: state?.uuid,
  } as WarrantyRequestModel;
  const [request, setRequest] = useState<WarrantyRequestModel>(defaultRequest);

  const changeStep = (step: string, back?: boolean) => {
    setRequest({
      ...request,
      manual_back: back,
      etape: step,
    });
  };

  useEffect(() => {
    if (reference) {
      data &&
        setRequest(
          request?.manual_back
            ? ({ ...data, etape: request?.etape, manual_back: request.manual_back } as WarrantyRequestModel)
            : data
        );
    } else {
      setRequest(defaultRequest);
    }
  }, [data, reference]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state?.type) {
      setRequest(defaultRequest);
    }
  }, [state?.type]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (request.isLocked) {
      dispatch(
        addNotification({
          type: 'error',
          message: t(`garantie.demande.modal.titre`),
          description: t(`garantie.errors.locked`),
          duration: 5,
        })
      );
    }
  }, [request]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section>
      {reference && isLoading && <Loader />}
      <wrapper>
        {!isError ? (
          <view data-border="none">
            <group data-space-vertical="20">
              {(data?.typedemande || request?.typedemande) && (
                <Text dataWeight="700" accent dataTextSize="large" data-wrap="wrap">{`garantie.demande.etapes.titre.${
                  data?.typedemande ?? request?.typedemande
                }`}</Text>
              )}
            </group>
            <StepsMenu request={request} />
            {!isLoading && getCurrentStep(request, setRequest, changeStep)}
          </view>
        ) : (
          <Text>Invalid Request</Text>
        )}
      </wrapper>
    </section>
  );
};

export default WarrantyRequest;
