import { Dispatch, SetStateAction } from 'react';
import { WarrantyArticleLine } from '.';
import Button from '../../../components/shared/button';
import Separator from '../../../components/shared/separator';
import { Text } from '../../../components/shared/text';
import { Article } from '../../../store/article/articleModels';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';

enum QuantityChangeType {
  add,
  remove,
}

const WarrantySelectedArticles: React.FC<{
  request: WarrantyRequest;
  show: boolean;
  selectedArticles: WarrantyArticleLine[];
  setSelectedArticles: Dispatch<SetStateAction<WarrantyArticleLine[]>>;
}> = ({ selectedArticles, setSelectedArticles, show }) => {
  const onRemoveArticle = (article: Article) => {
    setSelectedArticles(selectedArticles.filter((articleLine) => articleLine.article.uuid !== article.uuid));
  };
  const onChangeQuantity = (article: Article, actionType: QuantityChangeType) => {
    let articleIndex = selectedArticles.findIndex((a) => a.article.uuid === article.uuid);
    if (articleIndex !== -1) {
      const articleLine = selectedArticles[articleIndex];
      let coeff = 1;
      if (actionType === QuantityChangeType.add) {
        if (articleLine.qte >= article.quantite_sur_schema) {
          return;
        }
      } else {
        if (articleLine.qte <= 1) {
          return;
        }
        coeff = -1;
      }
      const newItems = [...selectedArticles];
      newItems.splice(articleIndex, 1, { article, qte: articleLine.qte + coeff });

      setSelectedArticles(newItems);
    }
  };

  return (
    <view data-border="none" data-scroll="" data-space="5" data-auto-height="">
      {show && selectedArticles?.length ? (
        <div className="list_view_container cart" data-view="grid">
          <group className="list_view_scroller">
            <div className="list_view">
              <div className="title" data-adaptive="desktop">
                <group data-align="center" data-space="10" data-gap="10" data-wrap="no">
                  <group data-direction="column" data-contain="" data-max-length="fit">
                    <Text className="">garantie.demande.etapes.2.SelectArticles.article</Text>
                  </group>
                  <group data-align="center" data-wrap="no" data-length="120">
                    <Text className="">garantie.demande.etapes.2.SelectArticles.qte</Text>
                  </group>
                  <group data-width="auto" data-space="15"></group>
                </group>
              </div>
              {selectedArticles.map((article) => (
                <ArticleLineItem
                  key={article.article.uuid}
                  articleLine={article as any}
                  onRemoveArticle={onRemoveArticle}
                  onChangeQuantity={onChangeQuantity}
                />
              ))}
            </div>
          </group>
        </div>
      ) : (
        <></>
      )}
    </view>
  );
};

export const ArticleLineItem: React.FC<{
  articleLine: WarrantyArticleLine;
  onRemoveArticle: (article: Article) => void;
  onChangeQuantity: (article: Article, minusOrPlus: QuantityChangeType) => void;
}> = ({ articleLine, onRemoveArticle, onChangeQuantity }) => {
  const { article, qte } = articleLine;
  return (
    <div className="item">
      <group data-align="center" data-space="10" data-gap="5" data-wrap="no">
        <group data-gap="10">
          <group
            data-width="auto"
            data-direction="column"
            data-contain=""
            data-max-length="200"
            data-position="left"
            data-length="forcefit"
          >
            <group data-gap="5">
              <text data-weight="700">[{article.reference}]</text>
              <text data-weight="700" data-wrap="wrap">
                {article.libelle}
              </text>
            </group>
          </group>
          <group data-align="center" data-wrap="no" data-length="120">
            <label className="field small" data-label="left" data-multi-element="" data-length="100" data-="true">
              <div className="form_fields">
                <div className="field_cont">
                  <Button
                    micro
                    material
                    icon="remove"
                    disabled={qte <= 1}
                    onClick={() => onChangeQuantity(article, QuantityChangeType.remove)}
                  />
                  <Separator vertical />
                  <input
                    data-appearance="none"
                    readOnly
                    type="number"
                    data-text-align="center"
                    min="1"
                    max={article.quantite_sur_schema}
                    value={qte}
                    onChange={() => {}}
                  />
                  <Separator vertical />
                  <Button
                    micro
                    material
                    icon="add"
                    disabled={qte >= article.quantite_sur_schema}
                    onClick={() => onChangeQuantity(article, QuantityChangeType.add)}
                  />
                  <i></i>
                </div>
              </div>
            </label>
          </group>
        </group>
        <group data-align="center" data-wrap="no" data-width="auto" data-gap="10">
          <Button mini material onClick={() => onRemoveArticle(article)}>
            <icon>delete</icon>
          </Button>
        </group>
      </group>
    </div>
  );
};

export default WarrantySelectedArticles;
