import classNames from 'classnames';
import { RefObject, useEffect, useMemo, useState } from 'react';
import Loader from '../../../../components/shared/loader';
import { Text } from '../../../../components/shared/text';
import { Article } from '../../../../store/article/articleModels';
import { addNotification } from '../../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useLazySearchWarrantyArticleQuery } from '../../../../store/search/searchApi';
import { currentCountryUuid } from '../../../../store/sideData/siteDataSlice';
import { WarrantyRequest } from '../../../../store/warrantyRequest/warrantyRequestModels';
import useOpener from '../../../../utils/hooks/useOpener';
import useTranslation from '../../../../utils/hooks/useTranslation';

const ArticleSearchAutocomplete: React.FC<{
  request: WarrantyRequest;
  onSelect: (article: Article) => void;
  isArticleSelected: (article: Article) => boolean;
}> = ({ request, onSelect, isArticleSelected }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const { open, close, Wrapper, refOpener, refWrapper } = useOpener('bottomForce', true, false, true);
  const [triggerSearch, response] = useLazySearchWarrantyArticleQuery({});
  const [searchResult, setSearchResult] = useState<any>(null);

  const handleResult = (result: any) => {
    if (result) {
      const { isFetching, isLoading, isSuccess, isError, error, data } = result;
      if (isSuccess && !isFetching && !isLoading) {
        setSearchResult(data);
      } else {
        setSearchResult(null);
        if (isError) {
          dispatch(
            addNotification({
              type: 'error',
              message: 'Error',
              description: error?.data?.error,
              duration: 5,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    response?.isFetching && open();
  }, [response?.isFetching]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChange = async (e: any) => {
    if (e.target.value.length >= 3) {
      let keywords = e.target.value;
      const result = await triggerSearch(
        {
          product_uuid: request.produit_uuid!,
          reseau_commercial_uuid: request.reseau_uuid!,
          keywords,
          lang,
          pays_uuid,
          univers: univers?.uuid!,
          limit: 10,
        },
        true
      );
      handleResult(result);
    } else {
      setSearchResult(null);
    }
  };

  const itemRender = (item: Article, allowSelect: boolean) => {
    return (
      <group
        key={`${item.uuid}`}
        {...(allowSelect && !isArticleSelected(item) ? { 'data-interactive': '' } : { 'data-light': '' })}
        data-radius="5"
        data-align="center"
        data-space="10"
        data-gap="5"
        data-wrap="no"
        onClick={() => {
          if (allowSelect && !isArticleSelected(item)) {
            onSelect(item);
            close();
          }
        }}
        className={classNames('item')}
      >
        <group data-direction="column" data-contain="" data-max-length="fit" data-gap="5">
          <group data-gap="5">
            <text data-weight="700" data-digit="">
              {`[${item.reference}]`}
            </text>
            <text>{item.libelle}</text>
          </group>
        </group>
      </group>
    );
  };

  const availableArticles = useMemo(
    () =>
      searchResult?.data.filter(
        (article: Article) =>
          article.articleReseau.isCommandable && article.articleReseau.reseau_uuid === request.reseau_uuid
      ),
    [searchResult, request]
  );
  const unAvailableArticles = useMemo(
    () =>
      searchResult?.data.filter(
        (article: Article) =>
          !article.articleReseau.isCommandable && article.articleReseau.reseau_uuid === request.reseau_uuid
      ),
    [searchResult, request]
  );

  return (
    <>
      <div className="field" data-length="auto" data-multi-element="">
        <div ref={refOpener as RefObject<HTMLDivElement>} className="form_fields">
          <div className="field_cont">
            <input
              type="text"
              placeholder={t('garantie.demande.etapes.1.placeholder')}
              onChange={onChange}
              onClick={() => {
                searchResult?.data && open();
              }}
            />
            <i></i>
          </div>
        </div>
      </div>
      <Wrapper>
        <group data-space="10" data-background="main-background" data-border="" ref={refWrapper}>
          {response?.isFetching && <Loader />}
          {searchResult?.data?.length === 0 ? (
            <Text light>recherche.noResultats</Text>
          ) : (
            <>
              {availableArticles?.length > 0 && (
                <div className="list_view" data-direction="column">
                  <group data-space-horizontal="20" data-gap="10">
                    <Text data-light="">garantie.demande.etapes.2.autocomplete.disponible</Text>
                  </group>
                  {availableArticles.map((item: Article) => itemRender(item, true))}
                </div>
              )}
              {unAvailableArticles?.length > 0 && (
                <div className="list_view" data-direction="column">
                  <group data-space-horizontal="20" data-gap="10">
                    <Text data-light="">garantie.demande.etapes.2.autocomplete.indisponible</Text>
                  </group>
                  {unAvailableArticles.map((item: Article) => itemRender(item, false))}
                </div>
              )}
            </>
          )}
        </group>
      </Wrapper>
    </>
  );
};

export default ArticleSearchAutocomplete;
