import { NavigationItemProps } from '../../components/shared/navigation/navigationItem';
import { APP_URL, SLUG_ACCESS_KEYS } from '../../project/defines';
import { getAppUrl } from '../../project/helpers';
import { SvgThermorContact, SvgThermorForum, SvgThermorHelp, SvgThermorHome } from './components/svg';

export const HEADER_MENU_NAVIGATION_DATA: NavigationItemProps[] = [
  {
    text: 'thermor.appmenu.home',
    svg: <SvgThermorHome />,
    url: 'https://www.thermor-pro.fr/',
  },
  {
    text: 'thermor.appmenu.faq',
    svg: <SvgThermorHelp />,
    url: 'https://assistance-pro.thermor-pro.fr/hc/fr',
  },
  {
    text: 'thermor.appmenu.forumpro',
    svg: <SvgThermorForum />,
    url: 'https://assistance-pro.thermor-pro.fr/hc/fr/community/topics',
  },
  {
    text: 'thermor.appmenu.contact',
    svg: <SvgThermorContact />,
    url: 'https://www.thermor-pro.fr/la-marque-thermor/les-femmes-et-les-hommes-thermor',
  },
];

export const HOME_NAVIGATION_DATA: NavigationItemProps[] = [
  {
    text: 'Demande de garantie',
  },
  {
    text: 'Aide',
  },
];

export enum MenuKeys {
  Commande = 'commande',
  Warranty = 'warranty',
  Devis = 'devis',
  Panier = 'panier',
}

export const HEADER_TOOLBAR_NAVIGATION_DATA: NavigationItemProps[] = [
  {
    key: MenuKeys.Warranty,
    accessKey: SLUG_ACCESS_KEYS.Sav,
    icon: 'event_available',
    text: 'global.menuUtilisateur.boutonGarantie.texte',
    link: getAppUrl(APP_URL.WarrantyHistory),
  },
  {
    key: MenuKeys.Commande,
    accessKey: SLUG_ACCESS_KEYS.Commande,
    icon: 'euro_symbol',
    text: 'global.menuUtilisateur.boutonCommandes.texte',
    link: getAppUrl(APP_URL.OrderHistory),
  },
  {
    key: MenuKeys.Devis,
    icon: 'article',
    text: 'global.menuUtilisateur.boutonDevis.texte',
    link: getAppUrl(APP_URL.QuoteHistory),
  },
  {
    key: MenuKeys.Panier,
    accessKey: SLUG_ACCESS_KEYS.Commande,
    icon: 'shopping_basket',
    text: 'global.menuUtilisateur.boutonPanier.texte',
    link: getAppUrl(APP_URL.ShoppingCart),
    count: 0,
  },
];
