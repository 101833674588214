import Button from '../../components/shared/button';
import { getDocGARight } from '../../project/helpers';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { copyToClipboard } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';


const TabFichesDefaut: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { fiches_defaut } = data;
  const { t } = useTranslation();

  return (
    <group data-space="5" data-space-vertical="20" data-align="start">
      <div className="table_view" data-length="autofit">
        <table>
          <thead>
            <tr>
              <th><text>{t("satc.detail.tabs.ficheDefaut.first")}</text></th>
              <th><text>{t("satc.detail.tabs.ficheDefaut.second")}</text></th>
              <th><text>{t("satc.detail.tabs.ficheDefaut.third")}</text></th>
              <th><text>{t("satc.detail.tabs.ficheDefaut.fourth")}</text></th>
              <th data-sticky="right"><text>{t("satc.detail.tabs.videoTutos.second")}</text></th>
            </tr>
          </thead>
          <tbody>
            {fiches_defaut.docga.map((item: any) => {
              let tags = getDocGARight(item.tags);
              return (
                <tr key={item.uuid}>
                  <td>
                    <wrap>
                      <a                        
                        data-type="link"
                        href={item.download}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <text data-clamp="2" data-wrap="wrap">{item.title}</text>
                        
                      </a>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">{item.num_serie_debut}</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">{item.num_serie_fin}</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">{tags.join(", ")}</text>
                    </wrap>
                  </td>
                  <td data-sticky="right">
                    <Button
                      extra
                      onClick={() => copyToClipboard(t, item.download)}
                    >
                      <icon>content_copy</icon>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </group>
  );
};

export default TabFichesDefaut;
