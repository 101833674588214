// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import { Zone } from './productModels';

export const zoneApi = createApi({
    reducerPath: 'zoneApi',
    baseQuery: baseQuery('zones'),
    endpoints: (builder) => {
        return {
            getZone: builder.query<Zone[], any>({
                query: ({ univers_uuid, lang, pays_uuid, id, schema_uuid, reseau_uuid }) => {
                    return {
                        url: `${id}/${schema_uuid}`,
                        method: 'get',
                        params: {
                            univers_uuid,
                            lang,
                            pays_uuid,
                            reseau_uuid,
                        }
                    };
                },
            }),
        };
    },
});

export const { useGetZoneQuery } = zoneApi;
