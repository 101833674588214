import { Article } from '../../store/article/articleModels';
import { Text } from '../shared/text';
import ArticleAvailabilityWidget from './articleAvailabilityWidget';

const ArticleInfoHeader: React.FC<{ article: Article; isWidget?: boolean }> = ({ article, isWidget }) => {
  return (
    <>
      <Text data-wrap="wrap" data-user-select="text">
        [{article?.reference}]{article?.refcom2 ? '-[' + article.refcom2 + ']' : ''}
      </Text>
      <Text data-wrap="wrap" data-weight="700" data-user-select="text">
        {article?.libelle || ''}
      </Text>
      <Text data-wrap="wrap" data-light="">
        {article?.articleReseau ? article.articleReseau.reseau?.nom : ''}
      </Text>
      <ArticleAvailabilityWidget article={article} isWidget={isWidget} />
    </>
  );
};

export default ArticleInfoHeader;
