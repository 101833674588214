import { useEffect, useState } from 'react';
import Loader from '../../components/shared/loader';
import { Text } from '../../components/shared/text';
import { SvgLoader } from '../../components/svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetCartItemsQuery } from '../../store/shoppingCart/shoppingCartApi';
import { setCartData } from '../../store/shoppingCart/shoppingCartSlice';
import ArticleCartSearch from './articleCartSearch';
import ReseauCart from './reseauCart';
import Popup from '../../components/shared/popup';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_URL } from '../../project/defines';
import ShoppingCartSuccessPopupContent from './shoppingCartSuccessPopupContent';
import useTranslation from '../../utils/hooks/useTranslation';
import { getAppUrl } from '../../project/helpers';

const ShoppingCart: React.FC = () => {
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const [panierResult, setPanierResult] = useState<string | null>(null);

  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const cartData: any = useAppSelector((state) => state.shoppingCart.cartData);

  const {
    data: result,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetCartItemsQuery({ lang, univers_uuid: univers?.uuid });

  useEffect(() => {
    if (result && isSuccess && !isLoading && !isFetching) {
      dispatch(setCartData(result));
    }
  }, [result, dispatch, isLoading, isFetching, isSuccess]);

  useEffect(() => {
    params?.result_status && setPanierResult(params?.result_status);
  }, [params?.result_status]);

  return (
    <>
      {panierResult && (
        <Popup
          title={t('panier.resultModal.title')}
          onClose={() => {
            setPanierResult(null);
            navigate(getAppUrl(APP_URL.ShoppingCart), { replace: true });
          }}
        >
          <ShoppingCartSuccessPopupContent
            resultStatus={panierResult}
            onClose={() => {
              setPanierResult(null);
              navigate(getAppUrl(APP_URL.ShoppingCart), { replace: true });
            }}
          />
        </Popup>
      )}
      {(isLoading || isFetching) && !cartData && <Loader isSticky />}
      <section>
        <wrapper data-gap="15">
          <group data-align="center" data-gap="15">
            <Text dataWeight="700" accent dataTextSize="medium" dataWrap="wrap">
              panier.title
            </Text>
            {(isLoading || isFetching) && cartData && (
              <icon>
                <SvgLoader />
              </icon>
            )}
          </group>
          <group data-gap="15">
            <group data-gap="15" data-length="forcefit" data-width="auto" data-fit="1" data-direction="column">
              <group data-gap="10" data-align="center">
                <Text data-weight="700" data-text-size="medium" data-wrap data-color="main">
                  panier.panierEnCours.title
                </Text>

                <ArticleCartSearch />
              </group>

              {cartData?.paniers && !!Object.keys(cartData?.paniers).length ? (
                Object.keys(cartData?.paniers).map((key) => {
                  return <ReseauCart key={key} cartByReseau={cartData?.paniers[key]} reseauUuid={key} />;
                })
              ) : (
                <group
                  data-space="40"
                  data-align="center"
                  data-justify="center"
                  data-direction="column"
                  data-border=""
                  data-radius="10"
                  data-gap="10"
                >
                  <group data-width="auto">
                    <icon data-icon-size="large" data-icon-weight="500" data-opacity="light">
                      shopping_basket
                    </icon>
                  </group>
                  <Text dataWeight="700">panier.emptyCart</Text>
                </group>
              )}
            </group>

            {cartData?.en_attente && !!Object.keys(cartData?.en_attente).length && (
              <group data-gap="15" data-length="forcefit" data-max-length="600" data-wrap="no" data-direction="column">
                <group data-radius="10" data-align="center">
                  <Text data-weight="700" data-text-size="medium" data-wrap data-color="main">
                    panier.articlesEnAttente.title
                  </Text>
                  <group data-width="auto" data-space="20"></group>
                </group>
                <group data-gap="15" data-length="forcefit" data-width="auto" data-direction="column" data-fit="1">
                  {cartData?.en_attente &&
                    Object.keys(cartData?.en_attente).map((key) => {
                      return (
                        <ReseauCart isPending key={key} cartByReseau={cartData?.en_attente[key]} reseauUuid={key} />
                      );
                    })}
                </group>
              </group>
            )}
          </group>
        </wrapper>
      </section>
    </>
  );
};

export default ShoppingCart;
