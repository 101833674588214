import React, { useState } from 'react';
import Button from '../../components/shared/button';
import Popup from '../../components/shared/popup';

const PopupDemo: React.FC<
  React.PropsWithChildren<{
    fullscreen?: boolean;
    title?: string;
    buttonText?: string;
    coloredTitle?: boolean;
  }>
> = ({ children, fullscreen = false, title, buttonText, coloredTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button large outline onClick={() => setIsOpen(true)}>
        <text>{buttonText ?? `Open ${fullscreen ? 'Full Screen' : ''} Window`}</text>
      </Button>
      {isOpen && (
        <Popup
          title={title ?? 'popup Demo'}
          fullscreen={fullscreen}
          onClose={() => setIsOpen(false)}
          coloredTitle={coloredTitle}
        >
          {children}
        </Popup>
      )}
    </>
  );
};
export default PopupDemo;
