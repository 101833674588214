import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import Loader from '../../../components/shared/loader';
import { Text } from '../../../components/shared/text';
import { WarrantyStage2FormFields, WarrantyRequestSteps, DATE_FORMAT_ARRAY } from '../../../project/defines';
import { ApiErrorHandler } from '../../../store/apiErrorHandler';
import { Article } from '../../../store/article/articleModels';
import { addNotification } from '../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Product } from '../../../store/product/productModels';
import { currentCountryUuid } from '../../../store/sideData/siteDataSlice';
import { useCreateOrUpdateWarrantyRequestMutation } from '../../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest, WarrantyRequestUpdateModel } from '../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../utils/hooks/useTranslation';
import { ProductInfoButton } from '../productInfo';
import StepButtons from '../stepButtons';
import WarrantyArticleSelection from './articleSelection';
import WarrantySelectedArticles from './selectedArticles';
import WarrantyForm from './warrantyForm';
import dayjs from 'dayjs';

export interface WarrantyArticleLine {
  article: Article;
  qte: number;
}
const Stage2: React.FC<{
  request: WarrantyRequest;
  setRequest: (request: WarrantyRequest) => void;
  changeStep: (step: string, back?: boolean) => void;
}> = ({ request, changeStep, setRequest }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [createOrUpdateDemande, { isLoading }] = useCreateOrUpdateWarrantyRequestMutation();

  const [selectedArticles, setSelectedArticles] = useState<WarrantyArticleLine[]>([
    ...(request.articles ?? []).map((article) => {
      return {
        article: article,
        qte: article.pivot.quantite,
      };
    }),
  ]);
  const [getFormValues, setGetFormValues] = useState<() => FieldValues>(() => () => ({} as any));
  const [isFormValid, setIsFormValid] = useState(false);
  const [renvoieProduit, setRenvoieProduit] = useState(false);
  const [renvoiePiece, setRenvoiePiece] = useState(false);

  const isValidNextStep = () => {
    let valideArticles = renvoieProduit || (renvoiePiece && selectedArticles?.length > 0);
    return isFormValid && valideArticles;
  };

  const onClickNextStep = async () => {
    if (isValidNextStep()) {
      const formValues = getFormValues();
      const data: WarrantyRequestUpdateModel = {
        uuid: request.uuid,
        etape: WarrantyRequestSteps.etape3,
        univers_uuid: univers?.uuid ?? '',
        pays_uuid: pays_uuid,
        ...formValues,
        ...(formValues.date_mise_en_service
          ? { date_mise_en_service: dayjs(formValues.date_mise_en_service, DATE_FORMAT_ARRAY[0]).format('YYYY-MM-DD') }
          : null),
      };

      if (formValues.type_renvoie === WarrantyStage2FormFields.renvoie_piece) {
        data.articles = selectedArticles.map((articleCommande) => {
          return {
            garantie_demande_uuid: request.uuid!,
            article_uuid: articleCommande.article.uuid,
            quantite: articleCommande.qte,
          };
        });
      }
      try {
        let result = await createOrUpdateDemande(data).unwrap();
        dispatch(
          addNotification({
            type: 'success',
            message: t(`global.notifications.demande.updateTitle`),
            description: t(`global.notifications.demande.updateMessage`),
            duration: 5,
          })
        );
        setRequest({ ...result, produit: request.produit });
      } catch (error: any) {
        ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
      }
    }
  };

  return (
    <section data-space="5">
      {isLoading && <Loader />}
      <wrapper>
        <group
          data-align="center"
          //data-wrap="no"
          data-width="auto"
          data-space="10"
          data-gap="10"
        >
          <Text>garantie.demande.etapes.2.titre</Text>
          <ProductInfoButton product={request.produit as Product} />
        </group>

        <view data-vertical="" data-border="none" data-adaptive="1300" data-gap="40">
          {request && (
            <>
              <WarrantyForm
                request={request}
                setGetFormValues={setGetFormValues}
                setIsFormValid={setIsFormValid}
                setRenvoiePiece={setRenvoiePiece}
                setRenvoieProduit={setRenvoieProduit}
              />
              <WarrantyArticleSelection
                show={renvoiePiece}
                request={request}
                selectedArticles={selectedArticles}
                setSelectedArticles={setSelectedArticles}
              />
              <WarrantySelectedArticles
                show={renvoiePiece}
                request={request}
                selectedArticles={selectedArticles}
                setSelectedArticles={setSelectedArticles}
              />
            </>
          )}
        </view>
        <StepButtons
          request={request}
          disableRight={!isValidNextStep()}
          onClickLeft={() => changeStep(WarrantyRequestSteps.etape1, true)}
          onClickRight={onClickNextStep}
        />
      </wrapper>
    </section>
  );
};

export default Stage2;
