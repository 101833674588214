import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbWidget from '../../components/breadcrumbWidget';
import ProductInfo from '../../components/productInfo';
import Loader from '../../components/shared/loader';
import SupportWidget, { SupportWidgetType } from '../../components/support';
import { APP_URL } from '../../project/defines';
import { getAppUrl } from '../../project/helpers';
import { setProduct } from '../../store/analyticsSlice';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetProductQuery } from '../../store/product/productApi';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import useTranslation from '../../utils/hooks/useTranslation';
import { useUpdateProductHistoryMutation } from '../../store/productHistory/productHistoryApi';
import SatcFamilyNav from './../family/satcFamilyNav';

const Product: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, schema_uuid, reseau_uuid } = useParams();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const authUser = useAppSelector((state) => state.auth);
  const isSatcVisible = useAppSelector((state) => state.components.satcVisible) && !!authUser?.uuid;
  const isSatc = useAppSelector((state) => state.components.satcMode);

  const { data: product, error } = useGetProductQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id, schema_uuid: schema_uuid ?? '' },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang }
  );
  const [updateHistory] = useUpdateProductHistoryMutation();

  useEffect(() => {
    if (product && univers?.uuid && lang && reseau_uuid && authUser.uuid) {
      updateHistory({
        univers_uuid: univers?.uuid || '',
        lang,
        reseau_uuid: reseau_uuid ?? '',
        produit_uuid: product?.uuid ?? '',
      });
    }
  }, [updateHistory, product, univers?.uuid, lang, reseau_uuid, authUser.uuid]);

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({ type: 'warning', message: 'Produit', description: t((error as any).data.error), duration: 5 })
      );

      navigate(getAppUrl(APP_URL.HomePage), { replace: true });
    }
  }, [error, navigate, t, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(setProduct(product));
    }
  }, [product, dispatch]);

  return (
    <section>
      {product?.nomenclature && product.nomenclature.schemas ? (
        <wrapper>
          <BreadcrumbWidget />
          {isSatcVisible && isSatc && <SatcFamilyNav />}
          <SupportWidget type={SupportWidgetType.Product} accessoires={product?.accessoires || []} />
          <space data-height="20"></space>
          <group>
            <ProductInfo
              id={id}
              key={`${schema_uuid}-${product?.uuid}`}
              schema_uuid={schema_uuid}
              reseau_uuid={reseau_uuid}
              selectable={false}
            />
          </group>
        </wrapper>
      ) : (
        <Loader />
      )}
    </section>
  );
};
export default Product;
