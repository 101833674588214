import React from 'react';
import Button from '../../components/shared/button';
import Scroll from '../../components/shared/scroll';
import { setColorTheme } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPalette } from '../../styles/skin';

const ThemePicker: React.FC = () => {
  const dispatch = useAppDispatch();

  const { colorPrimary, colorSecondary } = useAppSelector((state) => state.components.skinConfig.colorPalette);
  const onSelectPrimary = (color: string) => dispatch(setColorTheme(getPalette(color, colorSecondary)));
  const onSelectSecondary = (color: string) => dispatch(setColorTheme(getPalette(colorPrimary, color)));

  const colors = [
    '#934f9a',
    '#598b7f',
    '#679480',
    '#adbb88',
    '#ebd187',
    '#e89468',
    '#db6b5d',
    '#ff705e',
    '#ca7163',
    '#e27f6a',
    '#eca177',
  ];
  const seccolors = ['#464543', '#787a79', '#b1b7b7', '#ae9473', '#cac8bc', '#e0d9d1'];
  return (
    <Scroll>
      <group data-wrap="no" data-align="center">
        <text data-space="20">Theme Primary Color</text>
        {colors.map((c) => (
          <Button
            style={{ ['--main-color-lighter' as string]: `${c}75` }}
            key={c}
            outline={colorPrimary === c}
            mini
            rounded
            material
            onClick={() => onSelectPrimary(c)}
          >
            <icon>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <circle cx="10" cy="10" r="10" fill={c}></circle>
              </svg>
            </icon>
          </Button>
        ))}
        <text data-space="20">Secondary Color</text>
        {seccolors.map((c) => (
          <Button
            style={{ ['--main-color-lighter' as string]: `${c}75` }}
            key={c}
            outline={colorSecondary === c}
            mini
            rounded
            material
            onClick={() => onSelectSecondary(c)}
          >
            <icon>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <circle cx="10" cy="10" r="10" fill={c}></circle>
              </svg>
            </icon>
          </Button>
        ))}
      </group>
    </Scroll>
  );
};
export default ThemePicker;
