import classNames from 'classnames';
import { Text } from '../../components/shared/text';
import { WarrantyRequestSteps } from '../../project/defines';
import { WarrantyRequest } from '../../store/warrantyRequest/warrantyRequestModels';

const getIsActiveStep = (step: string, request?: WarrantyRequest) => {
  return (request?.etape ?? WarrantyRequestSteps.etape1) === step;
};

const StepsMenu: React.FC<{ request?: WarrantyRequest }> = ({ request }) => {
  if (request?.etape === WarrantyRequestSteps.etape_end) {
    return <></>;
  }
  return (
    <group data-name="breadcrumbs" data-adaptive="900" data-direction="row" data-height="50">
      {Object.keys(WarrantyRequestSteps)
        .filter((k) => k !== WarrantyRequestSteps.etape_end)
        .map((s: string) => (
          <group
            data-length="autofit"
            data-background="highlight"
            key={s}
            className={classNames({
              selected: getIsActiveStep((WarrantyRequestSteps as Record<string, string>)[s], request),
            })}
          >
            <Text data-weight="700">{`garantie.demande.etapes.titres.${s}`}</Text>
          </group>
        ))}
    </group>
  );
};

export default StepsMenu;
