import { FC } from 'react';
import { Link } from 'react-router-dom';
import ArticleWidget from '../../components/article/articleWidget';
import FamilyWidget from '../../components/familyWidget';
import ProductWidget from '../../components/productWidget';
import ListView, { LazyLoadPropTypes } from '../../components/shared/list/listView';
import { ListViewType } from '../../components/shared/list/useListHook';
import { Text } from '../../components/shared/text';
import { Article } from '../../store/article/articleModels';
import { Family } from '../../store/family/familyModels';
import { Product } from '../../store/product/productModels';
import { SearchFilterType, SearchItem } from '../../store/search/searchModels';

const SearchListItem: FC<{ data: any; type: SearchFilterType }> = ({ data, type }) => {
  switch (type) {
    case SearchFilterType.produits:
      return <ProductWidget product={data as Product} />;
    case SearchFilterType.articles:
      return <ArticleWidget article={data as Article} />;
    case SearchFilterType.familles:
      return <FamilyWidget family={data as Family} />;
  }
};

const SearchResultList: React.FC<{
  type: SearchFilterType;
  titleLabel: string;
  data: SearchItem;
  isSelected: boolean;
  setType: (type: SearchFilterType) => void;
  lazyLoadProps?: LazyLoadPropTypes;
}> = ({ data, titleLabel, type, isSelected, setType, lazyLoadProps }) => {
  return (
    <>
      {data && (
        <group data-align="center">
          <group data-gap="5" data-align="center" data-space="10">
            <Text data-weight="600" translateArgs={{ total: data.total ?? 0 }}>
              {titleLabel}
            </Text>
            {!isSelected && (
              <Link
                to=""
                className="button"
                data-position="right"
                onClick={(e) => {
                  e.preventDefault();
                  setType(type);
                }}
              >
                <Text>recherche.allResultats</Text>
              </Link>
            )}
          </group>
          <ListView
            {...(type === SearchFilterType.familles
              ? { keyField: 'uuid' }
              : type === SearchFilterType.articles
              ? { getKey: (data: Article) => data.articleReseau?.uuid }
              : { getKey: (data: Product) => `${data.uuid}-${data.reseau?.uuid}` })}
            data-template={type !== SearchFilterType.articles ? '200' : '300'}
            view={ListViewType.Block}
            scrollerProps={{ 'data-space': 10 }}
            listProps={{ 'data-gap': 10 }}
            dataSource={data?.data || []}
            getItemElement={({ data }) => <SearchListItem type={type} data={data} />}
            lazyLoadProps={lazyLoadProps}
          />
        </group>
      )}
    </>
  );
};

export default SearchResultList;
