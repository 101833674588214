import { PropsWithChildren } from 'react';

export interface BadgeProps {
  text: string;
  color?: string;
  filled?: boolean;
  outline?: boolean;
}

const Badge: React.FC<PropsWithChildren<BadgeProps>> = ({ text, color, filled, outline = true }) => {
  const badgeProps: any = {};
  color && (badgeProps['data-color'] = color);
  filled && (badgeProps['data-filled'] = '');
  outline && !filled && (badgeProps['data-outline'] = '');

  return <badge {...badgeProps}>{text}</badge>;
};

export default Badge;
