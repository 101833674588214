import React, { Suspense } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import './configs/customTags';
import store from './store';
//import { unregister, register } from './serviceWorkerRegistration';
import Loader from './components/shared/loader';
import { CookiebotUnivers } from './components/cookie/universCookiebot';

isMobile && document.documentElement.classList.add('mobile');
isDesktop && document.documentElement.classList.add('desktop');

//unregister();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <CookiebotUnivers />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  //</React.StrictMode>
);

//register();
