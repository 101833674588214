import { createSlice, PayloadAction } from '@reduxjs/toolkit';

let initialState = { cartData: null };

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: initialState,
  reducers: {
    setCartData(state, action: PayloadAction<any>) {
      state.cartData = action.payload;
    },
  },
});

export const { setCartData } = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
