const Switchable = () => {
  /* eslint-disable */
  return (
    <view data-space="30" data-direction="row">
      <group data-length="autofit" data-direction="column">
        <group data-direction="column" data-name="switchable" className="">
          <group
            data-name="switchable_head"
            data-effect="material"
            data-color="white"
            data-border=""
            data-background="main"
            data-align="center"
            data-space="10"
          >
            <div className="button micro collapsible">
              <icon>arrow_upward</icon>
            </div>
            <wrap>
              <a className="button" href="#">
                <text data-weight="700">Accumulateur</text>
              </a>
            </wrap>
          </group>
          <group data-space="10" data-gap="10">
            <group data-name="switchable_content" data-type="grid" data-grid-size="250">
              <group data-direction="column" data-gap="5" data-space="10">
                <a data-type="link" href="#">
                  <text data-wrap="wrap">Tradi 2 (2004-2020)</text>
                </a>
              </group>
              <group data-direction="column" data-gap="5" data-space="10">
                <a data-type="link" href="#">
                  <text data-wrap="wrap">Tradi Electroniwue (2003-2004)</text>
                </a>
              </group>
            </group>
          </group>
        </group>
        <group data-direction="column" data-name="switchable" className="">
          <group
            data-name="switchable_head"
            data-effect="material"
            data-color="white"
            data-border=""
            data-background="main"
            data-align="center"
            data-gap="15"
            data-space="10"
            data-cursor="pointer"
          >
            <div className="button micro collapsible">
              <icon>arrow_upward</icon>
            </div>
            <wrap>
              <a className="button" href="#">
                <text data-weight="700">Radiateur</text>
              </a>
            </wrap>
          </group>
          <group data-space="10" data-gap="10">
            <group data-name="switchable_content" data-type="grid" data-grid-size="250">
              <group data-direction="column" data-gap="5" data-space="10">
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Accessio Digital 2(S27/17 - )</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Agilia</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail(sept 2018)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail Premium (Aout 2019)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Galapagos PI Connecte (IO) (*S27/16 -)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Irisium</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Nievana Neo (Juin 2022 -)</text>
                </a>
              </group>
              <group data-direction="column" data-gap="5" data-space="10">
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Accessio Digital 2(S27/17 - )</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Agilia</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail(sept 2018)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail Premium (Aout 2019)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Galapagos PI Connecte (IO) (*S27/16 -)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Irisium</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Nievana Neo (Juin 2022 -)</text>
                </a>
              </group>
              <group data-direction="column" data-gap="5" data-space="10">
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Accessio Digital 2(S27/17 - )</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Agilia</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail(sept 2018)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Divail Premium (Aout 2019)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Galapagos PI Connecte (IO) (*S27/16 -)</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Irisium</text>
                </a>
                <a data-type="link" href="#">
                  <text data-wrap="wrap">1 - Nievana Neo (Juin 2022 -)</text>
                </a>
              </group>
            </group>
            <group data-direction="column" data-name="switchable" className="close">
              <group
                data-name="switchable_head"
                data-effect="material"
                data-color="white"
                data-border=""
                data-background="main-light"
                data-align="center"
                data-gap="15"
                data-space="10"
                data-cursor="pointer"
              >
                <div className="button micro collapsible">
                  <icon>arrow_upward</icon>
                </div>
                <wrap>
                  <a className="button" href="#">
                    <text data-weight="700">Radiateur</text>
                  </a>
                </wrap>
              </group>
              <group data-space="10" data-gap="10">
                <group data-name="switchable_content" data-type="grid" data-grid-size="250">
                  <group data-direction="column" data-gap="5" data-space="10">
                    <a data-type="link" href="#">
                      <text data-wrap="wrap">Tradi 2 (2004-2020)</text>
                    </a>
                  </group>
                  <group data-direction="column" data-gap="5" data-space="10">
                    <a data-type="link" href="#">
                      <text data-wrap="wrap">Tradi Electroniwue (2003-2004)</text>
                    </a>
                  </group>
                </group>
              </group>
            </group>

            <group data-direction="column" data-name="switchable" className="close">
              <group
                data-name="switchable_head"
                data-effect="material"
                data-color="white"
                data-border=""
                data-background="main-light"
                data-align="center"
                data-gap="15"
                data-space="10"
                data-cursor="pointer"
              >
                <div className="button micro collapsible">
                  <icon>arrow_upward</icon>
                </div>
                <wrap>
                  <a className="button" href="#">
                    <text data-weight="700">Accumulateur</text>
                  </a>
                </wrap>
              </group>

              <group data-space="10" data-gap="10">
                <group data-name="switchable_content" data-type="grid" data-grid-size="250">
                  <group data-direction="column" data-gap="5" data-space="10">
                    <a data-type="link" href="#">
                      <text data-wrap="wrap">Tradi 2 (2004-2020)</text>
                    </a>
                  </group>
                  <group data-direction="column" data-gap="5" data-space="10">
                    <a data-type="link" href="#">
                      <text data-wrap="wrap">Tradi Electroniwue (2003-2004)</text>
                    </a>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </view>
  );
};

export default Switchable;
