import { isArticleAvailable } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';
import { Text } from '../shared/text';


const getTextAndColorByStock = (stock?: number) => {
  let text = '',
    color = '';
  if ((stock as number) >= 4) {
    text = 'article.disponibilite.disponible';
    color = 'green';
  } else if ((stock as number) > 0) {
    text = 'article.prix.consult';
    color = 'green';
  } else {
    text = 'article.disponibilite.indisponible';
    color = 'orange';
  }
  return { text, color };
};
const ArticleAvailabilityWidget: React.FC<{ article: Article; isWidget?: boolean; }> = ({ article, isWidget }) => {
  let text = '',
    color = '';
  if (article.articleReseau) {
    if (isArticleAvailable(article)) {
      if (!isWidget) {
        ({ text, color } = getTextAndColorByStock(article?.articleReseau?.stock));
      }
    } else {
      if (article.article_remplacant) {
        text = 'article.statut.statuts.4';
      } else {
        text = 'article.statut.statuts.5';
      }
      color = 'red';
    }
  }
  return text ? (
    <Text data-wrap="wrap" data-weight="700" {...{ 'data-color': color }}>
      {text}
    </Text>
  ) : (
    <></>
  );
};

export default ArticleAvailabilityWidget;
