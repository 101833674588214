import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { APP_URL, UNIVERS, WarrantyRequestSteps, WarrantyType } from '../../project/defines';
import { getAppUrl, getClientType, isTypeGarantiesAllowed } from '../../project/helpers';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Product, ProductWarrantyType } from '../../store/product/productModels';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { useCreateOrUpdateWarrantyRequestMutation } from '../../store/warrantyRequest/warrantyRequestApi';
import useTranslation from '../../utils/hooks/useTranslation';
import Button from '../shared/button';
import Radio from '../shared/inputs/radio';
import Table from '../shared/list/table';
import Loader from '../shared/loader';
import { Text } from '../shared/text';

const allowWarrantyReturn = (univers: string) => univers !== UNIVERS.Atlantic;

const WarrantyRequestTypeSelect: React.FC<{ callback?: Function; useCases?: Product[]; article_uuid?: string }> = ({
  callback,
  useCases,
  article_uuid,
}) => {
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const user = useAppSelector((state) => state.auth);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [type, setType] = useState('');
  const { t } = useTranslation();
  const [createOrUpdateDemande, { isLoading }] = useCreateOrUpdateWarrantyRequestMutation();
  const types = [WarrantyType.Commande];
  const isArticleReplacementCase = !!article_uuid;
  if (allowWarrantyReturn(univers?.slug ?? '')) {
    types.push(WarrantyType.Retour);
  }
  let isPopup = !!callback;
  const onRemoveArticle = () => {
    setSelectedProduct(null);
  };
  type TableRecord = Record<string, any>;
  const columns: ColumnType<TableRecord>[] = [
    {
      title: t('article.casUtilisation.produit'),
      dataIndex: 'reference',
      width: 90,
      render: (value: string, record: Record<string, any>) => {
        return (
          <Text>
            [{record.reference}] {record.libelle}{' '}
          </Text>
        );
      },
    },
  ];

  const products = useMemo(
    () =>
      useCases?.filter((product: Product) => {
        const clientType = getClientType(user, product?.reseau_uuid ?? '');
        let warrantyClientEnCompte = clientType ? product.typesGarantie[clientType] : ({} as ProductWarrantyType);
        return product.isEnCompte && product.code && isTypeGarantiesAllowed(warrantyClientEnCompte, type);
      }),
    [useCases, user, type]
  );

  const onSubmit = async () => {
    if (isArticleReplacementCase) {
      if (selectedProduct) {
        let reseau_uuid = selectedProduct.reseau_uuid;
        let clientType = getClientType(user, reseau_uuid!);
        try {
          let result = await createOrUpdateDemande({
            uuid: '',
            produit_uuid: selectedProduct.uuid,
            reseau_uuid: reseau_uuid!,
            univers_uuid: univers?.uuid ?? '',
            garantie_type_uuid: (selectedProduct.typesGarantie as any)[clientType!].uuid,
            etape: WarrantyRequestSteps.etape2,
            typedemande: type,
            pays_uuid: pays_uuid,
            articles: [
              {
                article_uuid: article_uuid || '',
                quantite: 1,
                garantie_demande_uuid: '',
              },
            ],
          }).unwrap();
          dispatch(
            addNotification({
              type: 'success',
              message: t(`global.notifications.demande.createTitle`),
              description: t(`global.notifications.demande.createMessage`),
              duration: 5,
            })
          );

          result.uuid &&
            navigate(getAppUrl(APP_URL.WarrantyReplacement, { routeParams: { reference: result.uuid } }), {
              replace: true,
            });
          callback?.();
        } catch (error: any) {
          ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
        }
      }
    } else {
      navigate(getAppUrl(APP_URL.WarrantyReplacement, { routeParams: {} }), {
        replace: !isPopup,
        state: { type, uuid: uuidv4() },
      });
      callback?.();
    }
  };

  return (
    <group data-scroll="" data-space="20" data-max-length="700">
      {isLoading && <Loader />}
      {!isPopup && (
        <Text dataWeight="700" accent dataTextSize="medium" dataWrap="">
          garantie.demande.modal.titre
        </Text>
      )}
      <Text data-space="20" dataWeight="700" dataTextSize="" dataWrap="">
        garantie.demande.modal.titremodal
      </Text>
      <group data-direction="column" data-space-left="20">
        {types.map((t) => (
          <group data-space-bottom="10" key={t}>
            <group  data-name="custom">
              <Radio
                labelProps={{ 'data-weight': 400, 'data-text-size': '' }}
                radioValue={t}
                value={type}
                onChange={() => {
                  setType(t);
                }}
                label={`garantie.demande.modal.checkbox.${t}.titre`}
              />
            </group>
            <group data-space-vertical="20">
              <group data-space="20" data-direction="column" data-gap="10">
                <Text data-wrap="wrap">{`garantie.demande.modal.checkbox.${t}.description`}</Text>
                <Text data-wrap="wrap">{`garantie.demande.modal.checkbox.${t}.description2`}</Text>
                <Text data-wrap="wrap">{`garantie.demande.modal.checkbox.${t}.description3`}</Text>
              </group>
            </group>
          </group>
        ))}
      </group>

      {type && useCases && (
        <group
          data-space="20"
          //data-length="700"
        >
          <group>
            <Text data-space="20" dataWeight="700" dataTextSize="" data-wrap="wrap">
              garantie.demande.modal.selectProduct
            </Text>
          </group>
          {selectedProduct ? (
            <group>
              <group data-align="center" data-space="10" data-gap="5" data-wrap="no">
                <group data-gap="10">
                  <group
                    data-width="auto"
                    data-direction="column"
                    data-contain=""
                    data-max-length="500"
                    data-position="left"
                    data-length="forcefit"
                  >
                    <group data-gap="5">
                      <text data-weight="700" data-digit="">
                        [{selectedProduct.reference}]
                      </text>
                      <text data-weight="700" data-wrap="wrap">
                        {selectedProduct.libelle}
                      </text>
                    </group>
                  </group>
                </group>
                <group data-align="center" data-wrap="no" data-width="auto" data-gap="10">
                  <Button mini material onClick={() => onRemoveArticle()}>
                    <icon>delete</icon>
                  </Button>
                </group>
              </group>
            </group>
          ) : (
            <group
              data-table-highlight="zebra-odd"
              data-radius="5" 
              data-border="" 
              data-space="5" 
              data-scroll=""
            >

              <Table
              data-min-length="400"
                keyField="uuid"
                scroll={{ y: 500 }}
                ellipsis={true}
                onRow={(record: any, rowIndex) => {
                  return {
                    onClick: () => {
                      setSelectedProduct(record);
                    },
                  };
                }}
                bordered={false}
                columns={columns}
                showHeader={true}
                tableLayout="fixed"
                dataSource={products ?? []}
                pagination={false}
                size={'small' as SizeType}
              />

            </group>
          )}
        </group>
      )}
      <group data-space="30" data-gap="10" data-type="grid" data-background={isPopup ? 'highlight' : ''}>
        <Button
          material
          primary
          large
          disabled={!type || (isArticleReplacementCase && !selectedProduct)}
          onClick={() => {
            onSubmit();
          }}
        >
          <Text>garantie.demande.modal.boutonValider</Text>
        </Button>
        {isPopup && (
          <Button
            material
            outline
            large
            onClick={() => {
              callback?.();
            }}
          >
            <Text>garantie.demande.modal.boutonclose</Text>
          </Button>
        )}
      </group>
    </group>
  );
};

export default WarrantyRequestTypeSelect;
