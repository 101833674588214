import { Breadcrumb, Dropdown } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useGetBreadcrumbQuery } from '../store/breadcrumb/breadcrumbApi';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { APP_URL } from '../project/defines';
import useTranslation from '../utils/hooks/useTranslation';
import { Text } from './shared/text';
import { getAppUrl, getBreadcrumbQueryParams } from '../project/helpers';
import { setBreadcrumbList } from '../store/analyticsSlice';
import { BreadcrumbModel } from '../store/breadcrumb/breadcrumbModels';

const BreadcrumbWidget: React.FC = () => {
  const { id, reseau_uuid } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const { t } = useTranslation();

  const queryParamsByType = useMemo(() => {
    return getBreadcrumbQueryParams(location.pathname, id, reseau_uuid);
  }, [location.pathname, id, reseau_uuid]);

  const { data: breadcrumbData } = useGetBreadcrumbQuery(
    { univers_uuid: univers?.uuid ?? '', lang, ...queryParamsByType },
    { skip: !id || !univers?.uuid || !lang || !queryParamsByType }
  );

  useEffect(() => {
    if (breadcrumbData) {
      dispatch(setBreadcrumbList(breadcrumbData));
    }
  }, [breadcrumbData, dispatch]);

  const breadcrumbItems = useMemo(() => {
    if (!breadcrumbData || breadcrumbData.length < 2) {
      return [];
    }

    const arr: any[] = [];

    let dropdown_data: BreadcrumbModel[] = [...breadcrumbData];
    let active_item = dropdown_data.pop();
    dropdown_data.reverse();

    let menuItems = dropdown_data.map(({ type, uuid, libelle, reseau_uuid }, index) => {
      let urlUpToReseau = reseau_uuid ? APP_URL.ProductReseau : APP_URL.Product;
      let title = libelle,
        href = getAppUrl(type === 'famille' ? APP_URL.Family : urlUpToReseau, {
          routeParams: reseau_uuid
            ? {
                id: uuid,
                reseau_uuid,
              }
            : { id: uuid },
        });

      return {
        key: index.toString(),
        label: (
          <Link to={href}>
            <group data-align="center" data-gap="10">
              <icon data-icon-size="small">expand_less</icon>
              <text>{title}</text>
            </group>
          </Link>
        ),
      };
    });

    menuItems.push({
      key: 'ariane.accueil',
      label: (
        <Link to={getAppUrl(APP_URL.HomePage)}>
          <group data-align="center" data-gap="10">
            <icon data-icon-size="small">expand_less</icon>
            <Text>ariane.accueil</Text>
          </group>
        </Link>
      ),
    });

    arr.push({
      title: 'ariane.menuNavigationTitle',
      items: menuItems,
    });

    let productUrl = dropdown_data[0].reseau_uuid ? APP_URL.ProductReseau : APP_URL.Product;
    let parentHref = getAppUrl(dropdown_data[0].type === 'famille' ? APP_URL.Family : productUrl, {
      routeParams: dropdown_data[0].reseau_uuid
        ? {
            id: dropdown_data[0].uuid,
            reseau_uuid: dropdown_data[0].reseau_uuid,
          }
        : { id: dropdown_data[0].uuid },
    });

    arr.push({
      title: <Link to={parentHref}>{t('ariane.parent_folder')}</Link>,
    });

    arr.push({
      title: <span data-user-select="text">{active_item?.libelle}</span>,
    });

    return arr;
  }, [breadcrumbData, t]);

  return breadcrumbItems.length ? (
    <>
      <group data-align="center">
        <Breadcrumb
          items={breadcrumbItems}
          itemRender={(route: any) => {
            if (route.items) {
              return (
                <Dropdown menu={{ items: route.items }} placement="bottomLeft" trigger={['click']} arrow>
                  <a>
                    <group data-align="center">
                      <Text dataWeight="700">{route.title}</Text>
                      <icon data-icon-size="small">expand_more</icon>
                    </group>
                  </a>
                </Dropdown>
              );
            } else {
              return route.title;
            }
          }}
        />
      </group>
      <space data-height="20"></space>
    </>
  ) : (
    <></>
  );
};

export default BreadcrumbWidget;
